import { Map, Record, Set } from 'immutable';
import { Observable } from 'rxjs/Observable';

export interface DataModelState {
  ids: Set<string>;
  entities: Map<string, any>;
  relationships: Map<string, any> | undefined;
  diff: RequestDiffRecord;
  isBusy: number;
  // history: Array<any>;
}

export interface IPayload {
  id?: string;
  token?: string;
  data?: any;
}

const requestDiffRecordRecord = Record({
  id: undefined,
  initiator: undefined,
  payload: undefined,
  response: undefined,
  action: undefined
});

export class RequestDiffRecord extends requestDiffRecordRecord {
  id: number;
  initiator: {
    id: number,
    name: string
  };
  payload: any;
  response: any;
  action: string;
}
