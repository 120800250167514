import { CoreService } from 'src/app/core/service/core.service';
import { TranslateService } from '@ngx-translate/core';
import { Map, OrderedMap } from 'immutable';
import { EventEmitter, Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';

import { ColorLabelProviderServiceBasic } from './providers/colorlabelprovider.service.basic';
import { ColorLabelProviderServiceStatus } from './providers/colorlabelprovider.service.status';
import { ColorLabelProviderServiceDeviation } from './providers/colorlabelprovider.service.deviation';
import { ColorLabelProviderServiceProfit } from './providers/colorlabelprovider.service.profit';
import { ColorLabelProviderServiceTargetDate } from './providers/colorlabelprovider.service.targetdate';
import { ColorLabelProviderServiceImportance } from './providers/colorlabelprovider.service.importance';
import { ColorLabelProviderServiceResponsible } from './providers/colorlabelprovider.service.responsible';
import { ColorLabelProviderServiceModels } from './providers/colorlabelprovider.service.models';
import { ColorLabelProviderServiceSubModels } from './providers/colorlabelprovider.service.submodels';
import { ColorLabelProviderServiceSubSets } from './providers/colorlabelprovider.service.subsets';
import { ColorLabelProviderServiceSidestep } from './providers/colorlabelprovider.service.sidestep';
import { ColorLabelProviderServiceDifference } from './providers/colorlabelprovider.service.difference';
import { ColorLabelProviderServiceRisk } from './providers/colorlabelprovider.service.risk';
import { ColorLabelProviderServiceQStatus } from './providers/colorlabelprovider.service.qstatus';
import { ColorlabelproviderServiceRelatedstatus } from './providers/colorlabelprovider.service.relatedstatus';
import { ColorLabelProviderNodesType } from './providers/colorlabelprovider.service.nodestype';
import { ColorLabelProviderNodeTypes } from './providers/colorlabelprovider.service.nodetypes';
import { ColorLabelProviderLevels } from './providers/colorlabelprovider.service.levels';
import { ColorLabelProviderServicePlanned } from './providers/colorlabelprovider.service.planned';
import { ColorLabelProviderServiceAvailable } from './providers/colorlabelprovider.service.available';
import { ColorLabelProviderServiceProjectsAvailable } from './providers/colorlabelprovider.service.projects-available';
import { ColorLabelProviderServiceValidated } from './providers/colorlabelprovider.service.validated';
import { ColorLabelProviderServicePositive } from './providers/colorlabelprovider.service.positive';

import { Model } from '../../shared/api/models';
import { Subset } from '../../shared/api/subsets';
import { CoreHumanResource, TreeNode } from '../../core/interface/core.interface';
import { FormService } from '../../components/form/service/form.service';
import { ColorLabelProviderServiceHeatmap } from './providers/colorlabelprovider.service.heatmap';
import { ColorLabelProviderServiceColoredRelation } from './providers/colorlabelprovider.service.coloredRelation';
import { ColorlabelproviderServiceStatusfield } from './providers/colorlabelprovider.service.statusfield';
import { ColorLabelProviderServiceCustom } from './providers/colorlabelprovider.service.custom';
import { BehaviorSubject } from 'rxjs';
import { ColorLabelProviderServiceComparison } from './providers/colorlabelprovider.service.comparison';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import { ColorLabelProviderServiceStructure } from './providers/colorlabelprovider.service.structure';
import { ColorLabelProviderServiceGrouping } from "./providers/colorlabelprovider.service.grouping";

@Injectable()
export class ColorLabelProvider {

  public configurations = Map<string, TreeNode>();

  private configurationDispatchers = Map<string, BehaviorSubject<TreeNode>>();

  public constructor(private translateService?: TranslateService, private coreUtilities?: CoreUtilities) {}

  public setConfigurationNode(configurationNode: TreeNode) {
    if (!isNullOrUndefined(configurationNode)) {
      const count = configurationNode.children.length;
      for (let i = 0; i < count; i++) {
        const configuration = configurationNode.children[i];
        this.configurations = this.configurations.set(configuration.formId === '' ? configuration.colorLabelProvider : configuration.formId, configuration);
      }
    }
  }

  public addConfigurationDispatcher(key: string) {
    if (!this.configurationDispatchers.has(key)) {
      this.configurationDispatchers = this.configurationDispatchers.set(key, new BehaviorSubject<TreeNode>(undefined));
    }
  }

  public updateConfigurationNode(key: string, configurationNode: TreeNode) {
    if (!this.configurationDispatchers.has(key)) {
      this.configurationDispatchers = this.configurationDispatchers.set(key, new BehaviorSubject<TreeNode>(undefined));
    }
    this.configurationDispatchers.get(key).next(configurationNode);
  }

  public basic() {
    return new ColorLabelProviderServiceBasic(this.translateService);
  }

  public status() {
    return new ColorLabelProviderServiceStatus(this.translateService);
  }

  public deviation() {
    return new ColorLabelProviderServiceDeviation(this.translateService);
  }

  public profit() {
    return new ColorLabelProviderServiceProfit(this.translateService);
  }

  public sidestep() {
    return new ColorLabelProviderServiceSidestep();
  }

  public targetDate() {
    return new ColorLabelProviderServiceTargetDate(this.configurations.get('targetDate'));
  }

  public models(models: OrderedMap<string, Model>) {
    return new ColorLabelProviderServiceModels(models);
  }

  public submodels(subModels: OrderedMap<string, Model>) {
   return new ColorLabelProviderServiceSubModels(subModels);
  }

  public subsets(subSets: OrderedMap<string, Subset>) {
    return new ColorLabelProviderServiceSubSets(subSets);
  }

  public responsible(responsible: OrderedMap<string, CoreHumanResource>) {
    return new ColorLabelProviderServiceResponsible(responsible);
  }

  public difference() {
    return new ColorLabelProviderServiceDifference();
  }

  public importance() {
    return new ColorLabelProviderServiceImportance();
  }

  public risk() {
    return new ColorLabelProviderServiceRisk();
  }

  public qstatus(field: string) {
    return new ColorLabelProviderServiceQStatus(field);
  }

  public relatedstatus(treeNodes: OrderedMap<string, TreeNode>) {
    return new ColorlabelproviderServiceRelatedstatus(treeNodes);
  }

  public nodestype(params: any, models: OrderedMap<string, Model>, subModels: OrderedMap<string, Model>, subSets: OrderedMap<string, Subset>, responsible: OrderedMap<string, CoreHumanResource>, treeNodes: OrderedMap<string, TreeNode>) {
    return new ColorLabelProviderNodesType(params, models, subModels, subSets, responsible, treeNodes, this);
  }

  public nodetypes(params) {
    return new ColorLabelProviderNodeTypes(params);
  }

  public levels() {
    return new ColorLabelProviderLevels();
  }

  public planned() {
    return new ColorLabelProviderServicePlanned();
  }

  public available(formService: FormService) {
    return new ColorLabelProviderServiceAvailable(formService);
  }

  public projectsAvailable(formService: FormService) {
    return new ColorLabelProviderServiceProjectsAvailable(formService);
  }

  public validated() {
    return new ColorLabelProviderServiceValidated(this.configurations.get('validated'));
  }

  public positive() {
    return new ColorLabelProviderServicePositive();
  }

  public heatmap() {
    return new ColorLabelProviderServiceHeatmap(this.configurations.get('heatmap'));
  }

  public coloredRelation() {
    return new ColorLabelProviderServiceColoredRelation(this.configurations.get('coloredRelation'));
  }

  public statusField() {
    return new ColorlabelproviderServiceStatusfield();
  }

  public comparison() {
    return new ColorLabelProviderServiceComparison(this.coreUtilities);
  }

  public structure(coreService: CoreService) {
    return new ColorLabelProviderServiceStructure(this.configurations.get('structure'), coreService);
  }

  public grouping() {
    return new ColorLabelProviderServiceGrouping();
  }

  public custom(key: string) {
    return new ColorLabelProviderServiceCustom(this.configurationDispatchers.get(key));
  }
}
