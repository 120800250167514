import { BehaviorSubject } from 'rxjs';

export class CollectorService {

  protected collections = {};

  protected collectionUpdate = {};

  public setCollection(collectionName: string, data: any): CollectorService {
    this.collections[collectionName] = data;
    if (this.collectionUpdate[collectionName] !== undefined) {
      this.collectionUpdate[collectionName].next(data);
    } else {
      this.collectionUpdate[collectionName] = new BehaviorSubject<any>(data);
    }
    return this;
  }

  public getCollection(collectionName: string): any {
    return this.collections[collectionName];
  }

  public removeCollection(collectionName: string): CollectorService {
    this.collectionUpdate[collectionName].next(undefined);
    delete this.collections[collectionName];
    return this;
  }

  public subscribe(collectionName: string) {
    if (this.collectionUpdate[collectionName] === undefined) {
      this.collectionUpdate[collectionName] = new BehaviorSubject<any>(undefined);
    }
    return this.collectionUpdate[collectionName];
  }

}
