import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { MessagingAPI, INotification, JSONAPIResponse, GoAPI } from '../connector';
import { NotificationAction } from './notifications.actions';
import { Observable } from 'rxjs/Observable';
import { Action } from '../shared/common.action';

@Injectable()
export class NotificationEffects {
  concurrency = 5;

  @Effect() fetchNotifications$ = this.actions$
    .pipe(ofType(NotificationAction.LOAD))
    .mergeMap(() => this.messagingApi.notifications.findAll()
      .mergeMap((response: JSONAPIResponse<INotification[]>) => {
        return Observable.from([
          this.notificationAction.loadSuccess(response.toPayload())
        ]);
      })
    ).catch((error: any) => Observable.of(this.notificationAction.loadFail(error)));

  @Effect() updateNotifications$ = this.actions$
    .pipe(ofType(NotificationAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.map(payload => this.messagingApi.notifications.update(payload, null)
        .mergeMap((response: JSONAPIResponse<INotification>) => Observable.from([
          this.notificationAction.updateSuccess(payload, response.toPayload())
        ]))
      )).catch((error: any) => Observable.of(this.notificationAction.updateFail(action.payload, error)))
    ).mergeAll(this.concurrency);

  @Effect() removeNotification$ = this.actions$
    .pipe(ofType(NotificationAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.id.map(payload => this.messagingApi.notifications.remove(payload)
      .mergeMap(response => Observable.from([
        this.notificationAction.removeSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.notificationAction.removeFail(payload, error)))
    ))).mergeAll(this.concurrency);

  @Effect() rollbackNotification$ = this.actions$
    .pipe(ofType(NotificationAction.ROLLBACK))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => Observable.of(...action.payload.id.map(payload => this.messagingApi.notifications.rollback(payload)
      .mergeMap(response => Observable.from([
        this.notificationAction.rollbackSuccess(payload, response.toPayload())
      ]))
      .catch((error: any) => Observable.of(this.notificationAction.rollbackFail(payload, error)))
    ))).mergeAll(this.concurrency);

  @Effect() clear = this.actions$
    .pipe(ofType(NotificationAction.CLEAR))
    .mergeMap((action: Action) => this.api.resetNotifications().get().mergeMap(response => Observable.from([])));

  constructor(private messagingApi: MessagingAPI,
              private api: GoAPI,
              private actions$: Actions,
              private notificationAction: NotificationAction) {
  }
}
