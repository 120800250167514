import { Action } from '../shared/common.action';
import { Injectable } from '@angular/core';
import { IUser } from '../connector/models';

@Injectable()
export class UserAction {
  static key = 'User';

  static LOAD = `[${UserAction.key}] Load`;
  static LOAD_SUCCESS = `[${UserAction.key}] Load success`;
  static LOAD_FAIL = `[${UserAction.key}] Load fail`;

  static GET_USER_BY_EMAIL = `[${UserAction.key}] Get user by email`;
  static GET_USER_BY_EMAIL_SUCCESS = `[${UserAction.key}] Get user by email success`;
  static GET_USER_BY_EMAIL_FAIL = `[${UserAction.key}] Get user by email fail`;

  static LOAD_WITH_INCLUDES = `[${UserAction.key}] Load with includes`;

  public load(_, includes): Action {
    return {
      type: UserAction.LOAD,
      payload: {
        includes: includes
      }
    };
  }

  public loadSuccess(data: IUser): Action {
    return {
      type: UserAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: UserAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public loadElementsResponsibleFor(offset: number, limit: number): Action {
    return {
      type: UserAction.LOAD_WITH_INCLUDES,
      payload: {
        offset: offset,
        limit: limit
      }
    };
  }

  public getUserByEmail(email: string): Action {
    return {
      type: UserAction.GET_USER_BY_EMAIL,
      payload: {
        data: email
      }
    };
  }

  public getUserByEmailSuccess(id: number, request: any): Action {
    return {
      type: UserAction.GET_USER_BY_EMAIL_SUCCESS,
      payload: {
        request,
        response: id
      }
    };
  }

  public getUserByEmailFail(error: any): Action {
    return {
      type: UserAction.GET_USER_BY_EMAIL_FAIL,
      payload: {
        response: error
      }
    };
  }

}
