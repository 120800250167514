import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared';

const generalStateRecord = Record({
  healyMembers: [],
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class GeneralState extends generalStateRecord {
  healyMembers: HealyMember[];
  diff: RequestDiffRecord;
  isBusy: number;
}

export interface HealyMember {
  id: number;
  name: string;
  lastname: string;
  email: string;
  rankId: number;
  country: string;
  selected: boolean;
}
