import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import {
  NODES_TYPE_CAPABILITY, NODES_TYPE_CATEGORY,
  NODES_TYPE_GAP,
  NODES_TYPE_REQUIREMENT, NODES_TYPE_VALUE
} from '../../../shared/api/nodes/nodes.models';
import { CoreUtilities } from '../../../core/utilities/core.utilities';

export class ColorLabelProviderServiceComparison extends ColorLabelProviderAbstract {

  private static NOTAPPLICABLE = '#ffffff';
  private static DUEDATEPAST = '#ff867d';
  private static INTIME = '#b9df9b';

  public constructor(private coreUtilities: CoreUtilities) {
    super();
  }

  public color(yAxisNodes: TreeNode[], xAxisNodes: TreeNode[]) {

    if (yAxisNodes === undefined || xAxisNodes === undefined || yAxisNodes.length === 0 || xAxisNodes.length === 0) {
      return [ColorLabelProviderServiceComparison.NOTAPPLICABLE];
    }

    /* Get the requirements */
    const requirements = this.getRequirements(yAxisNodes, xAxisNodes);

    if (requirements.length === 0) {
      return [ColorLabelProviderServiceComparison.NOTAPPLICABLE];
    }

    if (requirements.length > 0 && requirements.filter(requirement => (requirement.capabilities.length === 0 || requirement.capabilities.filter(capability => capability.gap).length > 0)).length === 0) {
      return [ColorLabelProviderServiceComparison.INTIME];
    }

    return [ColorLabelProviderServiceComparison.DUEDATEPAST];
  }

  public getRequirementsAndCapabilities(yAxisNodes: TreeNode[], xAxisNodes: TreeNode[]) {
    const xRequirements = [];
    const xCapabilities = [];
    let count = xAxisNodes.length;
    for (let i = 0; i < count; i++) {
      const xAxisNode = xAxisNodes[i];
      if (xAxisNode.nodeType === NODES_TYPE_REQUIREMENT) {
        xRequirements.push(xAxisNode);
      }
      if (xAxisNode.nodeType === NODES_TYPE_CAPABILITY) {
        xCapabilities.push(xAxisNode);
      }
    }

    const yRequirements = [];
    const yCapabilities = [];
    count = yAxisNodes.length;
    for (let i = 0; i < count; i++) {
      const yAxisNode = yAxisNodes[i];
      if (yAxisNode.nodeType === NODES_TYPE_REQUIREMENT) {
        yRequirements.push(yAxisNode);
      }
      if (yAxisNode.nodeType === NODES_TYPE_CAPABILITY) {
        yCapabilities.push(yAxisNode);
      }
    }

    return { requirements: xRequirements.length > 0 ? xRequirements : yRequirements, capabilities: xRequirements.length > 0 ? yCapabilities : xCapabilities };
  }

  private getRequirements(yAxisNodes: TreeNode[], xAxisNodes: TreeNode[]) {
    const result: { requirement: TreeNode, capabilities: { gap: boolean, child: TreeNode }[] }[] = [];

    const { requirements, capabilities } = this.getRequirementsAndCapabilities(yAxisNodes, xAxisNodes);
    const capabilityIds = capabilities.map(c => c.id);

    const count = requirements.length;
    for (let i = 0; i < count; i++) {
      const requirement = requirements[i];
      /* Children */
      let children: { gap: boolean, child: TreeNode }[] = [];
      /* Check for children */
      const count2 = requirement.children.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const child = requirement.children[i2];
        switch (child.nodeType) {
          case NODES_TYPE_GAP:
            const count3 = child.children.length;
            for (let i3 = 0; i3 < count3; i3++) {
              const cchild = child.children[i3];
              if (cchild.nodeType === NODES_TYPE_CAPABILITY) {
                children.push({ gap: true, child: cchild });
              }
            }
            break;
          case NODES_TYPE_CAPABILITY:
            children.push({ gap: false, child: child });
            break;
        }
      }
      /* Now check if the capabilities are matching with the other axis and add to result */
      if (this.areThereAnyCapabilities(requirement)) {
        result.push({ requirement: requirement, capabilities: children.filter(c => capabilityIds.indexOf(c.child.id) !== -1) });
      }
    }

    return result;
  }

  private areThereAnyCapabilities(requirement: TreeNode) {
    let capabilities = [];
    /* Category values */
    let categoryValues = requirement.parents.filter(cv => cv.nodeType === NODES_TYPE_VALUE);
    let count2 = categoryValues.length;
    for (let i2 = 0; i2 < count2; i2++) {
      const categoryValue = categoryValues[i2];
      /* Categories */
      const categories = categoryValue.parents.filter(cv => cv.nodeType === NODES_TYPE_CATEGORY);
      const count3 = categories.length;
      for (let i3 = 0; i3 < count3; i3++) {
        const category = categories[i3];
        /* Category values */
        const categoryValuesCapability = category.children.filter(cv => cv.nodeType === NODES_TYPE_VALUE && cv.children.filter(capability => capability.nodeType === NODES_TYPE_CAPABILITY).length > 0);
        const count4 = categoryValuesCapability.length;
        for (let i4 = 0; i4 < count4; i4++) {
          const categoryValueCapability = categoryValuesCapability[i4];
          /* Capabilities */
          capabilities = capabilities.concat(categoryValueCapability.children);
        }
      }
    }
    return capabilities.length > 0;
  }

}
