import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared';

const treeStateRecord = Record({
  isBusy: 0,
  diff: new RequestDiffRecord(),
  entities: []
});

export class TreeState extends treeStateRecord {
  isBusy: number;
  diff: RequestDiffRecord;
  entities: any[];
}
