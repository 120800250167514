import { Record, Set, Map } from 'immutable';
import { RecordFactory } from '../shared/record-factory';
import { DataModelState, RequestDiffRecord } from '../shared';
import { IGroup, IGroupAttributes } from '../connector/models';

const groupStateRecord = Record({
  ids: Set<string>(),
  tempIdsMap: Map<string, string>(),
  entities: Map<string, Group>(),
  relationships: Map<string, GroupRelationships>(),
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class GroupState extends groupStateRecord implements DataModelState {
  ids: Set<string>;
  tempIdsMap: Map<string, string>;
  entities: Map<string, Group>;
  relationships: Map<string, GroupRelationships>;
  isBusy: number;
  diff: RequestDiffRecord;
}

const groupRecord = Record(<IGroupAttributes>{
  id: undefined,
  name: undefined,
  permissions: undefined,
  bestCase: 0,
  original: undefined,
  updatedAt: undefined,
  relationships: undefined
});

export class Group extends groupRecord implements IGroupAttributes {
  id: number;
  name: string;
  original: string;
  updatedAt: number;
  permissions: any;
  bestCase: number;

  relationships: GroupRelationships;
  instanceId?: string;

  static fromJSONAPI(group: IGroup) {
    return RecordFactory.entity.fromJSONAPI<Group>(Group, group);
  }
}

const groupRelationshipsRecord = Record({
  instance: undefined,
  humanresources: Set<string>(),
});

export class GroupRelationships extends groupRelationshipsRecord {
  instance: string;
  humanresources: Set<string>;

  static fromJSONAPI(group: IGroup) {
    return RecordFactory.relationships.fromJSONAPI<GroupRelationships>(GroupRelationships, group);
  }
}
