import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';

export class ColorLabelProviderServiceGrouping extends ColorLabelProviderAbstract {

  private static GROUPED = '#1F7A8CFF';

  public color(treeNode: TreeNode): any {
    if (treeNode.nodestructures.length > 1) {
      return [ColorLabelProviderServiceGrouping.GROUPED];
    }
    return super.color(treeNode);
  }

}
