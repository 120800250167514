import { INotification} from '../connector';

import { IPayload } from '../shared/common.models';
import { ImportSchemeAction } from '../importschemes/importschemes.actions';
import { Action } from '../shared/common.action';

export class NotificationAction {
  static key = 'Notification';

  static LOAD = `[${NotificationAction.key}] Load`;
  static LOAD_SUCCESS = `[${NotificationAction.key}] Load success`;
  static LOAD_FAIL = `[${NotificationAction.key}] Load fail`;

  static UPDATE = `[${NotificationAction.key}] Update`;
  static UPDATE_SUCCESS = `[${NotificationAction.key}] Update success`;
  static UPDATE_FAIL = `[${NotificationAction.key}] Update fail`;

  static REMOVE = `[${NotificationAction.key}] Remove`;
  static REMOVE_SUCCESS = `[${NotificationAction.key}] Remove success`;
  static REMOVE_FAIL = `[${NotificationAction.key}] Remove fail`;

  static ROLLBACK = `[${NotificationAction.key}] Rollback`;
  static ROLLBACK_SUCCESS = `[${NotificationAction.key}] Rollback success`;
  static ROLLBACK_FAIL = `[${NotificationAction.key}] Rollback fail`;

  static CLEAR = `[${NotificationAction.key}] Clear`;

  public load(): Action {
    return {
      type: NotificationAction.LOAD,
    };
  }

  public loadSuccess(data: INotification[]): Action {
    return {
      type: NotificationAction.LOAD_SUCCESS,
      payload: {
        response: data
      }
    };
  }

  public loadFail(error: any): Action {
    return {
      type: NotificationAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public update(data: number | number[]): Action {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NotificationAction.UPDATE,
      payload: data
    };
  }

  public updateSuccess(payload: IPayload, response: INotification): Action {
    return {
      type: NotificationAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): Action {
    return {
      type: NotificationAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public rollback(id: number | number[]): Action {
      if (!Array.isArray(id)) {
          id = [id];
      }
      return {
          type: NotificationAction.ROLLBACK,
          payload: {
            id: id
          }
      };
  }

  public rollbackSuccess(payload: IPayload, response: INotification): Action {
      return {
          type: NotificationAction.ROLLBACK_SUCCESS,
          payload: {
              request: payload,
              response: response
          }
      };
  }

  public rollbackFail(payload: IPayload, error: any): Action {
      return {
          type: NotificationAction.ROLLBACK_FAIL,
          payload: {
              request: payload,
              response: error
          }
      };
  }

  public remove(id: number | number[]): Action {
    if (!Array.isArray(id)) {
      id = [id];
    }
    return {
      type: ImportSchemeAction.REMOVE,
      payload: {
        id: id
      }
    };
  }

  public removeSuccess(payload: IPayload, response: INotification): Action {
    return {
      type: ImportSchemeAction.REMOVE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public removeFail(payload: IPayload, error: any): Action {
    return {
      type: ImportSchemeAction.REMOVE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public clear(): Action {
    return {
      type: NotificationAction.CLEAR,
      payload: {}
    };
  }

}
