import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { API, JSONAPIResponse, IActivity, GoAPI } from '../connector';
import { Observable } from 'rxjs/Observable';
import { ActivityAction } from './activities.actions';
import { Action } from '../shared/common.action';

@Injectable()
export class ActivityEffects {

    @Effect() createActivitiesOnInstance$ = this.actions$
    .pipe(ofType(ActivityAction.CREATE_ON_INSTANCE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.instance(action.payload.id).activities.bulkCreate(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.createSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.createFail(action.payload, error))));

  @Effect() createActivitiesOnNodeData$ = this.actions$
    .pipe(ofType(ActivityAction.CREATE_ON_NODEDATA))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.nodedatum(action.payload.id).activities.bulkCreate(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.createSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.createFail(action.payload, error))));

  @Effect() createActivitiesOnNodeDataGo$ = this.actions$
    .pipe(ofType(ActivityAction.CREATE_ON_NODEDATA_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.goAPI.activities.bulkCreateDirect(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.createSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.createFail(action.payload, error))));

  @Effect() updateActivities$ = this.actions$
    .pipe(ofType(ActivityAction.UPDATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.api.activities.bulkUpdate(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.updateSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.updateFail(action.payload, error))));

  @Effect() updateActivitiesGo$ = this.actions$
    .pipe(ofType(ActivityAction.UPDATE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.goAPI.activities.bulkUpdate(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.updateSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.updateFail(action.payload, error))));

  @Effect() removeActivities$ = this.actions$
    .pipe(ofType(ActivityAction.REMOVE))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.goAPI.activities.bulkRemove(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.removeSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.removeFail(action.payload, error))));

  @Effect() removeActivitiesGo$ = this.actions$
    .pipe(ofType(ActivityAction.REMOVE_GO))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.goAPI.activities.bulkRemove(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.removeSuccess(action.payload, response.toPayload())
    ])).catch((error: any) => Observable.of(this.activityAction.removeFail(action.payload, error))));

  @Effect() getActionByNodId$ = this.actions$
    .pipe(ofType(ActivityAction.GET_ACTIVITY_BY_NODEID))
    .filter((action: Action) => !!action.payload)
    .mergeMap((action: Action) => this.goAPI.activities.getActivityByNodeId(action.payload.data).mergeMap(response => Observable.from([
      this.activityAction.getSuccess(action.payload, response.toPayload())
      // this.activityAction.loadSuccess(action.payload)
    ])).catch((error: any) => Observable.of(this.activityAction.getFail(action.payload.data, error))));

  constructor(private api: API,
            private goAPI: GoAPI,
              private actions$: Actions,
              private activityAction: ActivityAction) {
  }
}
