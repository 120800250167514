import { IAction, ISuccessAction } from '../shared/common.interfaces';
import { Action } from '../shared/common.action';

export class TreeAction {
  static key = 'Tree';

  static LOAD = `[${TreeAction.key}] Load`;
  static LOAD_SUCCESS = `[${TreeAction.key}] Load success`;
  static LOAD_FAIL = `[${TreeAction.key}] Load fail`;

  public load(businessAreaId: number): IAction {
    return {
      type: TreeAction.LOAD,
      payload: {
        data: businessAreaId
      }
    };
  }

  public loadSuccess(response): ISuccessAction {
    return {
      type: TreeAction.LOAD_SUCCESS,
      payload: {
        request: undefined,
        response: response
      }
    };
  }

  public loadFail(error): Action {
    return {
      type: TreeAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

}
