import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { isNullOrUndefined } from 'util';

export class ColorLabelProviderServiceTargetDate extends ColorLabelProviderAbstract {

  private static NOTAPPLICABLE = '#d6dad5';
  private static DUEDATEPAST = '#ff867d';
  private static INTIME = '#b9df9b';

  protected field = 'status';
  protected completed = '200';

  public constructor(protected configurationNode?: TreeNode) {
    super();
    /* Configuration node */
    if (!isNullOrUndefined(configurationNode)) {
      this.field = configurationNode.formFieldId;
      this.completed = '' + configurationNode.form_default_value;
    }
  }

  public color(element: TreeNode | TreeNode[]) {
    if (Array.isArray(element)) {
      let color;
      for (let i = 0; i < element.length; i++) {
        const treeNode = element[i];
        if (this.calculate(treeNode) === 1) {
          return [ColorLabelProviderServiceTargetDate.DUEDATEPAST];
        } else if (this.calculate(treeNode) === 0) {
          color = [ColorLabelProviderServiceTargetDate.NOTAPPLICABLE];
        } else if (color === undefined) {
          color = [ColorLabelProviderServiceTargetDate.INTIME];
        }
      }
      return color;
    } else {
      switch (this.calculate(element)) {
        case 0:
          return [ColorLabelProviderServiceTargetDate.NOTAPPLICABLE];
        case 1:
          return [ColorLabelProviderServiceTargetDate.DUEDATEPAST];
        case 2:
          return [ColorLabelProviderServiceTargetDate.INTIME];
      }
    }
  }

  public label(element: TreeNode ) {
    return this.legend()[this.calculate(element)].label;
  }

  public legend() {
    return [
      { key: 'targetdate.na', color: ColorLabelProviderServiceTargetDate.NOTAPPLICABLE, label: 'Not applicable', field: 'targetDate', value: 0 },
      { key: 'targetdate.duedatepast', color: ColorLabelProviderServiceTargetDate.DUEDATEPAST, label: 'Due date past', field: 'targetDate', value: 1 },
      { key: 'targetdate.intime', color: ColorLabelProviderServiceTargetDate.INTIME, label: 'In time', field: 'targetDate', value: 2 }
    ];
  }

  public calculate(element: TreeNode) {
    if (element.targetDate !== null) {
      if (new Date(element.targetDate) < new Date() && ('' + element[this.field]) !== ('' + this.completed)) {
        return 1;
      } else {
        return 2;
      }
    }
    return 0;
  }

}
