import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { NODES_TYPE_FORM_OPTION } from '../../../shared/api/nodes/nodes.models';
import { isNullOrUndefined } from 'util';

export class ColorlabelproviderServiceStatusfield extends ColorLabelProviderAbstract {

  private field: string;
  private options: TreeNode[] = [];

  public modifyConfiguration(configurationNode: TreeNode): any {
    this.field = configurationNode.formFieldId;
    this.options = configurationNode.children.filter(child => child.nodeType === NODES_TYPE_FORM_OPTION);
    return this;
  }

  public color(treeNode: TreeNode) {
    return super.color();
  }

  public label(treeNode: TreeNode): any {
    if (!isNullOrUndefined(this.field) && !isNullOrUndefined(this.options) && !isNullOrUndefined(treeNode)) {
      const value = treeNode[this.field];
      const option = this.options.filter(o => '' + o.formId === '' + value)[0];
      if (!isNullOrUndefined(option)) {
        return '<i class="clp-statusField" style="background-color: ' + option.color + '"></i>' + option.name;
      }
    }
    return '';
  }

}
