import { CoreService } from './../../../core/service/core.service';
import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { isArray, isNullOrUndefined } from 'util';
import { Map, Set } from 'immutable';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';

export class ColorLabelProviderServiceColoredRelation extends ColorLabelProviderAbstract {

  private field = 'coloredRelation';
  private fields = Map<string, { label: string, color: string }>();
  private fieldMatrix: string[] = [];
  private count = 0;
  private alreadyChangedNodeIds: string[] = [];
  private colorFamilies = [
    [ '#264e7a', '#00769e', '#009daa', '#00c29d', '#8ee282'],
    [ '#be8e01', '#E6AC01', '#FECB33', '#FFD767', '#ffe293'],
    [ '#346598', '#5582B7', '#76A0D8', '#96C0F9', '#b7e0ff'],
    [ '#fd8b7c', '#fea984', '#fcc698', '#fce0b4', '#fff7d6'],
    [ '#00c2c8', '#5ecedd', '#8edaec', '#b8e6f8', '#dcf2ff'],
    [ '#89006e', '#b13a73', '#d1687e', '#ea9592', '#ffc2b1'],
    [ '#006b61', '#408d83', '#6cb1a6', '#97d6cc', '#c2fcf2'],
    [ '#943d34', '#C0564B', '#D96E47', '#ED8A3F', '#F9AA38'],
    [ '#007659', '#37925c', '#66ae59', '#9bc752', '#d7de4d'],
    [ '#5C6FA5', '#7F78AF', '#9E83B6', '#BA8EBC', '#D49AC0'],
    // ['#325E2A', '#549E47', '#69C458', '#76DE64', '#7DEB6A'],
    // ['#360E0C', '#751E1A', '#B52F28', '#DB3930', '#F53E35'],
    // ['#5C4811', '#9C7A1C', '#C29723', '#DCAC27', '#E8B52A'],
    // ['#561F5C', '#91359C', '#B542C2', '#CD4BDB', '#DB50EB'],
    // ['#1A255C', '#2C3E9C', '#364EC2', '#3D58DB', '#415DEB'],
    // ['#1A565C', '#2C929C', '#36B6C2', '#3DCEDB', '#41DDEB'],
  ]
  public constructor(protected configurationNode?: TreeNode) {
    super();
    /* Configuration node */
    if (!isNullOrUndefined(configurationNode)) {
      this.configure();
    }
  }
  private setChildrenColor(treeNodes: TreeNode[], familyNumber: number, nthChild: number){
    for (let i = 0; i < treeNodes.length; i++) {
      const child = treeNodes[i];
      if(!this.alreadyChangedNodeIds.includes(child.id)){
        child.colors = [this.colorFamilies[familyNumber][nthChild]];
        this.alreadyChangedNodeIds.push(child.id)
      }else{
        child.colors.push(this.colorFamilies[familyNumber][nthChild]);
      }
      if(child.children.length>0){
        this.setChildrenColor(child.children, familyNumber, nthChild + 1);
      }
    }
  }
  public color(treeNode: TreeNode, nodeType: number, mcm: number) {
    let color: string;
    if(nodeType === -1) return undefined;
    if(treeNode.nodeType === nodeType && (treeNode.parents.length === 0 || !treeNode.parents.map(e=>e.nodeType).includes(nodeType))&& treeNode.modelId !== mcm){
      while(this.count === this.colorFamilies.length){
        this.count = this.count - this.colorFamilies.length
      }
      if(!this.alreadyChangedNodeIds.includes(treeNode.id)){
        this.alreadyChangedNodeIds.push(treeNode.id)
        treeNode.colors = [this.colorFamilies[this.count][0]];
      }else{
        treeNode.colors.push(this.colorFamilies[this.count][0]);
      }
      this.setChildrenColor(treeNode.children, this.count, 1);
      this.count += 1;
      return undefined;
    }
    return undefined;
  }

  public legend(): any {
    return <ColorLabelProviderLegend[]>[
      <ColorLabelProviderLegend>{
        key: '',
        label: this.field,
        color: '',
        field: this.field,
        value: ''
    }]
  }

  protected configure() {
    if (this.configurationNode === null || this.configurationNode === undefined) {
      return;
    }
    /* Set the field */
    this.field = this.configurationNode.formFieldId;
    /* Set the fields */
    this.fields = this.fields.clear();
    const children = this.configurationNode.children.sort((a, b) => a.positionX - b.positionX);
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      this.fields = this.fields.set('' + child.formId, { label: child.name, color: child.color });
      /* Matrix */
      this.fieldMatrix.push('' + child.formId);
    }
  }

}
