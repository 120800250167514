import { Record, Set, Map } from 'immutable';
import { DataModelState, RecordFactory, RequestDiffRecord } from '../shared';
import { IUser, IUserAttributes } from '../connector/models';

const userStateRecord = Record({
  ids: Set<string>(),
  tempIdsMap: Map<string, string>(),
  entities: Map<string, User>(),
  relationships: Map<string, UserRelationships>(),
  isBusy: 0,
  diff: new RequestDiffRecord()
});

export class UserState extends userStateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, User>;
  relationships: Map<string, UserRelationships>;
  isBusy: number;
  diff: RequestDiffRecord;
}

const userRecord = Record(<IUserAttributes>{
  id: undefined,
  hrId: undefined,
  name: undefined,
  email: undefined,
  is_superuser: undefined,
  permissions: undefined,
  language: undefined,
  settings: undefined,
  relationships: undefined
});

export class User extends userRecord implements IUserAttributes {
  id: number;
  hrId: number;
  name: string;
  email: string;
  is_superuser: boolean;
  permissions: any;
  language: string;
  settings: any;
  relationships: UserRelationships;

  hasAccess(permission: string) {
    if (permission !== 'templates') {
      return true;
    }
    return this.is_superuser;
  }

  static fromJSONAPI(user: IUser) {
    return RecordFactory.entity.fromJSONAPI<User>(User, user);
  }
}

const relationshipsRecord = Record({
  humanresources: undefined
});

export class UserRelationships extends relationshipsRecord {
  humanresources: any;

  static fromJSONAPI(user: IUser) {
    return RecordFactory.relationships.fromJSONAPI<UserRelationships>(UserRelationships, user);
  }
}
