import { INodeData } from '../connector';

import { IPayload } from '../shared/common.models';
import { IAction, IFailAction, ISuccessAction } from '../shared/common.interfaces';

export class NodeDataAction {
  static key = 'NodeData';

  static LOAD = `[${NodeDataAction.key}] Load`;
  static LOAD_SUCCESS = `[${NodeDataAction.key}] Load success`;
  static LOAD_FAIL = `[${NodeDataAction.key}] Load fail`;

  static CREATE_SUCCESS = `[${NodeDataAction.key}] Create success`;

  static UPDATE = `[${NodeDataAction.key}] Update`;
  static UPDATE_GO = `[${NodeDataAction.key}] Update Go`;
  static UPDATE_SUCCESS = `[${NodeDataAction.key}] Update success`;
  static UPDATE_FAIL = `[${NodeDataAction.key}] Update fail`;

  static UPDATE_WITH_TOKEN = `[${NodeDataAction.key}] Update with token`;

  static ADD_RELATIONSHIP = `[${NodeDataAction.key}] Add Relationship`;
  static REMOVE_RELATIONSHIP = `[${NodeDataAction.key}] Remove Relationship`;

  static LOAD_AUDITS = `[${NodeDataAction.key}] Load Audits`;
  static LOAD_AUDITS_SUCCESS = `[${NodeDataAction.key}] Load Audits success`;
  static LOAD_AUDITS_FAIL = `[${NodeDataAction.key}] Load Audits fail`;

  static LOAD_BY_TYPES = `[${NodeDataAction.key}] Load By Types`;
  static LOAD_BY_TYPES_SUCCESS = `[${NodeDataAction.key}] Load By Types success`;
  static LOAD_BY_TYPES_FAIL = `[${NodeDataAction.key}] Load By Types fail`;

  public load(id: string, includes: string[] = []) {
    return {
      type: NodeDataAction.LOAD,
      payload: {
        id: id,
        includes: includes
      }
    };
  }

  public loadSuccess(data: INodeData[]) {
    data = data.filter(d => d.id !== '0');
    return {
      type: NodeDataAction.LOAD_SUCCESS,
      payload: data
    };
  }

  public loadFail(error: any) {
    return {
      type: NodeDataAction.LOAD_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

  public createSuccess(payload: IPayload, response: INodeData[]) {
    return {
      type: NodeDataAction.CREATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public update(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeDataAction.UPDATE,
      payload: {
        data: data
      }
    };
  }

  public updateGo(data: IPayload | IPayload[]): IAction {
    if (!Array.isArray(data)) {
      data = [data];
    }
    return {
      type: NodeDataAction.UPDATE_GO,
      payload: {
        data: data
      }
    };
  }

  public updateWithToken(payload: IPayload, token: string): IAction {
    return {
      type: NodeDataAction.UPDATE_WITH_TOKEN,
      token: token,
      payload: payload
    };
  }

  public updateSuccess(payload: IPayload, response: INodeData | INodeData[]): ISuccessAction {
    return {
      type: NodeDataAction.UPDATE_SUCCESS,
      payload: {
        request: payload,
        response: response
      }
    };
  }

  public updateFail(payload: IPayload, error: any): IFailAction {
    return {
      type: NodeDataAction.UPDATE_FAIL,
      payload: {
        request: payload,
        response: error
      }
    };
  }

  public addRelationship(id: string, type: string, relationship: string) {
    return {
      type: NodeDataAction.ADD_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public removeRelationship(id: string, type: string, relationship: string) {
    return {
      type: NodeDataAction.REMOVE_RELATIONSHIP,
      payload: {
        id: id,
        type: type,
        relationship: relationship
      }
    };
  }

  public autorefreshUpdate(data: any) {
    return {
      type: NodeDataAction.UPDATE_SUCCESS,
      payload: data
    };
  }

  public autorefreshCreate(data: any) {
    return {
      type: NodeDataAction.CREATE_SUCCESS,
      payload: data
    };
  }

  public loadAudits(id: string, data: any = {}) {
    return {
      type: NodeDataAction.LOAD_AUDITS,
      payload: {
        id: id,
        data: data
      }
    };
  }

  public loadByTypes(types: number[]) {
    return {
      type: NodeDataAction.LOAD_BY_TYPES,
      payload: {
        data: types
      }
    };
  }

  public loadByTypesSuccess(response: INodeData[]) {
    return {
      type: NodeDataAction.LOAD_BY_TYPES_SUCCESS,
      payload: response
    };
  }

  public loadByTypesFail(error: any) {
    return {
      type: NodeDataAction.LOAD_BY_TYPES_FAIL,
      payload: {
        request: undefined,
        response: error
      }
    };
  }

}
