import { Store } from '@ngrx/store';
import { DataModelState } from './common.models';
import { List, Map } from 'immutable';
import { isNullOrUndefined } from 'util';
import { NaturalSort } from 'angular2-natural-sort';
import { NodeState } from '../node';

function mergeEntityWithDefault(enitity: any, defaultMap: Map<string, any>): any {
  defaultMap.forEach((v, k) => {
    if (enitity.has(k) && isNullOrUndefined(enitity.get(k))) {
      enitity = enitity.set(k, v);
    }
  });
  return enitity;
}

export function distinct(element: any) {
  return !isNullOrUndefined(element) ? element.id + element.updatedAt : element;
}

export function getEntityMap() {
  return (state$: Store<DataModelState>) => state$.select(s => s.entities);
}

export function getFromMap(from: string) {
  return (state$: Store<DataModelState>) => state$
    .select(s => s[from]);
}

export function getWithDefault(id: string, defaultMap?: Map<string, any>) {
  return (state$: Store<DataModelState>) => state$
    .select(s => {
      let enitity = s.entities.get('' + id);
      if (!isNullOrUndefined(enitity) && !isNullOrUndefined(defaultMap)) {
        enitity = mergeEntityWithDefault(enitity, defaultMap);
      }
      return enitity;
    });
}

export function getManyWithDefault(ids?: string[], defaultMap?: Map<string, any>, sort ?: string) {
  return (state$: Store<DataModelState>) => state$
    .let(getEntityMap())
    .map(entities => {
      let _entities = !ids ? entities.toList() : List<any>(ids).map(id => entities.get('' + id)).filter(entity => !!entity).toList();
      if (!isNullOrUndefined(defaultMap)) {
        _entities = _entities.map(enitity => mergeEntityWithDefault(enitity, defaultMap)).toList();
      }
      if (!isNullOrUndefined(sort)) {
        _entities = _entities.sort((a: any, b: any) => NaturalSort.SORT(a[sort], b[sort])).toList();
      }
      return _entities;
    });
}

export function get(id: string) {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.entities.get('' + id));
}

export function getFirst() {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.entities.first());
}

export function getMany(ids?: string[], sort ?: string) {
  return (state$: Store<DataModelState>) => state$
    .let(getEntityMap())
    .map(entities => {
      const _entities = !ids ? (!!entities.toList ? entities.toList() : List(entities)) : List<any>(ids).map(id => entities.get('' + id)).filter(entity => !!entity).toList();
      return isNullOrUndefined(sort) ? _entities : _entities.sort((a: any, b: any) => NaturalSort.SORT(a[sort], b[sort])).toList();
    });
}

export function geFrom(from: string) {
  return (state$: Store<DataModelState>) => state$
    .let(getFromMap(from));
}

export function getAll() {
  return (state$: Store<DataModelState>) => state$
    .let(getEntityMap());
}

export function getBy(by: string, value: string) {
  return (state$: Store<DataModelState>) => state$
    .let(getEntityMap())
    .map(entities => {
      return List<any>(entities).filter(entity => {
        entity = entity[1];
        if (entity.has(by)) {
          return ('' + entity.get(by)) === value;
        } else {
          return entity;
        }
      }).toList();
    });
}

export function getRelationships(id: string) {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.relationships.get('' + id));
}

export function has(id: string) {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.ids.includes('' + id));
}

export function getIsBusy() {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.isBusy);
}

export function getIsBusyIPC() {
  return (state$: Store<NodeState>) => state$
    .select(s => s.isBusyIPC);
}

export function getDiff() {
  return (state$: Store<DataModelState>) => state$
    .select(s => s.diff);
}
