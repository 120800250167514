import { EventEmitter, Injectable} from '@angular/core';
import { Store } from '@ngrx/store';
import { Socket } from '../connector';

import { AppState } from '../../app.reducer';

import { NotificationAction } from './notifications.actions';
import * as selectors from './notifications.selectors';
import { environment } from '../../../../environments/environment';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class NotificationService extends SharedService {

  private notif = new EventEmitter();

  public constructor(protected store: Store<AppState>, protected action: NotificationAction, private socket: Socket) {
    super(store, action, selectors);
    this.notif.subscribe((value: any) => this.notificationHandler(value));
  }

  public connectToNotificationRoom(userId: string) {
    this.socket.connectToNotificationRoom(environment, parseInt(userId), 'notification', this.notif);
  }

  public getLatestNotificationsOfUser() {
    // this.store.dispatch(this.action.load());
  }

  public markAsRead(notificationId: number | number[]) {
    this.store.dispatch(this.action.update(notificationId));
  }

  public rollback(notificationId: number | number[]) {
    this.store.dispatch(this.action.rollback(notificationId));
  }

  public clear() {
    this.store.dispatch(this.action.clear());
  }

  private notificationHandler(value: any) {
    // TODO: implement this!
  }
}
