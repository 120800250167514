import * as crypto from 'crypto-js';

import { CoreHumanResource, PHRASE } from '../../core/interface/core.interface';
import { ExportModel } from './export.service';

export interface ImportData {
  key: string;
  businessAreaId?: number;
  modelId: number;
  models: ExportModel[];
  humanResources: CoreHumanResource[];
  groups: any[];
  groupHumanResources: any;
}

export class ImportService {

  public importFile(file, callback) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const extension = file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1);
      switch (extension) {
        case 'vm':
          this.importVM(e.target.result, callback);
          break;
      }
    };
    reader.readAsText(file);
  }

  protected importVM(data: any, callback: Function) {
    try {
      const bytes  = crypto.AES.decrypt(data, PHRASE);
      callback(JSON.parse(bytes.toString(crypto.enc.Utf8)));
    } catch (e) {
      console.error(e);
    }
  }

}
