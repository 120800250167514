import { Record, Set, Map } from 'immutable';
import { IEmail, IEmailAttributes } from '../connector';
import { RecordFactory, RequestDiffRecord } from '../shared';

const emailStateRecord = Record({
  diff: new RequestDiffRecord(),
  isBusy: 0
});

export class EmailState extends emailStateRecord {
  diff: RequestDiffRecord;
  isBusy: number;
}

const emailRecord = Record(<IEmailAttributes>{
  recipient: '',
  subject: '',
  message: '',
  from: ''
});

export class Email extends emailRecord implements IEmailAttributes {
  recipient: string;
  recipients: string[];
  subject: string;
  message: string;
  from: string;
  cc?: string[];

  static fromJSONAPI(email: IEmail) {
    return RecordFactory.entity.fromJSONAPI<Email>(Email, email);
  }
}
