import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isNullOrUndefined } from 'util';

import { HumanResource } from '../../api/humanresources/humanresources.models';
import { Node } from '../../api/nodes/nodes.models';
import { Template } from '../../api/templates';
import { Utilities } from '../../utilities/utilities';
import { SubscriptionService } from '../../utilities/subscription';
import { TranslationService } from '../../services/translation/translation.service';
import { TranslateParser, TranslateService } from '@ngx-translate/core';
import { AppShared } from '../../../app.shared';
import { environment } from '../../../../environments/environment';
import { Map } from 'immutable';

declare var jQuery: any;

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DynamicFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  public static VIEWTYPE = { STANDARD: '', MODAL: 'modal', OVERLAY: 'overlay', TABLE: 'table', REPORT: 'report', WIZARD: 'wizard', EDIT: 'edit', READONLY: 'readonly' };
  public static DIFFERS = 'DATASHEET.GENERAL.DIFFERS';

  /* Settings and definitions */
  @Input() type = '';
  @Input() viewType = DynamicFormComponent.VIEWTYPE.STANDARD;
  @Input() submitButton = true;
  @Input() cancelButton = true;
  @Input() deleteButton = false;
  @Input() element: any;
  @Input() formDefinition: any;
  @Input() maxHeight: number;
  @Input() onShow: EventEmitter<string>;
  @Input() afterShow: string;
  @Input() params = {};
  @Input() translation: TranslationService;
  @Input() formParams = '';

  @Input() labelCancel = 'GENERAL.CANCEL';
  @Input() labelClose = 'GENERAL.CLOSE';
  @Input() labelDelete = 'GENERAL.DELETE';
  @Input() labelSave = 'GENERAL.SAVE';

  /* Options input values */
  @Input() templates: Template[];
  @Input() template = new Template();
  @Input() humanResources: HumanResource[];
  @Input() nodes: Node[];
  @Input() languageFiles: any[];
  @Input() businessCalculations: any[];

  /* Output */
  @Output() update = new EventEmitter<FormGroup>();
  @Output() cancel = new EventEmitter();
  @Output() delete = new EventEmitter<any>();
  @Output() click = new EventEmitter<string>();
  @Output() tabSwitch = new EventEmitter();
  @Output() onChanges = new EventEmitter();
  @Output() valueChanges = new EventEmitter<any>();

  /* ViewChild */
  @ViewChild('form', { static: false }) formElement: any;

  public formGroup: FormGroup = new FormGroup({});
  public templateProperties = { tabs: [] };

  public readOnlyForm = false;

  public overlayExpanded = false;
  public overlayClassName = 'card card-grid p-0';

  public reset = new EventEmitter();

  private subscriptionService = new SubscriptionService();

  public constructor(private translateService: TranslateService, private translateParser: TranslateParser, private appShared: AppShared) {}

  public ngOnInit() {
    if (!isNullOrUndefined(this.onShow)) {
      this.subscriptionService.add('onShow', this.onShow.subscribe(selector => this.onAfterShow(selector)));
    }
    if (isNullOrUndefined(this.translation)) {
      this.translation = new TranslationService(this.translateService, this.translateParser, this.appShared);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!isNullOrUndefined(changes['element'])) {
      this.init();
    }
  }

  public ngOnDestroy() {
    this.subscriptionService.remove();
  }

  public ngAfterViewInit(): void {
    if (!isNullOrUndefined(this.afterShow)) {
      jQuery(this.formElement.nativeElement).find(this.afterShow).focus();
    }
  }

  public onCancel() {
    this.cancel.next();
    if (this.viewType === DynamicFormComponent.VIEWTYPE.REPORT) {
      this.reset.next();
    }
  }

  public onDelete() {
    this.delete.next(this.element);
  }

  public onSubmit(event: any) {
    event.stopPropagation();
    event.preventDefault();
    this.update.next(this.formGroup);
  }

  public onClick(type: string) {
    this.click.next(type);
  }

  public onSwitchTab(entry: any) {
    this.tabSwitch.next(entry);
  }

  public onExpand() {
    if (this.overlayExpanded) {
      this.overlayClassName = this.overlayClassName.replace(' expanded', '');
    } else {
      this.overlayClassName = this.overlayClassName + ' expanded';
    }
    this.overlayExpanded = !this.overlayExpanded;
  }

  private getReadOnlyStatus(entries: { entry: any, children: any[] }[], readOnly: boolean[] = []) {
    const count = entries.length;
    for (let i = 0; i < count; i++) {
      const entry = entries[i];
      if (!isNullOrUndefined(entry.entry.controlType)) {
        readOnly.push(entry.entry.readonly);
      }
      if (!isNullOrUndefined(entry.children) && entry.children.length > 0) {
        readOnly = this.getReadOnlyStatus(entry.children, readOnly);
      }
    }
    return readOnly;
  }

  private areAllReadOnly(entries: any) {
    const readOnly = this.getReadOnlyStatus(entries);
    const count = readOnly.length;
    let readOnlys = 0;
    for (let i = 0; i < count; i++) {
      if (readOnly[i] === true) {
        readOnlys++;
      }
    }
    return count !== 0 && readOnlys === count;
  }

  private init() {
    if (!isNullOrUndefined(this.formDefinition)) {
      this.templateProperties = this.formDefinition;
      this.formGroup = Utilities.toFormGroup(this.templateProperties.tabs, this.element);
    } else {
      if (!isNullOrUndefined(this.template[this.type])) {
        if (this.appShared.entryMap.has(this.type)) {
          this.templateProperties = { tabs: this.mergeTemplate(this.template[this.type].tabs, this.appShared.entryMap.get(this.type)) };
        } else {
          this.templateProperties = this.template[this.type];
        }
      } else if (!isNullOrUndefined(this.template.reports[this.type])) {
        const entry = this.template.reports[this.type].filter(e => e.type === 'table');
        if (entry.length > 0) {
          this.templateProperties = { tabs: entry[0].configuration.fields };
        }
      }
      if (!isNullOrUndefined(this.templateProperties)) {
        /* Filter out unsused tabs */
        this.templateProperties.tabs = this.templateProperties.tabs.filter(tab => !tab.children || tab.children.length > 0);
        if (this.type === 'nodes' && this.template.modeltype !== 'workflow') {
          const hasAudit = this.templateProperties.tabs.filter(entry => entry.entry.key === 'audits').length > 0;
          if (!hasAudit) {
            this.templateProperties.tabs.splice(this.templateProperties.tabs.length - 1, 0, { entry: { key: 'audits', label: 'NODE.DATASHEET.HEADERS.HEADER4'}});
          }
        }
        this.readOnlyForm = this.areAllReadOnly(this.templateProperties.tabs);
        this.formGroup = Utilities.toFormGroup(this.templateProperties.tabs, this.element);
      }
    }
    this.formGroup.valueChanges.subscribe(changes => this.valueChanges.emit(this.formGroup));
  }

  private onAfterShow(selector: string) {
    jQuery(this.formElement.nativeElement).find(selector).first().focus().select();
    this.subscriptionService.add('onChanges', this.formGroup.valueChanges.subscribe(changes => this.onChanges.next(changes)));
  }

  private mergeTemplate(items: any[], entryMap: Map<string, any>, result = []) {
    const count = items.length;
    for (let i = 0; i < count; i++) {
      const item = items[i];
      if (entryMap.has(item.entry.key)) {
        item.entry = entryMap.get(item.entry.key);
      }
      if (!isNullOrUndefined(item.children)) {
        item.children = this.mergeTemplate(item.children, entryMap);
      }
      result.push(item);
    }
    return result;
  }

}
