import { Map, OrderedMap } from 'immutable';

import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { CoreLegend, TreeNode } from '../../../core/interface/core.interface';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';
import { isArray, isNullOrUndefined } from 'util';
import { WidgetTab } from '../../../components/widget/interface/widget.interface';
import { NODES_TYPE_COLUMN } from '../../../shared/api/nodes/nodes.models';

export class ColorLabelProviderNodeTypes extends ColorLabelProviderAbstract {

  public params = Map<number, any>();

  public constructor(params: any[]) {
    super();
    if (!isNullOrUndefined(params)) {
      params.forEach((param, i) => {
        this.params = this.params.set(param.key, param);
      });
    }
  }

  public color(treeNode: TreeNode) {
    const nodeType = parseInt('' + treeNode.nodeType);
    if (!isNullOrUndefined(this.params) && this.params.has(nodeType)) {
      return [this.params.get(nodeType).color];
    } else {
      return super.color();
    }
  }

  public legend(treeNodes?: OrderedMap<string, TreeNode>) {
    if (!isNullOrUndefined(treeNodes)) {
      const nodeTypes: number[] = <number[]> (isArray(treeNodes) ? treeNodes.map(treeNode => treeNode.nodeType) : treeNodes.map(treeNode => treeNode.nodeType).toArray());
      return this.params.filter(param => nodeTypes.indexOf(param.key) !== -1).map(param => <ColorLabelProviderLegend> { key: 'nodetype.' + param.key, color: param.color, label: param.label, field: 'nodeType', value: param.key }).toArray();
    }
    if (isNullOrUndefined(this.params)) {
      return [];
    }
    return this.params.map(param => <ColorLabelProviderLegend> { key: 'nodetype.' + param.key, color: param.color, label: param.label, field: 'nodeType', value: param.key }).toArray();
  }

  public legendByTabs(tabs: WidgetTab[]): CoreLegend[] {
    const legends = [];
    const count = tabs.length;
    const usedKeys = [];
    for (let i = 0; i < count; i++) {
      const tab = tabs[i];
      let param = this.params.get(tab.i);
      if (tab.node.nodeType === NODES_TYPE_COLUMN) {
        const columns = tab.node.children;
        const count2 = columns.length;
        for (let i2 = 0; i2 < count2; i2++) {
          const column = columns[i2];
          param = this.params.get(column.nodeType);
          if (tab.active && usedKeys.indexOf(param.key) === -1) {
            usedKeys.push(param.key);
            legends.push(<CoreLegend> { key: 'nodetype.' + param.key, color: param.color, label: column.name, field: 'nodeType', value: param.key, selected: true });
          }
        }
      } else if (tab.active) {
        legends.push(<CoreLegend> { key: 'nodetype.' + param.key, color: param.color, label: tab.content, field: 'nodeType', value: param.key, selected: true });
      }
    }
    return legends;
  }

}
