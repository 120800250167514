import { isNullOrUndefined } from 'util';
import { Map, Set } from 'immutable';

import { EisenhowerElement } from '../interface/eisenhower.interface';
import { NODES_TYPE_CATEGORYGROUP } from '../../../shared/api/nodes/nodes.models';
import { TreeNode } from '../../../core/interface/core.interface';
import { CoreUtilities } from '../../../core/utilities/core.utilities';
import { Injectable } from '@angular/core';

@Injectable()
export class EisenhowerService {

  public folders: TreeNode[];

  public yAxis: TreeNode;
  public xAxis: TreeNode;
  public matrix = [];
  public notAssignedFolders: TreeNode[] = [];

  public modelId: string;

  private categoryMatrix = Map<string, EisenhowerElement>();
  private parentNodeType: number;

  public constructor(private coreUtilities: CoreUtilities) {}

  public setParentNodeType(nodeType) {
    this.parentNodeType = nodeType;
    return this;
  }

  public getPriorityScore(treeNode: TreeNode): number {
    let priorityScore = 0;
    if (!isNullOrUndefined(this.xAxis) && !isNullOrUndefined(this.yAxis) && !isNullOrUndefined(this.xAxis.formFieldId) && this.xAxis.formFieldId !== '' && !isNullOrUndefined(this.yAxis.formFieldId) && this.yAxis.formFieldId !== '') {
      const xy = treeNode[this.xAxis.formFieldId] + ':' + treeNode[this.yAxis.formFieldId];
      if (this.categoryMatrix.has(xy)) {
        const category = this.categoryMatrix.get(xy).category;
        if (!isNullOrUndefined(category)) {
          priorityScore = category.sorting;
        }
      }
    }
    return priorityScore;
  }

  public setMatrix(fieldNodes: TreeNode[]) {
    /* Set the axis */
    this.yAxis = fieldNodes[0];
    this.xAxis = fieldNodes[1];
    /* Set the matrix */
    const matrix = [];
    if (isNullOrUndefined(this.xAxis) || isNullOrUndefined(this.yAxis)) {
      return;
    }
    /* Sort the children */
    this.xAxis.children = this.xAxis.children.sort((a, b) => a.positionX - b.positionX);
    this.yAxis.children = this.yAxis.children.sort((a, b) => a.positionX - b.positionX);
    /* Count */
    const countX = this.xAxis.children.length;
    const countY = this.yAxis.children.length;
    /* Iterate over elements */
    for (let i = 0; i < countX; i++) {
      const x = [];
      for (let j = 0; j < countY; j++) {
        /* Set the matrix element */
        const element = <EisenhowerElement> { y: this.yAxis.children[j], x: this.xAxis.children[i], category: undefined, treeNodes: [] };
        /* Get the category nodes */
        const xCategories = element.x.children.filter(child => child.nodeType === NODES_TYPE_CATEGORYGROUP);
        const yCategories = element.y.children.filter(child => child.nodeType === NODES_TYPE_CATEGORYGROUP);
        /* Counts */
        const countCategoriesX = xCategories.length;
        const countCategoriesY = yCategories.length;
        /* Set the category */
        for (let k = 0; k < countCategoriesY; k++) {
          const yCategory = yCategories[k];
          for (let lx = 0; lx < countCategoriesX; lx++) {
            const xCategory = xCategories[lx];
            if (yCategory.id === xCategory.id) {
              element.category = yCategory;
            }
          }
        }
        /* Store it */
        if (!isNullOrUndefined(this.xAxis.formFieldId) && this.xAxis.formFieldId !== '' && !isNullOrUndefined(this.yAxis.formFieldId) && this.yAxis.formFieldId !== '') {
          this.categoryMatrix = this.categoryMatrix.set(element.x.formId + ':' + element.y.formId, element);
        }
        /* Add the matrix element */
        x.push(element);
      }
      matrix.push(x);
    }
    /* Set the matrix */
    this.matrix = matrix;
  }

  public assignTreeNodes(treeNodes: TreeNode[]) {
    this.clearMatrix();

    const parentIds = this.folders !== undefined ? this.folders.map(folder => folder.id) : [];

    let parents = Map<string, TreeNode>();
    let assigned = Set<string>();
    let count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      const treeNode = treeNodes[i];
      /* Filter by parent node */
      if (this.folders !== undefined && !(treeNode.parentIds.length === 0 || this.coreUtilities.arraysShareElements(parentIds, treeNode.parentIds.map(id => '' + id)))) {
        assigned = assigned.add(treeNode.id);
        continue;
      }
      if (!isNullOrUndefined(this.xAxis) && !isNullOrUndefined(this.xAxis.formFieldId) && this.xAxis.formFieldId !== '' && !isNullOrUndefined(this.yAxis) && !isNullOrUndefined(this.yAxis.formFieldId) && this.yAxis.formFieldId !== '') {
        const xy = treeNode[this.xAxis.formFieldId] + ':' + treeNode[this.yAxis.formFieldId];
        if (this.categoryMatrix.has(xy)) {
          assigned = assigned.add(treeNode.id);
          const category = this.categoryMatrix.get(xy);
          category.treeNodes.push(treeNode);
          category.treeNodes = category.treeNodes.sort((a, b) => a.sorting - b.sorting);
          const parentNodes = !isNullOrUndefined(this.parentNodeType) ? this.coreUtilities.getParents(treeNode.unfilteredParents, false).filter(parent => parent.nodeType === this.parentNodeType) : treeNode.parents;
          const count2 = parentNodes.length;
          for (let i2 = 0; i2 < count2; i2++) {
            const parent = this.coreUtilities.makeImmutable(parentNodes[i2]);
            if (!parents.has(parent.id)) {
              parent.unfilteredChildren = [];
            }
            parents = parents.set(parent.id, parent);
          }
        }
      }
    }

    const notAssigned = treeNodes.filter(treeNode => !assigned.has(treeNode.id));
    let ids = Set<string>();
    count = notAssigned.length;
    for (let i = 0; i < count; i++) {
      const notAssignedNode = notAssigned[i];
      ids = ids.add(notAssignedNode.id);
      const parentNodes = this.folders === undefined ? [] : !isNullOrUndefined(this.parentNodeType) ? this.coreUtilities.getParents(notAssignedNode.unfilteredParents, false).filter(parent => parent.nodeType === this.parentNodeType) : notAssignedNode.parents;
      const count2 = parentNodes.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const parent = this.coreUtilities.makeImmutable(parentNodes[i2]);
        if (parents.has(parent.id)) {
          parent.unfilteredChildren.push(notAssignedNode);
        } else {
          parent.unfilteredChildren = [notAssignedNode];
        }
        parents = parents.set(parent.id, parent);
      }
    }
    const existingFolders = [];
    let notAssignedFolders = parents.filter(parent => this.modelId !== undefined && parent.modelId === this.modelId).map(parent => {
      existingFolders.push(parent.id);
      parent.children = parent.unfilteredChildren.filter(child => {
        if (ids.has(child.id)) {
          ids = ids.remove(child.id);
          return true;
        }
        return false;
      });
      return <TreeNode> { id: parent.id, name: parent.name, children: parent.children };
    }).toArray();
    if (ids.size > 0) {
      notAssignedFolders.push(<TreeNode> { id: '0', name: '', children: treeNodes.filter(treeNode => ids.has(treeNode.id)) });
    }
    /* Additional folders */
    if (this.folders !== undefined) {
      notAssignedFolders = notAssignedFolders.concat(this.folders.filter(folder => existingFolders.indexOf(folder.id) === -1).map(d => Map(d).set('children', []).toJS()));
    }
    this.notAssignedFolders = notAssignedFolders;
  }

  public getTreeNodeMaps(updates?: Map<string, Map<string, any>>): { treeNodesMap: Map<string, TreeNode>, updatesMap: Map<string, Map<string, any>> } {
    let treeNodes = Map<string, TreeNode>();
    /* Iterate over matrix */
    let count = this.matrix.length;
    for (let i = 0; i < count; i++) {
      const row = this.matrix[i];
      const count2 = row.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const cell = row[i2];
        const count3 = cell.treeNodes.length;
        for (let i3 = 0; i3 < count3; i3++) {
          const treeNode = cell.treeNodes[i3];
          const sorting = Math.ceil(((!isNullOrUndefined(cell.category) ? cell.category.sorting : 0) + parseFloat('0.' + i3)) * 10000);
          if (!isNullOrUndefined(updates)) {
            if (treeNode.sorting !== sorting) {
              updates = updates.set(treeNode.id, (updates.has(treeNode.id) ? updates.get(treeNode.id) : Map<string, any>()).set('sorting', sorting));
            }
            if ('' + treeNode[this.xAxis.formFieldId] !== '' + cell.x.formId) {
              updates = updates.set(treeNode.id, (updates.has(treeNode.id) ? updates.get(treeNode.id) : Map<string, any>()).set(this.xAxis.formFieldId, cell.x.formId));
            }
            if ('' + treeNode[this.yAxis.formFieldId] !== '' + cell.y.formId) {
              updates = updates.set(treeNode.id, (updates.has(treeNode.id) ? updates.get(treeNode.id) : Map<string, any>()).set(this.yAxis.formFieldId, cell.y.formId));
            }
          } else {
            treeNode.sorting = sorting;
          }
          treeNodes = treeNodes.set(treeNode.id, treeNode);
        }
      }
    }

    count = this.notAssignedFolders.length;
    for (let i = 0; i < count; i++) {
      const folder = this.notAssignedFolders[i];
      const count2 = folder.children.length;
      for (let i2 = 0; i2 < count2; i2++) {
        const treeNode = folder.children[i2];
        if (!isNullOrUndefined(updates)) {
          if ('' + treeNode[this.xAxis.formFieldId] !== '0' || '' + treeNode[this.yAxis.formFieldId] !== '0') {
            updates = updates.set(treeNode.id, Map<string, any>()
              .set(this.xAxis.formFieldId, '0')
              .set(this.yAxis.formFieldId, '0')
              .set('sorting', 0)
            );
          }
        } else {
          treeNode.sorting = 0;
        }
        treeNodes = treeNodes.set(treeNode.id, treeNode);
      }
    }
    return { treeNodesMap: treeNodes, updatesMap: updates };
  }

  public clearMatrix(draggedNode?: TreeNode, initialPosition?: string) {
    this.categoryMatrix = <Map<string, EisenhowerElement>> this.categoryMatrix.map(element => {
      if (isNullOrUndefined(draggedNode)) {
        element.treeNodes = [];
      } else {
        const rez = [];
        const coord = element.x.formId + ':' + element.y.formId;
        if (coord === initialPosition) {
          rez.push(draggedNode);
        } else {
          element.treeNodes.forEach(elem => {
            if (elem.id === draggedNode.id) {
              rez.push(elem);
            }
          });
        }

        element.treeNodes = rez;
      }
      return element;
    });
  }

}
