import { Observable } from 'rxjs/Observable';
import { Set, List, Map, OrderedMap, OrderedSet } from 'immutable';
import { isNull, isNullOrUndefined, isUndefined } from 'util';
import { UUID } from 'angular2-uuid';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs/Subject';

import { SubscriptionService } from '../../../shared/utilities/subscription';
import { Node, Relationship, Activity, Businessarea, NodeDataAction, NodeDataService, Template, NodeData, Group } from '../../../shared/api';
import { TreeNode } from './tree.node';
import { TreeRelationship } from './tree.relationship';
import { TreeActivity } from './tree.activity';
import { WordWrapper, WordWrapLine } from '../../../shared/utilities';
import { Screen } from '../../splitscreen/screen';
import { RELATIONSHIP_TYPE_DEFAULT, RelationshipRelationships } from '../../../shared/api/relationships/relationships.models';
import {IPayload, RequestDiffRecord} from '../../../shared/api/shared';
import { NodeStructureAction } from '../../../shared/api/nodestructures';
import { RelationshipAction, RelationshipCreate } from '../../../shared/api/relationships';
import { TreeCacheService } from './tree.cache.service';
import { RadialMenuItem } from '../radial-menu/radial-menu-item';
import { LegendItem } from '../../../shared/legend/legend.item';
import { ColorLabelProvider } from '../colorlabelprovider/colorlabelprovider.service';
import { TreeFilter } from './tree.filter.service';
import { Utilities } from '../../../shared/utilities/utilities';
import { HumanResource } from '../../../shared/api/humanresources';
import { Model } from '../../../shared/api/models';
import { Subset } from '../../../shared/api/subsets';
import { AppShared } from '../../../app.shared';
import { NodeGrouping, NodeSidestep } from '../../../shared/api/nodes/nodes.models';
import { NotifyService } from '../../../shared/notifications/notification.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ApiData } from '../../../shared/api/api.service';
import { TreeComponentPositioning } from './components/tree.component.positioning';
import { NaturalSort } from 'angular2-natural-sort';
import { ChangeDetectorRef } from '@angular/core';
import { TreeComponentHashing } from './components/tree.component.hashing';

export class Tree {

  /* Output subjects */
  public nodes = new BehaviorSubject<OrderedMap<string, TreeNode>>(OrderedMap<string, TreeNode>());
  public unfilteredNodes = new BehaviorSubject<OrderedMap<string, TreeNode>>(OrderedMap<string, TreeNode>());
  public relationships = new BehaviorSubject<Map<string, TreeRelationship>>(Map<string, TreeRelationship>());
  public unfilteredRelationships = new BehaviorSubject<Map<string, TreeRelationship>>(Map<string, TreeRelationship>());
  public activities = new BehaviorSubject<OrderedMap<string, TreeActivity>>(OrderedMap<string, TreeActivity>());
  public legend = new BehaviorSubject<List<LegendItem>>(List<LegendItem>());
  public filters: string[] = [];
  public invalidRelationships = Set<TreeRelationship>();
  public maxPositions = { '0': 0, '1': 0, '2': 0, '3': 0 };
  public maxSubLevels = { '0': 0, '1': 0, '2': 0, '3': 0 };
  public levelsCount = { '0': 0, '1': 0, '2': 0, '3': 0 };
  public relationshipNodeMap = Map<string, TreeRelationship>();

  public useSelection = true;

  /* Listeners */
  public businessarea: Observable<Businessarea>;
  public models: Observable<List<Model>>;
  public subModels: Observable<List<Model>>;
  public subSets: Observable<List<Subset>>;
  public nodesDiff: Observable<RequestDiffRecord>;

  /* Internal maps */
  public _nodes = OrderedMap<string, TreeNode>();
  public _nodeData = OrderedMap<string, NodeData>();
  public _relationships = Map<string, TreeRelationship>();
  public _activities = List<Activity>();
  public _activitiesMap = OrderedMap<string, TreeActivity>();
  public _humanResources = List<HumanResource>();
  public _groups = List<Group>();
  public _models = List<Model>();
  public _subModels = List<Model>();
  public _subSets = List<Subset>();
  public _modelsMap = Map<string, Model>();
  public _subModelsMap = Map<string, Model>();
  public _subSetsMap = Map<string, Subset>();
  public _subLevelMap = Map<string, number>();

  /* Phantom data */
  public phantomNode: TreeNode;
  public phantomRelationship: TreeRelationship;

  /* Tools */
  private _cdr: ChangeDetectorRef;

  /* Cache */
  private cache = new TreeCacheService();
  private nodesCheckSum = '';
  private relationshipsCheckSum = '';
  private activitiesCheckSum = '';
  private checkSum = '';

  /* Internal settings */
  private _subLevel = false;
  private _addActivities = false;
  private _breakAtLevels = true;
  private _topMostParent: string | boolean = false;
  private _challenges = false;
  private _colorLabelProvider: ColorLabelProvider;
  private _screen: Screen;
  private _translate: TranslateService;
  private _deletedRelationships = Set<string>();
  private _appShared: AppShared;
  private _notifyService: NotifyService;
  private _nodeDataService: NodeDataService;
  private _wordWrap: WordWrapper;
  private _wordWrapCheckSum: string;
  private chainIdCheckSum: string;
  private subTreeCheckSum: string;
  private autoPositioning: {crossed: boolean, uncrossed: boolean};
  private _useCache = true;
  private isWorkflow = false;
  private template: Template;

  /* Filter */
  private filter = Set<string>();
  private filterValues = Map<string, any>();
  private filterSoft = Map<string, boolean>();

  /* Color Label Provider */
  private colorLabelProviderColor = '';
  private colorLabelProviderLabelTop = '';
  private colorLabelProviderLabelBottom = 'name';
  private colorLabelProviderParams: any;

  /* Internal services */
  private subscriptionService = new SubscriptionService();

  /* Grouping */
  private groupingRelationships = Map<string, TreeRelationship>();
  private groupingAddMap = Map<string, string>();
  private groupingRemoveMap = Map<string, string>();
  private groupingGroupedMap = Map<string, string>();
  private groupingAddMaps = Map<string, NodeGrouping>();

  private onGroupingAdd = new Subject<Map<string, Set<NodeGrouping>>>();
  private onGroupingRemove = new Subject<Set<string>>();
  private onGroupingRelationshipsAdd = new Subject<RelationshipCreate[]>();
  private onGroupingRelationshipsRemove = new Subject<string[]>();
  private onNodeDataAddRelationship = new Subject<any>();
  private onNodeDataRemoveRelationship = new Subject<any>();
  private onGroupingTag = new Subject<Map<string, Set<string>>>();
  private onGroupingUntag = new Subject<Map<string, Set<string>>>();

  /* Sidestep */
  private sidestepMap = Map<string, NodeSidestep>();
  private onSidestep = new Subject<Map<string, NodeSidestep>>();
  private onSidestepCreated = new Subject<Map<string, string>>();

  /* Positioning */
  private positioning = new TreeComponentPositioning();

  /* Hashing */
  private hashing = new TreeComponentHashing();

  /* Settings */
  public subLevel(subLevel: boolean): Tree {
    this._subLevel = subLevel;
    return this;
  }

  public addActivities(addActivities: boolean): Tree {
    this._addActivities = addActivities;
    return this;
  }

  public breakAtLevels(breakAtLevels: boolean): Tree {
    this._breakAtLevels = breakAtLevels;
    return this;
  }

  public topMostParent(topMostParent: boolean | string): Tree {
    this._topMostParent = topMostParent;
    return this;
  }

  public challenges(challenges: boolean): Tree {
    this._challenges = challenges;
    return this;
  }

  public useCache(useCache: boolean): Tree {
    this._useCache = useCache;
    return this;
  }

  public colorLabelProvider(colorLabelProvider: ColorLabelProvider): Tree {
    this._colorLabelProvider = colorLabelProvider;
    return this;
  }

  public screen(screen: Screen): Tree {
    this._screen = screen;
    /* Set screen */
    this.positioning.screen = screen;
    /* Add listener on auto positioning change */
    this.subscriptionService.add('auto.positioning', screen.onAutoPosition.subscribe(_ => this.onAutoPositionChange(_)));
    return this;
  }

  public cdr(cdr: ChangeDetectorRef): Tree {
    this._cdr = cdr;
    return this;
  }

  public translate(translate: TranslateService): Tree {
    this._translate = translate;
    return this;
  }

  public appShared(appShared: AppShared): Tree {
    this._appShared = appShared;
    this.colorLabelProviderColor = this._appShared.colorLabelProviderColor;
    this.colorLabelProviderLabelTop = this._appShared.colorLabelProviderLabelTop;
    this.colorLabelProviderLabelBottom = this._appShared.colorLabelProviderLabelBottom;
    this.subscriptionService.add('template', appShared.selectedTemplate.subscribe(selectedTemplate => {
      this.template = selectedTemplate;
      this.isWorkflow = !isNullOrUndefined(selectedTemplate) && selectedTemplate.modeltype === 'workflow';
    }));
    this.subscriptionService.add('subSetClicked', appShared.subSetClicked.subscribe(subset => this.onSubSetClicked(subset)));
    return this;
  }

  public notifyService(notifyService: NotifyService): Tree {
    this._notifyService = notifyService;
    return this;
  }

  public nodeDataService(nodeDataService: NodeDataService): Tree {
    this._nodeDataService = nodeDataService;
    this.subscriptionService.add('node.data.all', this._nodeDataService.all().subscribe(nodeData => nodeData.forEach(nodeDatum => {
      this._nodeData = this._nodeData.set(nodeDatum.id, nodeDatum);
    })));
    this.subscriptionService.add('node.data.diff', this._nodeDataService.diff.subscribe((diff: RequestDiffRecord) => this.onNodeDataDiff(diff)));
    return this;
  }

  public addEmitter(type: string, listener: any) {
    switch (type) {
      case 'grouping.add':
        this.subscriptionService.add('nodes.grouping.add', this.onGroupingAdd.subscribe(d => listener(d)));
        break;
      case 'grouping.remove':
        this.subscriptionService.add('nodes.grouping.remove', this.onGroupingRemove.subscribe(d => listener(d)));
        break;
      case 'grouping.tag':
        this.subscriptionService.add('nodes.grouping.tag', this.onGroupingTag.subscribe(d => listener(d)));
        break;
      case 'grouping.untag':
        this.subscriptionService.add('nodes.grouping.untag', this.onGroupingUntag.subscribe(d => listener(d)));
        break;
      case 'grouping.relationship.add':
        this.subscriptionService.add('relationships.grouping.add', this.onGroupingRelationshipsAdd.subscribe(d => listener(d)));
        break;
      case 'grouping.relationship.remove':
        this.subscriptionService.add('relationships.grouping.remove', this.onGroupingRelationshipsRemove.subscribe(d => listener(d)));
        break;
      case 'node.data.add.relationship':
        this.subscriptionService.add('node.data.add.relationship', this.onNodeDataAddRelationship.subscribe(d => listener(d)));
        break;
      case 'node.data.remove.relationship':
        this.subscriptionService.add('node.data.remove.relationship', this.onNodeDataRemoveRelationship.subscribe(d => listener(d)));
        break;
      case 'sidestep':
        this.subscriptionService.add('sidestep', this.onSidestep.subscribe(d => listener(d)));
        break;
      case 'sidestep.created':
        this.subscriptionService.add('sidestep.created', this.onSidestepCreated.subscribe(d => listener(d)));
        break;
    }
    return this;
  }

  public addListener(type: string, observable: Observable<any>) {
    switch (type) {
      case 'data':
        this.subscriptionService.add('data', observable.subscribe(apiData => this.onDataLoaded(apiData)));
        break;
      case 'nodes':
        this.useSelection = false;
        this.subscriptionService.add('nodes', observable.subscribe(nodes => this.onNodesLoaded(nodes)));
        break;
      case 'nodesDiff':
        this.nodesDiff = observable;
        this.subscriptionService.add('nodes.diff', observable.subscribe((diff: RequestDiffRecord) => this.onNodesDiff(diff)));
        break;
      case 'relationshipsDiff':
        this.subscriptionService.add('relationships.diff', observable.subscribe((diff: RequestDiffRecord) => this.onRelationshipsDiff(diff)));
        break;
      case 'activitiesDiff':
        this.subscriptionService.add('activities.diff', observable.subscribe((diff: RequestDiffRecord) => this.onActivitiesDiff(diff)));
        break;
      case 'radial':
        this.subscriptionService.add('radial', observable.subscribe((radialItem: RadialMenuItem) => this.onRadialEvent(radialItem)));
        break;
      case 'language':
        this.subscriptionService.add('language', observable.subscribe(language => this.onLanguageChange(language)));
        break;
      case 'humanResources':
        this.subscriptionService.add('humanResources', observable.subscribe(humanResources => this.onHumanResourcesLoaded(humanResources)));
        break;
      case 'groups':
        this.subscriptionService.add('groups', observable.subscribe(groups => this.onGroupsLoaded(groups)));
        break;
      case 'businessarea':
        this.businessarea = observable;
        break;
      case 'models':
        this.models = observable;
        this.subscriptionService.add('models', observable.subscribe(models => this.onModelsLoaded(models)));
        break;
      case 'subSets':
        this.subSets = observable;
        this.subscriptionService.add('subSets', observable.subscribe(subSets => this.onSubSetsLoaded(subSets)));
        break;
    }
    return this;
  }

  public forget() {
    this.subscriptionService.remove();
    this.nodesCheckSum = '';
    this.relationshipsCheckSum = '';
    this.activitiesCheckSum = '';
    this.checkSum = '';
    this.cache.flushAll();
  }

  public flushCache(cache: string, key: string) {
    this.cache.flush(cache, key);
  }

  public addNode(treeNode: TreeNode, event = true): TreeNode {
    if (isNullOrUndefined(this.phantomNode) || this.phantomNode.id !== treeNode.id) {
      this.setNodes(this._nodes.set(treeNode.id, this.updateUUID(treeNode, true)), event);
    }
    return treeNode;
  }

  public addNodeWithParent(parentNode: TreeNode, childNode: TreeNode): TreeRelationship {
    const relationship = this.getPhantomRelationship(parentNode, this.addNode(childNode, true), true);
    return relationship;
  }

  public addNodeWithChild(parentNode: TreeNode, childNode: TreeNode): TreeRelationship {
    const relationship = this.getPhantomRelationship(this.addNode(parentNode, true), childNode, true);
    return relationship;
  }

  public updateNodes(nodes = this._nodes) {
    this._nodes = nodes.map(node => this.updateNode(node)).toOrderedMap();
  }

  public updateNode(treeNode: TreeNode): TreeNode {
    return this.updateUUID(treeNode, true);
  }

  public removeNode(id: string, event = true): TreeNode {
    const treeNode = this._nodes.get(id);
    this.setNodes(this._nodes.remove(id), event);
    return treeNode;
  }

  public addFilter(by?: string, value?: any, soft = false): Tree {
    if (!isNullOrUndefined(by)) {
      this.filter = this.filter.add(by);
    }
    if (!isNullOrUndefined(value)) {
      this.filterValues = this.filterValues.set(by, value);
    }
    this.filterSoft = this.filterSoft.set(by, soft);
    return this;
  }

  public removeFilter(by: string): Tree {
    this.filter = this.filter.remove(by);
    return this;
  }

  public filterByColorLabelProvider(node: Node, legendItems: LegendItem[]) {
    let visible = true;
    const count = legendItems.length;
    for (let i = 0; i < count; i++) {
      const legendItem = legendItems[i];
      if (visible) {
        switch (legendItem.field) {
          case 'difference':
            visible = !(this._colorLabelProvider.difference().calculate(node) === legendItem.value);
            break;
          case 'sidestep':
            visible = !(this._colorLabelProvider.sidestep().calculate(node) === legendItem.value);
            break;
          case 'targetDate':
            visible = !(this._colorLabelProvider.targetDate().calculate(node) === legendItem.value);
            break;
          case 'model':
            visible = node.relationships.models.indexOf(legendItem.value) === -1;
            break;
          case 'subModel':
            visible = node.relationships.models.indexOf(legendItem.value) === -1;
            break;
          case 'subSet':
            visible = node.relationships.subsets.indexOf(legendItem.value) === -1;
            break;
          default:
            visible = !(node.get(legendItem.field) === legendItem.value);
        }
      } else {
        break;
      }
    }
    return visible;
  }

  public filterNodes(event = true) {
    let nodes = this._nodes;
    if (this.filter.size === 0) {
      this.filterRelationships(nodes, event);
    }
    this.filters = this.filter.toArray();
    if (this._cdr) { this._cdr.detectChanges(); }
    this.filter.forEach(filterBy => {
      const value = this.filterValues.get(filterBy);
      let _nodes = nodes;
      switch (filterBy) {
        case 'humanResources':
          _nodes = TreeFilter.byHumanResources(nodes, value);
          break;
        case 'subsets':
          _nodes = TreeFilter.bySubSets(nodes, value);
          break;
        case 'closed':
          _nodes = TreeFilter.closed(nodes);
          break;
        case 'notclosed':
          _nodes = TreeFilter.notClosed(nodes);
          break;
        case 'active':
          _nodes = TreeFilter.active(nodes);
          break;
        case 'kadb':
          _nodes = TreeFilter.kaDb(nodes);
          break;
        case 'directchain':
          _nodes = TreeFilter.directChain(nodes, value);
          break;
        case 'subtree':
          _nodes = TreeFilter.directChain(nodes, value);
          break;
      }
      if (this.filterSoft.get(filterBy)) {
        nodes = nodes.map(node => this.updateNode(<TreeNode> node.set('filtered', !_nodes.has(node.id)).set('className', _nodes.has(node.id) ? Utilities.removeClass(node.className, 'filtered') : Utilities.addClass(node.className, 'filtered')))).toOrderedMap();
      } else {
        nodes = this.setHierarchy(_nodes, this.filterRelationships(_nodes, event));
      }
    });

    if (event) {
      this.nodes.next(nodes);
    }
    return nodes;
  }

  public onDirectChainSelected(chainIds: string[]) {
    if (chainIds.length === 0) {
      this.removeFilter('directchain');
    } else {
      this.addFilter('directchain', chainIds, true).filterNodes();
    }
    this.filterNodes();
  }

  public onSubTreeSelected(chainIds: string[]) {
    if (chainIds.length === 0) {
      this.removeFilter('subtree');
    } else {
      this.addFilter('subtree', chainIds, true).filterNodes();
    }
    this.filterNodes();
  }

  public addPhantomNode(phantomNode: TreeNode, event = true) {
    this.phantomNode = this.addNode(phantomNode, event);
  }

  public addPhantomNodeWithRelationship(parentNode: TreeNode, childNode: TreeNode, phantom = 'child'): TreeNode {
    if (phantom === 'child') {
      this.addPhantomNode(childNode, false);
      this.phantomRelationship = this.addNodeWithParent(parentNode, childNode);
    } else {
      this.addPhantomNode(parentNode, false);
      this.phantomRelationship = this.addNodeWithChild(parentNode, childNode);
    }
    return this.phantomNode;
  }

  public removePhantomData() {
    this.removePhantomNode(isNullOrUndefined(this.phantomRelationship));
    this.removePhantomRelationship(true);
  }

  public removePhantomNode(event = true) {
    if (!isNullOrUndefined(this.phantomNode)) {
      this.removeNode(this.phantomNode.id, event);
      this.phantomNode = undefined;
    }
  }

  public removePhantomRelationship(event = true) {
    if (!isNullOrUndefined(this.phantomRelationship)) {
      this.removeRelationship(this.phantomRelationship.id, event);
      this.phantomRelationship = undefined;
    }
  }

  public addRelationship(relationship: TreeRelationship, event = true): TreeRelationship {
    this.setRelationships(this._relationships.set(relationship.id, this.updateUUID(relationship, true)), event);
    return relationship;
  }

  public updateRelationship(relationship: TreeRelationship, event = true): TreeRelationship {
    this.setRelationships(this._relationships.set(relationship.id, this.updateUUID(relationship, true)), event);
    return relationship;
  }

  public removeRelationship(id: string, event = true): TreeRelationship {
    const relationship = this._relationships.get(id);
    if (!!relationship && relationship.phantom) {
      // If the phantom relationship points to non-phantom nodes
      // then unset the parent or child so that they are not filtered out
      // also remove the entries from parent.children and child.parents
      this._deletedRelationships = this._deletedRelationships.add(id);
      this.setRelationships(this._relationships.remove(id), event);
    }
    return relationship;
  }

  public filterRelationships(treeNodes: OrderedMap<string, TreeNode>, event = true): Map<string, TreeRelationship> {
    const relationships = TreeFilter.byNodes(treeNodes, this._relationships);
    if (event) {
      this.relationships.next(relationships);
    }
    return relationships;
  }

  public calculateChildPosition(treeNode: TreeNode): number {
    if (this.autoPositioning.crossed) {
      return treeNode.autoposition;
    }
    if (this.autoPositioning.uncrossed) {
      return treeNode.autopositionUncrossed;
    }
    return treeNode.x;
  }

  public getTopMostNodes(nodes = this._nodes): Set<TreeNode> {
    /* The result */
    let result = Set<TreeNode>();
    /* Find the lowest level */
    let ll = 5;
    nodes.forEach(node => {
      if (node.subLevel === 0) {
        if (node.level < ll) {
          ll = node.level;
          result = result.clear();
        }
        if (ll === node.level) {
          result = result.add(node);
        }
      }
    });
    /* Return the result */
    return result;
  }

  public selectNodes(treeNodes: Set<TreeNode>) {
    if (!this.useSelection) {
      return;
    }
    const ids = [];
    treeNodes.forEach(treeNode => {
      ids.push(treeNode.id);
    });
    this.setSelect(ids);
    this.onNodesSelected(treeNodes);
    if (this._appShared.pointerMode.getValue()) {
      this._appShared.highlightNodes.next(ids);
    }
  }

  public onNodesSelected(treeNodes: Set<TreeNode>) {
    if (!this.useSelection) {
      return;
    }
    const selectedIds = [];
    let chainIdsSet = Set<string>();
    let chainIdCheckSum = '';
    let subTreeSet = Set<string>();
    let subTreeCheckSum = '';
    let nodes = Set<Node>();
    treeNodes = treeNodes.map(treeNode => this._nodes.get(treeNode.id)).filter(treeNode => {
      treeNode = !isNullOrUndefined(treeNode) && this._nodes.has(treeNode.id) ? this._nodes.get(treeNode.id) : treeNode;
      const valid = !isNullOrUndefined(treeNode) && !treeNode.phantom;
      if (valid) {
        selectedIds.push(treeNode.id);
        nodes = nodes.add(treeNode.node);
        chainIdsSet = chainIdsSet.merge(this.getChainIds(treeNode));
        chainIdCheckSum = chainIdsSet.toString();
        subTreeSet = subTreeSet.merge(this.getChainIds(treeNode, undefined, 'down'));
        subTreeCheckSum = subTreeSet.toString();
      }
      return valid;
    }).toSet();
    /* Shared selectNode */
    this._appShared.selectNodes = selectedIds;
    /* Subtree */
    let st = OrderedMap<string, TreeNode>();
    treeNodes.forEach(treeNode => {
      st = st.merge(this.getChains(treeNode, undefined, undefined, 'down'));
    });
    this.filterValues = this.filterValues.set('subtree', st.map(treeNode => treeNode.id).toArray());
    /* Direct chain */
    if (this._appShared.directChain.getValue()) {
      /* Direct chain */
      if (chainIdCheckSum !== this.chainIdCheckSum) {
        this.chainIdCheckSum = chainIdCheckSum;
        this.onDirectChainSelected(chainIdsSet.toArray());
      }
    } else if (this._appShared.subTree.getValue()) {
      /* Sub Tree */
      if (subTreeCheckSum !== this.subTreeCheckSum) {
        this.subTreeCheckSum = subTreeCheckSum;
        this.onSubTreeSelected(subTreeSet.toArray());
      }
    } else {
      /* Datasheet */
      if (this.isWorkflow) {
        nodes = <Set<Node>> nodes.filter(node => node.status === 110);
        this._appShared.dataPopUpValues.next(Set());
        this._appShared.dataPopUpType.next('nodes');
        this._appShared.dataPopUpValues.next(nodes);
      } else {
        this._appShared.datasheetType.next('node');
        this._appShared.datasheetValues.next(nodes);
      }
    }
    /* Shared */
    this._appShared.nodesSelected.next(treeNodes);
  }

  public setSelect(nodes: string[]) {
    if (!this.useSelection) {
      return;
    }
    const location = window.location.href;
    let href = location.replace(/\/select\/(\S*)/, '');
    if (nodes.length > 0) {
      href = href + '/select/' + nodes.sort((a, b) => NaturalSort.SORT(a, b)).join(',');
    }
    if (location !== href) {
      window.history.replaceState({}, document.title, href);
    }
  }

  public getChain(treeNodes: Set<TreeNode>): Map<string, TreeNode> {
    let chain = Map<string, TreeNode>();
    treeNodes.forEach(treeNode => chain = chain.merge(this.getChains(treeNode)));
    chain = chain.map(treeNode => <TreeNode> treeNode.set('topMost', this.getChainTopMost(treeNode))).toMap();
    return chain;
  }

  public getReverseChain(treeNodes: Set<TreeNode>): Map<string, TreeNode> {
    let chain = Map<string, TreeNode>();
    treeNodes.forEach(treeNode => {
      const leaf = this.getChainLeafs(treeNode).sort(this.sortByLevelSubLevelAndPosition).first();
      if (!isNullOrUndefined(leaf)) {
        chain = this.reverseChainIterate(leaf, chain);
      }
    });
    return chain;
  }

  public reverseChainIterate(treeNode: TreeNode, chain: Map<string, TreeNode>) {
    chain = chain.set(treeNode.id, treeNode);
    if (treeNode.parents.size > 0) {
      chain = this.reverseChainIterate(treeNode.parents.first(), chain);
    }
    return chain;
  }

  public getChainParentIds(treeNode: TreeNode, breakAtLevels = false, result = []): string[] {
    treeNode.parents.forEach(parent => {
      if (!breakAtLevels || treeNode.level === parent.level) {
        result.push(parent.id);
        result = this.getChainParentIds(parent, breakAtLevels, result);
      }
    });
    return result;
  }

  public getChainChildren(treeNode: TreeNode, breakAtLevels = false, result = []): TreeNode[] {
    treeNode.children.forEach(child => {
      if (!breakAtLevels || treeNode.level === child.level) {
        result.push(child);
        result = this.getChainChildren(child, breakAtLevels, result);
      }
    });
    return result;
  }

  public getChainTopMost(treeNode: TreeNode): TreeNode {
    if (treeNode.parents.size === 0) {
      return treeNode;
    }
    return treeNode.parents.map(parentTreeNode => this.getChainTopMost(parentTreeNode)).sort(this.sortByLevelAndSubLevel).first();
  }

  public getChainLeafs(treeNode: TreeNode, result = OrderedSet<TreeNode>()): OrderedSet<TreeNode> {
    if (treeNode.children.size === 0) {
      result = result.add(treeNode);
    }
    treeNode.children.forEach(childTreeNode => result = this.getChainLeafs(childTreeNode, result));
    return result;
  }

  public getChains(treeNode: TreeNode, previousTreeNode?: TreeNode, filteredChain = Map<string, TreeNode>(), direction?: string): Map<string, TreeNode> {
    if (!isNullOrUndefined(previousTreeNode)) {
      const key = direction === 'down' ? 'parents' : 'children';
      const keySet = <OrderedMap<string, TreeNode>> treeNode.get(key).filter(_ => _.id !== previousTreeNode.id);
      treeNode = <TreeNode> treeNode.set(key, keySet.set(previousTreeNode.id, previousTreeNode));
    }
    if (!this.isWorkflow || this.filterOnWorkflow(treeNode)) {
      filteredChain = filteredChain.set(treeNode.id, treeNode);
    }
    /* Children */
    if (isUndefined(direction) || direction === 'down') {
      treeNode.children.forEach(treeNodeChild => {
        filteredChain = filteredChain.merge(this.getChains(treeNodeChild, treeNode, filteredChain, 'down'));
      });
    }
    /* Parents */
    if (isUndefined(direction) || direction === 'up') {
      treeNode.parents.forEach(treeNodeParent => {
        filteredChain = filteredChain.merge(this.getChains(treeNodeParent, treeNode, filteredChain, 'up'));
      });
    }
    /* Return */
    return filteredChain;
  }

  public getAvailableParentNodes(treeNode: TreeNode): OrderedMap<string, TreeNode> {
    const notAllowed = this.getChain(Set([treeNode])).map(d => d.id);
    return <OrderedMap<string, TreeNode>> this._nodes.filter(d => !notAllowed.has(d.id) && Math.abs(treeNode.level - d.level) <= 1 );
  }

  public getRelatedTreeNodes(treeNode: TreeNode, type: string, modelId: string): string[] {
    let result = [];
    (<OrderedMap<string, TreeNode>> treeNode[type]).forEach(node => {
      result = result.concat(node.node.relationships.nodestructures.map(struct => {
        const split = struct.split(':');
        return '' + split[0] === '' + modelId ? split[1] : undefined;
      }).filter(struct => !isNullOrUndefined(struct)));
    });
    return result;
  }

  public getTreeRelationships(treeNodes: TreeNode[]): TreeRelationship[] {
    let ids = Set<string>();
    const count = treeNodes.length;
    for (let i = 0; i < count; i++) {
      ids = ids.add(treeNodes[i].id);
    }
    return this._relationships.filter(relationship => ids.has(relationship.parent) && ids.has(relationship.child)).toArray();
  }

  public setColorLabelProvider(provider: string, key: string, params?: any) {
    this.colorLabelProviderParams = params;
    this.colorLabelProviderColor = key;
    this.updateColorLabelProviders(provider, true, params);
  }

  protected filterOnWorkflow(treeNode: TreeNode) {
    return treeNode.node.status > 0;
  }

  protected getChainIds(treeNode: TreeNode, filteredChain = Set<string>(), direction?: string): Set<string> {
    if (!this.isWorkflow || this.filterOnWorkflow(treeNode)) {
      filteredChain = filteredChain.add(treeNode.id);
    }
    /* Children */
    if (isUndefined(direction) || direction === 'down') {
      treeNode.children.forEach(treeNodeChild => {
        filteredChain = filteredChain.merge(this.getChainIds(treeNodeChild, filteredChain, 'down'));
      });
    }
    /* Parents */
    if (isUndefined(direction) || direction === 'up') {
      treeNode.parents.forEach(treeNodeParent => {
        filteredChain = filteredChain.merge(this.getChainIds(treeNodeParent, filteredChain, 'up'));
      });
    }
    /* Return */
    return filteredChain;
  }

  protected onNodesLoaded(nodes: List<Node>) {
    /* Nodes */
    let treeNodes = OrderedMap<string, TreeNode>();
    const nodesCheckSum = this.hashing.getHash(nodes);
    if (this._useCache && this.cache.has('nodes', nodesCheckSum)) {
      treeNodes = this.cache.get('nodes', nodesCheckSum);
    } else if (!this._useCache || !this.cache.has('tree', 'nodes') || this.cache.get('tree', 'nodes') !== nodesCheckSum) {
      /* Clear everything */
      this.cache.flush('nodes');
      this._nodes = this._nodes.clear();
      this.maxPositions = { '0': 0, '1': 0, '2': 0, '3': 0 };
      /* Set the nodes */
      nodes.forEach((node: Node) => {
        let treeNode = new TreeNode(node);
        if (this.maxPositions['' + treeNode.level] < treeNode.node.positionX) {
          this.maxPositions['' + treeNode.level] = treeNode.node.positionX;
        }
        if (this._addActivities) {
          treeNode = this.addActivitiesToTreeNode(treeNode);
        }
        treeNodes = treeNodes.set(node.id, this.updateUUID(treeNode));
      });
      /* Store checksum in cache */
      this.cache.set('tree', 'nodes', nodesCheckSum);
      /* Store the nodes in cache */
      this.cache.set('nodes', nodesCheckSum, treeNodes);
    }
    /* Calculate stuff for nodes */
    this.setNodes(treeNodes, true, true);
  }

  protected onDataLoaded(apiData: ApiData) {
    const activitiesCheckSum = this.hashing.getHash(apiData.activities);
    const nodesCheckSum = this.hashing.getHash(apiData.nodes);
    const relationshipsCheckSum = this.hashing.getHash(apiData.relationships);

    let updatedNodes = false;
    let updatedRelationships = false;
    let updatedActivities = false;

    const checkSum = activitiesCheckSum + ':' + nodesCheckSum + ':' + relationshipsCheckSum;
    if (this.checkSum === checkSum) {
      return;
    }
    this.checkSum = checkSum;

    /* Activities */
    let activitiesMap = OrderedMap<string, TreeActivity>();

    if (this._useCache && this.cache.has('activities', activitiesCheckSum)) {
      activitiesMap = this.cache.get('activities', activitiesCheckSum);
    } else if (!this._useCache || !this.cache.has('tree', 'activities') || this.cache.get('tree', 'activities') !== activitiesCheckSum) {
      /* Clear everything */
      this._activities = this._activities.clear();
      /* Set the activities */
      this._activities = apiData.activities;
      apiData.activities.forEach(activity => {
        const treeActivity = new TreeActivity(activity);
        activitiesMap = activitiesMap.set(treeActivity.id, treeActivity);
      });
      /* Set the flag that something has been changed */
      updatedActivities = true;
      /* Store checksum in cache */
      this.cache.set('tree', 'activities', activitiesCheckSum);
      /* Store the activities in cache */
      this.cache.set('activities', activitiesCheckSum, activitiesMap);
      /* Flush nodes cache */
      this.cache.flush('nodes');
      this.cache.flush('tree', 'nodes');
    }
    this._activitiesMap = activitiesMap;

    /* Nodes */
    let treeNodes = OrderedMap<string, TreeNode>();
    if (this._useCache && this.cache.has('nodes', nodesCheckSum)) {
      treeNodes = this.cache.get('nodes', nodesCheckSum);
    } else if (!this._useCache || !this.cache.has('tree', 'nodes') || this.cache.get('tree', 'nodes') !== nodesCheckSum) {
      /* Clear everything */
      this.cache.flush('nodes');
      this._nodes = this._nodes.clear();
      this.maxPositions = { '0': 0, '1': 0, '2': 0, '3': 0 };
      /* Set the nodes */
      apiData.nodes.forEach((node: Node) => {
        let treeNode = new TreeNode(node);
        if (this.maxPositions['' + treeNode.level] < treeNode.node.positionX) {
          this.maxPositions['' + treeNode.level] = treeNode.node.positionX;
        }
        if (this._addActivities) {
          treeNode = this.addActivitiesToTreeNode(treeNode);
        }
        treeNodes = treeNodes.set(node.id, this.updateUUID(treeNode));
      });
      /* Set the flag that something has been changed */
      updatedNodes = true;
      /* Store checksum in cache */
      this.cache.set('tree', 'nodes', nodesCheckSum);
      /* Store the nodes in cache */
      this.cache.set('nodes', nodesCheckSum, treeNodes);
    }

    /* Relationships */
    let treeRelationships = Map<string, TreeRelationship>();
    if (this._useCache && this.cache.has('relationships', relationshipsCheckSum)) {
      treeRelationships = this.cache.get('relationships', relationshipsCheckSum);
    } else if (!this._useCache || !this.cache.has('tree', 'relationships') || this.cache.get('tree', 'relationships') !== relationshipsCheckSum) {
      /* Clear everything */
      this.cache.flush('relationships');
      this._relationships = this._relationships.clear();
      /* Set the relationships */
      apiData.relationships.forEach(relationship => {
        if (!this._deletedRelationships.has(relationship.id)) {
          const treeRelationship = <TreeRelationship> this.updateUUID(new TreeRelationship(relationship));
          treeRelationships = treeRelationships.set(relationship.id, treeRelationship);
          this.relationshipNodeMap = this.relationshipNodeMap.set(treeRelationship.parent + ':' + treeRelationship.child, treeRelationship);
        }
      });
      this._relationships = treeRelationships;
      /* Set the flag that something has been changed */
      updatedRelationships = true;
      /* Store checksum in cache */
      this.cache.set('tree', 'relationships', relationshipsCheckSum);
      /* Store the relationships in cache */
      this.cache.set('relationships', relationshipsCheckSum, treeRelationships);
    }

    if (updatedNodes) {
      this.setNodes(treeNodes, true, true);
    }
    if (updatedActivities) {
      this.activities.next(activitiesMap);
    }
    if (updatedRelationships) {
      this.unfilteredRelationships.next(treeRelationships);
      this.relationships.next(this.filterRelationships(this.filterNodes(false), true));
    }
  }

  protected onHumanResourcesLoaded(humanResources: OrderedSet<HumanResource>) {
    this._humanResources = humanResources.toList();
    if (this.colorLabelProviderColor === 'responsible') {
      this.cache.flushAll('node.color');
      this.updateColorLabelProviders();
    }
  }

  protected onGroupsLoaded(groups: OrderedSet<Group>) {
    this._groups = groups.toList();
  }

  protected onNodesDiff(diff: RequestDiffRecord) {
    switch (diff.action) {
      case NodeStructureAction.CREATE_FAIL:
        this.removePhantomData();
        break;
      case NodeStructureAction.CREATE_SUCCESS:
        const relationshipsToCreate = [];
        /* Phantom */
        let modelId = 0;
        diff.response.forEach(response => {

          modelId = response.relationships.model.data.id;

          if (!isNullOrUndefined(this.phantomRelationship)) {
            if (this.phantomRelationship.child === response.creationId) {
              this.phantomRelationship = <TreeRelationship> this.phantomRelationship.set('child', response.id);
            }
            if (this.phantomRelationship.parent === response.creationId) {
              this.phantomRelationship = <TreeRelationship> this.phantomRelationship.set('parent', response.id);
            }
            this.updateRelationship(<TreeRelationship> this.phantomRelationship.set('toCreate', true), true);
          }
          this.removePhantomNode();

          /* Grouping */
          if (this.groupingAddMap.size > 0 && this.groupingAddMap.has(response.creationId)) {
            /* Update store */
            this.onNodeDataAddRelationship.next([
              { id: response.relationships.nodedata.data.id, key: 'models', value: '' + response.relationships.model.data.id },
              { id: response.relationships.nodedata.data.id, key: 'nodestructures', value: '' + response.relationships.model.data.id + ':' + response.id }
            ]);
            this.groupingGroupedMap = this.groupingGroupedMap.set('' + this.groupingAddMap.get(response.creationId), '' + response.id);
          }

          /* Sidestep */
          if (this.sidestepMap.size > 0 && this.sidestepMap.has(response.creationId)) {
            this.onSidestepCreated.next(Map<string, string>().set(response.relationships.model.data.id, response.id));
          }

          /* Selected Filter */
          this.filter.forEach(filterBy => {
            const value = this.filterValues.get(filterBy);
            switch (filterBy) {
              case 'humanResources':
                break;
              case 'subsets':
                this.onGroupingTag.next(Map<string, Set<string>>().set(value, Set<string>([response.id])));
                break;
            }
          });

          /* Grouping */
          if (this.groupingAddMaps.has(response.creationId)) {
            /* Get the node grouping */
            const nodeGrouping = this.groupingAddMaps.get(response.creationId);
            /* Parents */
            nodeGrouping.relationships.parents.forEach(parentId => {
              relationshipsToCreate.push(new RelationshipCreate({ id: UUID.UUID(), parent: parentId, child: response.id, model: modelId }));
            });
            /* Children */
            nodeGrouping.relationships.children.forEach(childId => {
              relationshipsToCreate.push(new RelationshipCreate({ id: UUID.UUID(), parent: response.id, child: childId, model: modelId }));
            });
          }

        });

        this.groupingRelationships.filter(treeRelationship => {
          return this.groupingGroupedMap.has('' + treeRelationship.parent) || this.groupingGroupedMap.has('' + treeRelationship.child);
        }).forEach(treeRelationship => {

          /* Create */
          relationshipsToCreate.push(new RelationshipCreate({
            id: UUID.UUID(),
            weight: treeRelationship.relationship.weight,
            parent: this.groupingGroupedMap.has('' + treeRelationship.parent) ? this.groupingGroupedMap.get('' + treeRelationship.parent) : treeRelationship.parent,
            child: this.groupingGroupedMap.has('' + treeRelationship.child) ? this.groupingGroupedMap.get('' + treeRelationship.child) : treeRelationship.child,
            model: modelId
          }));
        });

        if (relationshipsToCreate.length > 0) {
          this.onGroupingRelationshipsAdd.next(relationshipsToCreate);
        }
        break;
      case NodeStructureAction.REMOVE_SUCCESS:
        diff.response.forEach(response => {
          /* Grouping */
          if (this.groupingRemoveMap.size > 0 && this.groupingRemoveMap.has(response.id)) {
            this.onNodeDataRemoveRelationship.next([
              { id: response.relationships.nodedata.data.id, key: 'models', value: '' + response.relationships.model.data.id },
              { id: response.relationships.nodedata.data.id, key: 'nodestructures', value: '' + response.relationships.model.data.id + ':' + response.id }
            ]);
          }
        });
        break;
    }
  }

  protected onNodeDataDiff(diff: RequestDiffRecord) {
    switch (diff.action) {
      case NodeDataAction.UPDATE_SUCCESS:
        if (!this.isWorkflow) { return; }
        if (isNullOrUndefined(diff.payload.data)) { return; }
        const update = diff.payload.data[0].data;
        if (!update.has('uncertainty')) {
          return; // no need to trigger workflow
        }

        const parent = this._nodes.filter(nd => diff.payload.data[0].id === nd.node.relationships.nodedata).first();
        if (!parent) { return; }

        const nodesToUpdate = this._relationships
          .filter(rel => rel.parent === parent.id && rel.relationship.condition + '' === update.get('uncertainty') + '')
          .map(rel => this._nodes.get(rel.child));

        if (nodesToUpdate.isEmpty()) { return; }  // nothing to do

        this._relationships.filter(rel => rel.parent === parent.id).isEmpty();

        const updates = nodesToUpdate.map(nd => <IPayload>({
          id: nd.node.relationships.nodedata,
          data: Map<string, any>({
            status: this._relationships.filter(rel => rel.parent === nd.node.id).isEmpty() ? 200 : 110
          })
        })).toArray();

        /* Update status of newly updated node */
        this._nodeDataService.update(updates);

        window.setTimeout(() => this._nodeDataService.update([<IPayload>{
          id: parent.node.relationships.nodedata,
          data: Map<string, any>({
            status: 200
          })
        }]), 1000);

        break;
    }
  }

  protected onRelationshipsDiff(diff: RequestDiffRecord) {
    if (diff.action === RelationshipAction.CREATE_FAIL) {
      this.removePhantomData();
    }
    if (diff.action === RelationshipAction.CREATE_SUCCESS) {
      this.removePhantomData();
    }
  }

  protected onActivitiesDiff(diff: RequestDiffRecord) {}

  protected onModelsLoaded(models: List<Model>) {
    this._modelsMap = this._modelsMap.clear();
    this._subModelsMap = this._subModelsMap.clear();
    let _models = List<Model>();
    let _subModels = List<Model>();
    models.forEach(model => {
      if (isUndefined(model.relationships.model)) {
        _models = _models.push(model);
        this._modelsMap = this._modelsMap.set(model.id, model);
      } else {
        _subModels = _subModels.push(model);
        this._subModelsMap = this._subModelsMap.set(model.id, model);
      }
    });
    this._models = _models;
    this._subModels = _subModels;
    if (this.colorLabelProviderColor === 'models' || this.colorLabelProviderColor === 'submodels') {
      this.cache.flushAll('node.color');
      this.updateColorLabelProviders();
    }
  }

  protected onSubSetsLoaded(subSets: List<Subset>) {
    this._subSets = subSets;
    this._subSets.forEach(subSet => this._subSetsMap = this._subSetsMap.set(subSet.id, subSet));
    if (this.colorLabelProviderColor === 'subsets') {
      this.cache.flushAll('node.color');
      this.updateColorLabelProviders();
    }
  }

  protected onRadialEvent(item: RadialMenuItem) {
    switch (item.event) {
      case 'default.display.color':
        this._appShared.colorLabelProviderColor = this.colorLabelProviderColor = item.subevent;
        this.updateColorLabelProviders('node.color');
        break;
      case 'default.display.label.top':
        this._appShared.colorLabelProviderLabelTop = this.colorLabelProviderLabelTop = item.subevent;
        this.updateColorLabelProviders('node.label.top');
        break;
      case 'default.display.label.bottom':
        this._appShared.colorLabelProviderLabelBottom = this.colorLabelProviderLabelBottom = item.subevent;
        this.updateColorLabelProviders('node.label.bottom');
        break;
      case 'nodes.selected.grouping.submodels':
      case 'nodes.selected.grouping':
        this.groupModel(item);
        break;
      case 'nodes.selected.grouping.subsets':
        this.tag(item);
        break;
      case 'nodes.selected.sidestep':
        this.sidestep(item);
        break;
      case 'node.selected.select.subtree':
      case 'nodes.selected.select.subtree':
        const subtree = this.filterValues.has('subtree') ? this.filterValues.get('subtree') : [];
        this.selectNodes(this._nodes.filter(treeNode => subtree.indexOf(treeNode.id) !== -1).toSet());
        break;
      case 'default.settings.directchain':
        this._appShared.directChain.next(item.selected);
        if (!isNullOrUndefined(this._appShared.selectNodes) && this._appShared.selectNodes.length > 0) {
          if (!item.selected) {
            this.removeFilter('directchain').filterNodes();
          }
        }
        break;
      case 'default.settings.subtree':
        this._appShared.subTree.next(item.selected);
        if (!isNullOrUndefined(this._appShared.selectNodes) && this._appShared.selectNodes.length > 0) {
          if (!item.selected) {
            this.removeFilter('subtree').filterNodes();
          }
        }
        break;
      case 'default.action.filter':
        if (item.selected) {
          this.addFilter(item.subevent).filterNodes();
        } else {
          this.removeFilter(item.subevent).filterNodes();
        }
        break;
    }
  }

  protected onLanguageChange(language: any) {
    if (language.hasOwnProperty('lang')) {
      this.cache.flushAll('node.label.top');
      this.cache.flushAll('node.label.bottom');
      this.updateNodes();
      this.updateColorLabelProviders();
    }
  }

  protected setNodes(nodes: OrderedMap<string, TreeNode> = this._nodes, event = true, updateColorLabelProvider = false) {
    this._nodes = nodes;
    this.updateColorLabelProviders(undefined, false);
    this._nodes = this.updateHierarchy(this._nodes);
    this.maxSubLevels = { '0': 0, '1': 0, '2': 0, '3': 0 };
    this.levelsCount = { '0': 0, '1': 0, '2': 0, '3': 0 };
    if (this._subLevel && (this._topMostParent !== false || this._challenges !== false)) {
      this._nodes = this._nodes.map(treeNode => {

        this.levelsCount['' + treeNode.level]++;

        if (this.maxSubLevels['' + treeNode.level] < treeNode.subLevel) {
          this.maxSubLevels['' + treeNode.level] = treeNode.subLevel;
        }

        /* Top most */
        if (this._topMostParent !== false) {
          const topMost = this.getTopMostParent(treeNode, this._topMostParent === 'sidestep');
          if (!isNullOrUndefined(topMost)) {
            treeNode = <TreeNode> treeNode.set('topMost', this._nodes.get(topMost.id));
          }
        }

        /* Challenges */
        if (this._challenges !== false) {
          treeNode = this.setChallenges(treeNode);
        }

        /* Chain & Parent Ids */
        treeNode = <TreeNode> treeNode
          .set('chainIds', this.getChainIds(treeNode).toArray())
          .set('parentIds', this.getChainParentIds(treeNode))
          .set('levelParentIds', this.getChainParentIds(treeNode, true));

        return treeNode;
      }).toOrderedMap();
    }

    this._nodes = this.sort(<OrderedMap<string, TreeNode>> this.updateNodesInHierarchy(this._nodes).filter(node => node.parents.size === 0));
    // this._nodes = this.positioning.update(this._nodes, this._relationships, []);

    /* Selected */
    const selected = this._appShared.nodesSelected.getValue();
    if (selected.size > 0) {
      this.onNodesSelected(selected);
    }

    /* Event */
    if (event) {
      this.unfilteredNodes.next(this._nodes);
    }
    /* Filter */
    if (this.filters.length > 0) {
      this.filterNodes(event);
    } else if (event) {
      this.nodes.next(this._nodes);
    }
  }

  private onAutoPositionChange(autoPositioning) {
    this.autoPositioning = autoPositioning;
    window.setTimeout(() => {
      this.filterNodes(true);
    }, 1);
  }

  protected updateNodesInHierarchy(nodes: OrderedMap<string, TreeNode>): OrderedMap<string, TreeNode> {
    return <OrderedMap<string, TreeNode>> nodes.map(node => {
      node = this._nodes.has(node.id) ? this._nodes.get(node.id) : node;
      if (node.children.size > 0) {
        node = <TreeNode> node.set('children', this.updateNodesInHierarchy(node.children));
      }
      return node;
    });
  }

  protected updateHierarchy(nodes: OrderedMap<string, TreeNode>) {
    return this._subLevel && this._relationships.size > 0 ? this.setHierarchy(nodes) : nodes;
  }

  protected setRelationships(relationships: Map<string, TreeRelationship> = this._relationships, event = true) {
    this._relationships = relationships;
    if (this._subLevel && this._nodes.size > 0) {
      this.setNodes(this._nodes, event);
    }
    if (event) {
      this.filterRelationships(this.filterNodes(false), true);
    }
  }

  protected getPhantomRelationship(parentNode: TreeNode, childNode: TreeNode, event = true): TreeRelationship {
    return this.addRelationship(<TreeRelationship> new TreeRelationship(new Relationship({ id: UUID.UUID(), relationships: new RelationshipRelationships({
        parent: parentNode.id,
        child: childNode.id
      })})).set('phantom', true), event);
  }

  protected updateUUID(element: any, force = false) {
    if (element instanceof TreeNode) {
      return <TreeNode> element.set('uuid', 'node-' + (isNullOrUndefined(element.node.updatedAt) || force ? new Date().valueOf() : element.node.updatedAt) + '-' + element.id);
    }
    if (element instanceof TreeRelationship) {
      return <TreeRelationship> element.set('uuid', 'relationship-' + (isNullOrUndefined(element.relationship.updatedAt) || force ? new Date().valueOf() : element.relationship.updatedAt) + '-' + element.id);
    }
    return element;
  }

  protected addActivitiesToTreeNode(treeNode: TreeNode): TreeNode {
    return <TreeNode> treeNode.set('activities', this._activitiesMap.filter((treeActivity: TreeActivity) => {
      return treeNode.node.relationships.nodedata === treeActivity.activity.relationships.nodedata;
    }).toOrderedSet());
  }

  protected setHierarchy(nodes = this._nodes, relationships = this._relationships) {
    this._subLevelMap = this._subLevelMap.clear();
    let parentChildMap = Map<string, Map<string, string>>();
    let childParentMap = Map<string, Map<string, string>>();
    // const parentIds = [];
    relationships.filter(treeRelationship => !treeRelationship.phantom && treeRelationship.relationship.type === RELATIONSHIP_TYPE_DEFAULT).forEach(treeRelationship => {
      const parentId = '' + treeRelationship.parent;
      const childId = '' + treeRelationship.child;
      const parent = parentChildMap.has(parentId) ? parentChildMap.get(parentId) : Map<string, string>();
      parentChildMap = parentChildMap.set(parentId, parent.set(childId, childId));
      const child = childParentMap.has(childId) ? childParentMap.get(childId) : Map<string, string>();
      childParentMap = childParentMap.set(childId, child.set(parentId, parentId));
      // parentIds.push(parentId);
    });
    // const sanitized = this.preventCircle(parentIds, parentChildMap, childParentMap);
    //
    // parentChildMap = sanitized.parents;
    // childParentMap = sanitized.children;

    nodes.filter(node => !childParentMap.has(node.id)).forEach(node => {
      nodes = this.setChildren(node, nodes, parentChildMap).nodes;
    });
    return nodes;
  }

  protected preventCircle(parents: string[], parentChildMap: Map<string, Map<string, string>>, childParentMap: Map<string, Map<string, string>>): { parents: Map<string, Map<string, string>>, children: Map<string, Map<string, string>> } {
    let illegals: { parent: string, child: string }[] = [];
    const parentsCount = parents.length;
    parentsLoop:
      for (let i = 0; i < parentsCount; i++) {
        const parent = parents[i];
        if (parentChildMap.has(parent)) {
          const children = parentChildMap.get(parent).toArray();
          const childrenCount = children.length;
          for (let j = 0; j < childrenCount; j++) {
            const child = children[j];
            illegals = this.getCircularRelationships(parent, child, parentChildMap);
            if (illegals.length > 0) {
              break parentsLoop;
            }
          }
        }
      }

    const illegalCount = illegals.length;
    for (let k = 0; k < illegalCount; k++) {
      const illegal = illegals[k];
      /* Remove from parent child map */
      parentChildMap = parentChildMap.set(illegal.parent, <Map<string, string>> parentChildMap.get(illegal.parent).filter(mapChild => mapChild !== illegal.child));
      if (parentChildMap.get(illegal.parent).size === 0) {
        parentChildMap = parentChildMap.remove(illegal.parent);
      }
      /* Remove from child parent map */
      childParentMap = childParentMap.set(illegal.child, <Map<string, string>> childParentMap.get(illegal.child).filter(mapParent => mapParent !== illegal.parent));
      if (childParentMap.get(illegal.child).size === 0) {
        childParentMap = childParentMap.remove(illegal.child);
      }
    }

    return illegalCount > 0 ? this.preventCircle(parents, parentChildMap, childParentMap) : { parents: parentChildMap, children: childParentMap };
  }

  protected getCircularRelationships(parent, child, parentChildMap: Map<string, Map<string, string>>, processed = [], illegalRelationships = []): { parent: string, child: string }[] {
    if (processed.indexOf(child) !== -1) {
      illegalRelationships.push({ parent: parent, child: child });
      return illegalRelationships;
    }
    processed.push(parent);
    if (parentChildMap.has(child)) {
      parentChildMap.get(child).forEach(childchild => {
        illegalRelationships = this.getCircularRelationships(child, childchild, parentChildMap, processed, illegalRelationships);
      });
    }
    return illegalRelationships;
  }

  protected setChildren(treeNode: TreeNode, nodes: OrderedMap<string, TreeNode>, parentChildMap: Map<string, OrderedMap<string, string>>, parentNode?: TreeNode, ids = Set<string>()): { nodes: OrderedMap<string, TreeNode>, treeNode: TreeNode } {
    if (!isNullOrUndefined(parentNode)) {
      treeNode = <TreeNode> treeNode.set('parents', treeNode.parents.set(parentNode.id, parentNode));
    }
    let subLevel = 0;
    if (!isNullOrUndefined(parentNode)) {
      let _subLevel = this.breakAtLevels ? (parentNode.level !== treeNode.level ? 0 : parentNode.subLevel + 1) : parentNode.subLevel + 1;
      const storedSubLevel = this._subLevelMap.get(treeNode.id);
      if (!isNullOrUndefined(storedSubLevel) && storedSubLevel > _subLevel) {
        _subLevel = storedSubLevel;
      }
      subLevel = _subLevel;
    }
    this._subLevelMap = this._subLevelMap.set(treeNode.id, subLevel);
    if (!treeNode.phantom) {
      treeNode = <TreeNode> treeNode.set('subLevel', subLevel);
    }
    if (parentChildMap.has(treeNode.id)) {
      treeNode = <TreeNode> treeNode.set('children', parentChildMap.get(treeNode.id).filter(id => nodes.has(id) && !ids.has(id)).map(id => {
        ids.add(treeNode.id);
        const data = this.setChildren(nodes.get(id), nodes, parentChildMap, treeNode, ids);
        nodes = data.nodes;
        return data.treeNode;
      }).sort(this.sortByPosition));
    }

    nodes = nodes.set(treeNode.id, treeNode);
    return { nodes: nodes, treeNode: treeNode };
  }

  protected updateColorLabelProviders(provider?: string, event = true, params = this.colorLabelProviderParams) {
    if (this._nodes.size > 0) {
      this._nodes = this._nodes.map(treeNode => {
        let update = false;

        /* Color */
        if (isUndefined(provider) || provider === 'node.color') {
          const color = this.getNodeColorProvider(treeNode, params);
          if (treeNode.color !== color) {
            treeNode = <TreeNode> treeNode.set('color', color);
            this.cache.set('node.color', treeNode.uuid, color, treeNode.id);
            update = true;
          }
        }

        /* Label top */
        if (isUndefined(provider) || provider === 'node.label.top') {
          const labelTop = this.getNodeLabelProvider(treeNode, 'top');
          if (treeNode.labelTop !== labelTop) {
            treeNode = <TreeNode> treeNode.set('labelTop', labelTop);
            this.cache.set('node.label.top', treeNode.uuid, labelTop, treeNode.id);
            update = true;
          }
        }

        /* Label bottom */
        if (isUndefined(provider) || provider === 'node.label.bottom') {
          const labelBottom = this.getNodeLabelProvider(treeNode, 'bottom');
          if (treeNode.labelBottom !== labelBottom) {
            treeNode = <TreeNode> treeNode.set('labelBottom', labelBottom);
            this.cache.set('node.label.bottom', treeNode.uuid, labelBottom, treeNode.id);
            update = true;
          }
        }

        /* Check for update */
        if (update) {
          treeNode = this.updateNode(treeNode);
        }

        /* Return TreeNode */
        return treeNode;
      }).toOrderedMap();
      this._nodes = this.setTreeNodeDataRecursive(this._nodes);
      this.legend.next(this.getLegendByColorLabelProvider(params));

      /* Filter */
      if (this.filters.length > 0) {
        this.filterNodes(event);
      } else if (event) {
        this.nodes.next(this._nodes);
      }
    }
  }

  protected setTreeNodeDataRecursive(treeNodes: OrderedMap<string, TreeNode>) {
    return (<any> treeNodes).map(treeNode => {
      const color = this._nodes.has(treeNode.id) ? this._nodes.get(treeNode.id).color : treeNode.color;
      return <TreeNode> treeNode
        .set('color', color)
        .set('children', this.setTreeNodeDataRecursive(treeNode.children));
    });
  }

  protected getNodeColorProvider(treeNode: TreeNode, params?: any) {
    if (this.cache.has('node.color', treeNode.uuid)) {
      return this.cache.get('node.color', treeNode.uuid);
    } else {
      return this.getColorByColorLabelProvider(treeNode, params);
    }
  }

  protected getNodeLabelProvider(treeNode: TreeNode, label: string) {
    if (this._appShared.isIE) {
      if (this.cache.has((label === 'top' ? 'node.label.top' : 'node.label.bottom'), treeNode.uuid)) {
        return this.cache.get((label === 'top' ? 'node.label.top' : 'node.label.bottom'), treeNode.uuid);
      } else {
        const l: string = this.getLabelByColorLabelProvider(treeNode, label === 'top' ? this.colorLabelProviderLabelTop : this.colorLabelProviderLabelBottom);
        if (this.cache.has('text', l)) {
          return this.cache.get('text', l);
        } else {
          const wrapped = this.wordWrap(l, (a, b, c) => label === 'top' ? this.wordWrapGetLabelTopY(a, b, c) : this.wordWrapGetLabelBottomY(a, b, c));
          this.cache.set('text', l, wrapped);
          return wrapped;
        }
      }
    } else {
      return this.getLabelByColorLabelProvider(treeNode, label === 'top' ? this.colorLabelProviderLabelTop : this.colorLabelProviderLabelBottom);
    }
  }

  protected getColorByColorLabelProvider(treeNode: TreeNode, params?: any): any {
    switch (this.colorLabelProviderColor) {
      case 'models':
        return this._colorLabelProvider.models(this._models).color(treeNode.node);
      case 'submodels':
        return this._colorLabelProvider.submodels(this._subModels).color(treeNode.node);
      case 'subsets':
        return this._colorLabelProvider.subsets(this._subSets).color(treeNode.node);
      case 'targetDate':
        return this._colorLabelProvider.targetDate().color(treeNode.node);
      case 'status':
        return this._colorLabelProvider.status().color(treeNode.node);
      case 'sidestep':
        return this._colorLabelProvider.sidestep().color(treeNode.node);
      case 'responsible':
        return this._colorLabelProvider.responsible(this._humanResources).color(treeNode.node);
      case 'difference':
        return this._colorLabelProvider.difference().color(treeNode.node);
      case 'todostatus':
        return this._colorLabelProvider.todostatus(this._activities).color(treeNode.node);
      case 'importance':
        return this._colorLabelProvider.importance().color(treeNode.node);
      case 'risk':
        return this._colorLabelProvider.risk().color(treeNode.node);
      case 'qstatus.q1':
        return this._colorLabelProvider.qstatus().color(treeNode.node, 'executive');
      case 'qstatus.q2':
        return this._colorLabelProvider.qstatus().color(treeNode.node, 'program');
      case 'qstatus.q3':
        return this._colorLabelProvider.qstatus().color(treeNode.node, 'functional');
      case 'qstatus.q4':
        return this._colorLabelProvider.qstatus().color(treeNode.node, 'resource');
      case 'relatedstatus':
        return this._colorLabelProvider.relatedstatus(this._nodeData).color(treeNode.node);
      case 'nodestype':
        return this._colorLabelProvider.nodestype(params, this._models, this._subModels, this._subSets, this._humanResources, this._activities, this._nodeData, this._nodes).color(treeNode.node);
      case 'nodetypes':
        return this._colorLabelProvider.nodetypes(params).color(treeNode.node);
      case 'levels':
        return this._colorLabelProvider.levels().color(treeNode.node);
      default:
        return this._colorLabelProvider.basic().color();
    }
  }

  protected getLabelByColorLabelProvider(treeNode: TreeNode, label: string): any {
    switch (label) {
      case 'status':
        return this._colorLabelProvider.status().label(treeNode.node, label);
      case 'budgetProfit':
        return this._colorLabelProvider.profit().label(treeNode.node.benefitBudget, treeNode.node.costBudget, treeNode.node.investBudget);
      case 'actualProfit':
        return this._colorLabelProvider.profit().label(treeNode.node.benefitActual, treeNode.node.costActual, treeNode.node.investActual);
      case 'remainingProfit':
        return this._colorLabelProvider.profit().label(treeNode.node.benefitRemaining, treeNode.node.costRemaining, treeNode.node.investRemaining);
      case 'deviationProfit':
        return this._colorLabelProvider.profit().label(
          this._colorLabelProvider.deviation().label(treeNode.node.benefitBudget, treeNode.node.benefitActual, treeNode.node.benefitRemaining),
          this._colorLabelProvider.deviation().label(treeNode.node.costBudget, treeNode.node.costActual, treeNode.node.costRemaining),
          this._colorLabelProvider.deviation().label(treeNode.node.investBudget, treeNode.node.investActual, treeNode.node.investRemaining)
        ).toString();
      case 'benefitDeviation':
        return this._colorLabelProvider.deviation().label(treeNode.node.benefitBudget, treeNode.node.benefitActual, treeNode.node.benefitRemaining);
      case 'costDeviation':
        return this._colorLabelProvider.deviation().label(treeNode.node.costBudget, treeNode.node.costActual, treeNode.node.costRemaining);
      case 'investDeviation':
        return this._colorLabelProvider.deviation().label(treeNode.node.investBudget, treeNode.node.investActual, treeNode.node.investRemaining);
      default:
        return this._colorLabelProvider.basic().label(treeNode.node, label);
    }
  }

  protected getLegendByColorLabelProvider(params?: any) {
    switch (this.colorLabelProviderColor) {
      case 'models':
        return this._colorLabelProvider.models(this._models).legend(this._nodes);
      case 'submodels':
        return this._colorLabelProvider.submodels(this._subModels).legend(this._nodes);
      case 'subsets':
        return this._colorLabelProvider.subsets(this._subSets).legend(this._nodes);
      case 'targetDate':
        return this._colorLabelProvider.targetDate().legend();
      case 'status':
        return this._colorLabelProvider.status().legend();
      case 'sidestep':
        return this._colorLabelProvider.sidestep().legend();
      case 'responsible':
        return this._colorLabelProvider.responsible(this._humanResources).legend(this._nodes);
      case 'difference':
        return this._colorLabelProvider.difference().legend();
      case 'todostatus':
        return this._colorLabelProvider.todostatus(this._activities).legend();
      case 'importance':
        return this._colorLabelProvider.importance().legend();
      case 'risk':
        return this._colorLabelProvider.risk().legend();
      case 'qstatus.q1':
      case 'qstatus.q2':
      case 'qstatus.q3':
      case 'qstatus.q4':
        return this._colorLabelProvider.qstatus().legend();
      case 'nodestype':
        return this._colorLabelProvider.nodestype(params, this._models, this._subModels, this._subSets, this._humanResources, this._activities, this._nodeData, this._nodes).legend();
      case 'nodetypes':
        return this._colorLabelProvider.nodetypes(params).legend();
      case 'levels':
        return this._colorLabelProvider.levels().legend();
      default:
        return this._colorLabelProvider.basic().legend();
    }
  }

  protected sort(treeNodes: OrderedMap<string, TreeNode>, sorted = OrderedMap<string, TreeNode>(), processed = Set<string>()): OrderedMap<string, TreeNode> {
    treeNodes.sort(this.sortByPosition).forEach(treeNode => {
      if (!processed.has(treeNode.id) && !isNullOrUndefined(treeNode)) {
        sorted = sorted.set(treeNode.id, treeNode);
        processed = processed.add(treeNode.id);
        if (treeNode.children.size > 0) {
          let childTreeNodes = OrderedMap<string, TreeNode>();
          treeNode.children.forEach(child => childTreeNodes = childTreeNodes.set(child.id, child));
          sorted = this.sort(childTreeNodes, sorted, processed);
        }
      }
    });
    return sorted;
  }

  protected sortByPosition(node1: TreeNode, node2: TreeNode): number {
    if (isNullOrUndefined(node1) || isNullOrUndefined(node2) || node1.node.positionX === node2.node.positionX) {
      return 0;
    }
    return node1.node.positionX > node2.node.positionX ? 1 : -1;
  }

  protected sortByLevelAndSubLevel(node1: TreeNode, node2: TreeNode): number {
    if (node1.level === node2.level) {
      if (node1.subLevel === node2.subLevel) {
        return 0;
      }
      return node1.subLevel > node2.subLevel ? 1 : -1;
    }
    return node1.level > node2.level ? 1 : -1;
  }

  protected sortByLevelSubLevelAndPosition(node1: TreeNode, node2: TreeNode): number {
    if (node1.level === node2.level && node1.subLevel === node2.subLevel) {
      return node1.node.positionX === node2.node.positionX ? 0 : (node1.node.positionX > node2.node.positionX ? 1 : -1);
    }
    if (node1.level === node2.level) {
      return node1.subLevel === node2.subLevel ? 0 : (node1.subLevel > node2.subLevel ? 1 : -1);
    }
    return node1.level < node2.level ? 1 : -1;
  }

  protected wordWrap(text: string, yFunction: any) {
    if (isNullOrUndefined(text)) {
      return [];
    }
    const checkSum = ('' + this._screen.nodeHitboxWidth) + this._screen.wordWrapMaxLines + this._screen.wordWrapBreak + this._screen.wordWrapDivider + this._screen.wordWrapTruncator;
    if (this._wordWrapCheckSum !== checkSum) {
      this._wordWrapCheckSum = checkSum;
      this._wordWrap = new WordWrapper()
        .width(this._screen.nodeHitboxWidth)
        .maxLines(this._screen.wordWrapMaxLines)
        .fillUp(this._screen.wordWrapBreak)
        .divider(this._screen.wordWrapDivider)
        .truncater(this._screen.wordWrapTruncator);
    }
    let wrappedText = this._wordWrap.wrap('' + text);
    const wrappedTextCount = wrappedText.length;
    wrappedText = List(wrappedText).map((line: WordWrapLine, i: number) => {
      line = <WordWrapLine> line.set('x', (- (this._screen.nodeHitboxWidth / 2) + ((this._screen.nodeHitboxWidth - line.width) / 2)));
      line = <WordWrapLine> line.set('y', yFunction(line, i, wrappedTextCount));
      return line;
    }).toArray();
    return wrappedText;
  }

  protected wordWrapGetLabelTopY(line: WordWrapLine, lineNumber: number, lineCount: number): number {
    return - ((lineCount * this._screen.nodeLineHeight) - (lineNumber * this._screen.nodeLineHeight));
  }

  protected wordWrapGetLabelBottomY(line: WordWrapLine, lineNumber: number, lineCount: number): number {
    return (this._screen.nodeRadius * 2) + this._screen.nodeLabelBottomPadding + (lineNumber * this._screen.nodeLineHeight);
  }

  /* Grouping */
  private groupModel(radialMenuItem: RadialMenuItem) {
    const modelId = radialMenuItem.subevent;

    this.groupingAddMap = this.groupingAddMap.clear();
    this.groupingRemoveMap = this.groupingRemoveMap.clear();

    let groupingAddSet = Set<NodeGrouping>();
    let groupingAddMapNodes = Map<string, TreeNode>();
    let groupingRemoveSet = Set<string>();
    let groupingRemoveAfterConfirmationSet = Set<string>();

    this.groupingRelationships = this._relationships;

    this._nodes.forEach(treeNode => {
      if (!isNullOrUndefined(this._appShared.selectNodes) && this._appShared.selectNodes.indexOf(treeNode.id) !== -1) {
        if (treeNode.node.relationships.models.indexOf(modelId) === -1) {
          const uuid = UUID.UUID();
          if ((radialMenuItem.event === 'nodes.selected.grouping.submodels' && this._subModelsMap.has(treeNode.node.relationships.model)) ||
            (radialMenuItem.event === 'nodes.selected.grouping.submodels' && this._modelsMap.has(treeNode.node.relationships.model)) ||
            (radialMenuItem.event === 'nodes.selected.grouping' && this._modelsMap.has(radialMenuItem.subevent) && radialMenuItem.subevent === this._screen.modelId && this._subModelsMap.has(treeNode.node.relationships.model))) {
            this.groupingRemoveMap = this.groupingRemoveMap.set(treeNode.id, treeNode.id);
            groupingRemoveSet = groupingRemoveSet.add(treeNode.id);
          }
          this.groupingAddMap = this.groupingAddMap.set(uuid, treeNode.id);
          groupingAddMapNodes = groupingAddMapNodes.set(uuid, treeNode);
          groupingAddSet = groupingAddSet.add(new NodeGrouping({
            id: uuid,
            positionX: treeNode.node.positionX,
            level: treeNode.node.level,
            nodeDataId: treeNode.node.relationships.nodedata
          }));
        } else {
          const structures = treeNode.node.relationships.nodestructures;
          const lastStructure = structures.length === 1;
          structures.forEach(combi => {
            const combiSplit = combi.split(':');
            if (modelId === combiSplit[0]) {
              if (!lastStructure) {
                this.groupingRemoveMap = this.groupingRemoveMap.set(combiSplit[1], treeNode.id);
                groupingRemoveSet = groupingRemoveSet.add(combiSplit[1]);
              } else {
                groupingRemoveAfterConfirmationSet = groupingRemoveAfterConfirmationSet.add(combiSplit[1]);
              }
            }
          });
        }
      }
    });

    if (groupingAddSet.size > 0) {
      this.groupingAddMaps = this.groupingAddMaps.clear();
      const _groupingAddSet = this.getRelatedForGrouping(groupingAddSet, groupingAddMapNodes, modelId);
      const relatedInvolved = _groupingAddSet.filter(add => add.relationships.parents.length > 0 || add.relationships.children.length > 0).size > 0;
      if (relatedInvolved) {
        this._notifyService.confirmation('', 'GENERAL.ADDRELATIONSHIPS', () => {
          this.onGroupingAdd.next(<Map<string, Set<NodeGrouping>>> Map({}).set(modelId, this.storeGroupingAdd(_groupingAddSet)));
        }, () => {
          this.onGroupingAdd.next(<Map<string, Set<NodeGrouping>>> Map({}).set(modelId, this.storeGroupingAdd(groupingAddSet)));
        });
      } else {
        this.onGroupingAdd.next(<Map<string, Set<NodeGrouping>>> Map({}).set(modelId, this.storeGroupingAdd(groupingAddSet)));
      }
    }

    if (groupingRemoveSet.size > 0) {
      this.onGroupingRemove.next(groupingRemoveSet);
    }

    if (groupingRemoveAfterConfirmationSet.size > 0) {
      if (groupingRemoveAfterConfirmationSet.size === 1) {
        this._notifyService.confirmation('NODE.DELETE.SINGULAR.TITLE', 'NODE.DELETE.SINGULAR.UNSPECIFICTEXT', () => {
          this.onGroupingRemove.next(groupingRemoveAfterConfirmationSet);
        }, () => {});
      } else if (groupingRemoveAfterConfirmationSet.size > 1) {
        this._notifyService.confirmation('NODE.DELETE.PLURAL.TITLE', 'NODE.DELETE.PLURAL.UNSPECIFICTEXT', () => {
          this.onGroupingRemove.next(groupingRemoveAfterConfirmationSet);
        }, () => {});
      }
    }
  }

  private storeGroupingAdd(nodes: Set<NodeGrouping>): Set<NodeGrouping> {
    return <Set<NodeGrouping>> nodes.map(node => {
      this.groupingAddMaps = this.groupingAddMaps.set('' + node.id, node);
      return node;
    });
  }

  private getRelatedForGrouping(addSet: Set<NodeGrouping>, groupingAddMapNodes: Map<string, TreeNode>, modelId: string): Set<NodeGrouping> {
    return <Set<NodeGrouping>> addSet.map(add => {
      const treeNode = groupingAddMapNodes.get('' + add.id);
      if (!isNullOrUndefined(treeNode)) {
        /* Get the relationships */
        const relationships = Map(add.relationships).toJS();
        /* Get the related child nodes */
        relationships.children = this.getRelatedTreeNodes(treeNode, 'children', modelId);
        /* Get the related parent nodes */
        relationships.parents = this.getRelatedTreeNodes(treeNode, 'parents', modelId);
        /* Set the adds */
        add = <NodeGrouping> add.set('relationships', relationships);
      }
      return add;
    });
  }

  private tag(radialMenuItem: RadialMenuItem) {
    const subsetId = radialMenuItem.subevent;
    let addMap = Map<string, Set<string>>();
    let removeMap = Map<string, Set<string>>();
    const subset = this._subSetsMap.get(subsetId);

    this._nodes.forEach(treeNode => {
      if (this._appShared.selectNodes.indexOf(treeNode.id) !== -1) {
        const subsets = Set(treeNode.node.relationships.subsets);
        if (radialMenuItem.selected && subsets.has(subsetId)) {
          let idSet = removeMap.has(subset.id) ? removeMap.get(subset.id) : Set<string>();
          idSet = idSet.add(treeNode.id);
          removeMap = removeMap.set(subset.id, idSet);
        }
        if (!radialMenuItem.selected && !subsets.has(subsetId)) {
          let idSet = addMap.has(subset.id) ? addMap.get(subset.id) : Set<string>();
          idSet = idSet.add(treeNode.id);
          addMap = addMap.set(subset.id, idSet);
        }
      }
    });

    if (addMap.size > 0) {
      this.onGroupingTag.next(addMap);
    }
    if (removeMap.size > 0) {
      this.onGroupingUntag.next(removeMap);
    }
  }

  private sidestep(radialMenuItem: RadialMenuItem) {
    const modelId = radialMenuItem.subevent;
    const id = this._appShared.selectNodes[0];
    if (!radialMenuItem.selected && this._nodes.has(id)) {
      const treeNode = this._nodes.get(id);
      const uuid = UUID.UUID();
      const node = new NodeSidestep({
        id: uuid,
        positionX: treeNode.node.positionX,
        level: treeNode.node.level,
        originalId: treeNode.id,
        nodeDataId: treeNode.node.relationships.nodedata
      });
      this.sidestepMap = this.sidestepMap.set(uuid, node);
      this.onSidestep.next(Map<string, NodeSidestep>().set(modelId, node));
    }
  }

  private onSubSetClicked(subset: Subset) {
    if (isNull(subset)) {
      this.removeFilter('subsets').filterNodes();
    } else {
      this.addFilter('subsets', '' + subset.id, false).filterNodes();
    }
  }

  private getTopMostParent(treeNode: TreeNode, withSideStep = false) {
    if (treeNode.parents.size > 0) {
      return treeNode.parents.filter(_ => !!_).map(parentTreeNode => this.getTopMostParent(parentTreeNode, withSideStep)).first();
    } else if (withSideStep && treeNode.node.isSidestep) {
      const sidestep = this._nodes.get(treeNode.node.relationships.original);
      return isNullOrUndefined(sidestep) ? treeNode.node : this.getTopMostParent(sidestep, withSideStep);
    } else {
      return treeNode.node;
    }
  }

  private setChallenges(treeNode: TreeNode) {
    treeNode = <TreeNode> treeNode.set('challenge', this.getChallenge(treeNode));
    treeNode = <TreeNode> treeNode.set('challenge2nd', this.get2ndChallenge(treeNode));
    return treeNode;
  }

  private getChallenge(treeNode: TreeNode) {
    if (treeNode.level === 0) {
      return null;
    }
    if (treeNode.level === 1 && treeNode.subLevel === 0) {
      return treeNode;
    }
    if (treeNode.parents.size > 0) {
      const tnp = treeNode.parents.map(parentTreeNode => this.getChallenge(parentTreeNode)).filter(parentTreeNode => !isNull(parentTreeNode));
      if (tnp.size > 0) {
        return tnp.first();
      } else {
        return null;
      }
    } else {
      return treeNode;
    }
  }

  private get2ndChallenge(treeNode: TreeNode) {
    if (treeNode.level === 0) {
      return null;
    }
    if (treeNode.level === 1 && treeNode.subLevel === 0) {
      return null;
    }
    if (treeNode.level === 1 && treeNode.subLevel === 1) {
      return treeNode;
    }
    if (treeNode.parents.size > 0) {
      const tnp = treeNode.parents.map(parentTreeNode => this.get2ndChallenge(parentTreeNode)).filter(parentTreeNode => !isNull(parentTreeNode));
      if (tnp.size > 0) {
        return tnp.first();
      } else {
        return null;
      }
    } else {
      return treeNode;
    }
  }

}
