import { Record, Set, Map } from 'immutable';
import { IRelationship } from '../connector';
import { DataModelState, RecordFactory, RequestDiffRecord } from '../shared';

export const RELATIONSHIP_TYPE_DEFAULT = 0;
export const RELATIONSHIP_TYPE_LINK = 1;

const stateRecord = Record({
  ids: Set<string>(),
  entities: Map<string, Relationship>(),
  relationships: Map<string, RelationshipRelationships>(),
  diff: new RequestDiffRecord(),
  isBusy: 0,
});

export class RelationshipState extends stateRecord implements DataModelState {
  ids: Set<string>;
  entities: Map<string, Relationship>;
  relationships: Map<string, RelationshipRelationships>;
  diff: RequestDiffRecord;
  isBusy: number;
}

const relationshipRecord = Record({
  id: undefined,
  weight: '1',
  updatedAt: undefined,
  deletedAt: undefined,
  condition: 0,
  category: null,
  targetDate: null,
  type: RELATIONSHIP_TYPE_DEFAULT,
  refreshId: '',

  relationships: undefined
});

export class Relationship extends relationshipRecord {
  id: string;
  weight: string;
  updatedAt: number;
  deletedAt: number;
  condition: number;
  category: number;
  type: number;
  startDate: string;
  endDate: string;
  refreshId: string;

  relationships: RelationshipRelationships;

  static fromJSONAPI(relationship: IRelationship) {
    return RecordFactory.entity.fromJSONAPI<Relationship>(Relationship, relationship);
  }
}

const relationshipsRecord = Record({
  model: undefined,
  parent: undefined,
  child: undefined
});

export class RelationshipRelationships extends relationshipsRecord {
  model: string;
  parent: string;
  child: string;

  static fromJSONAPI(relationship: IRelationship) {
    return RecordFactory.relationships.fromJSONAPI<RelationshipRelationships>(RelationshipRelationships, relationship);
  }
}

const relationshipCreateRecord = Record({
  id: undefined,
  weight: 1,
  condition: 0,
  category: 0,
  type: RELATIONSHIP_TYPE_DEFAULT,
  parent: undefined,
  child: undefined,
  model: undefined,
  version_id: undefined
});

export class RelationshipCreate extends relationshipCreateRecord {
  id: string;
  weight: string;
  condition: number;
  category: number;
  type: number;
  parent: string;
  child: string;
  model: string;
  version_id: number;
}
