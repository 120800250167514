import { DefaultLangChangeEvent, TranslateParser, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Template } from '../../api/templates';
import { SubscriptionService } from '../../utilities/subscription';
import { Map } from 'immutable';
import { EventEmitter, Injectable } from '@angular/core';
import { isNullOrUndefined, isNumber, isObject } from 'util';
import { AppShared } from '../../../app.shared';

@Injectable()
export class TranslationService {

  /* Listener if template has been set */
  public translationSet = new EventEmitter();

  /* The selected template */
  private translations: any;
  /* Subscription service */
  private subscriptionService = new SubscriptionService();
  /* Registered translations */
  private registeredTranslations = Map<string, { params: any, emitter: BehaviorSubject<string> }[]>();

  public constructor(private translateService: TranslateService, private translateParser: TranslateParser, private appShared: AppShared) {}

  public translate(key: string, params?: any) {
    const translation = this.translation(key, params);
    const emitter = new BehaviorSubject<string>(translation);
    const translations = this.registeredTranslations.has(key) ? this.registeredTranslations.get(key) : [];
    translations.push({ params: params, emitter: emitter });
    this.registeredTranslations = this.registeredTranslations.set(key, translations);
    return emitter;
  }

  public instant(key: string | number, params?: any) {
    return this.translation(key, params);
  }

  public onSet(callback: Function): TranslationService {
    const ref = this;
    this.subscriptionService.add('onchanged', this.translateService.onDefaultLangChange.subscribe(event => {
      ref.setTranslations.call(ref, event);
      callback();
    }));
    this.subscriptionService.add('onset', this.translationSet.subscribe(() => callback()));
    return this;
  }

  public setTemplate(template: Template): TranslationService {
    const ref = this;
    this.translations = undefined;
    if (template.language !== '') {
      this.subscriptionService.add('defaultLang', this.translateService.onDefaultLangChange.subscribe(defaultLang => ref.loadTranslation.call(ref, template)));
      this.loadTranslation(template);
    } else {
      this.triggerTranslation();
    }
    return this;
  }

  private setTranslations(event: DefaultLangChangeEvent) {
    this.appShared.translationMap = this.appShared.translationMap.set(event.lang, event.translations);
    this.translations = event.translations;
  }

  private loadTranslation(template: Template) {
    const language = !isNullOrUndefined(template.defaultLanguage) ? template.defaultLanguage : (!isNullOrUndefined(this.translateService.defaultLang) ? this.translateService.defaultLang : 'en');
    const key = language + '-' + template.language;
    if (this.appShared.translationMap.has(key)) {
      this.translations = this.appShared.translationMap.get(key);
      this.triggerTranslation();
    } else {
      this.subscriptionService.add('translate', this.translateService.currentLoader.getTranslation(key).catch(() => {
        this.appShared.translationMap = this.appShared.translationMap.set(key, undefined);
        this.triggerTranslation();
        return Observable.empty();
      }).take(1).subscribe(translations => {
        this.appShared.translationMap = this.appShared.translationMap.set(key, translations);
        this.translations = translations;
        this.triggerTranslation();
      }));
    }
  }

  private triggerTranslation() {
    this.registeredTranslations.forEach((translations: { params: any, emitter: BehaviorSubject<string> }[], key: string | number) => translations.forEach(translation => {
      const newValue = this.translation(key, translation.params);
      const currentValue = translation.emitter.getValue();
      if (newValue !== currentValue) {
        translation.emitter.next(newValue);
      }
    }));
    this.translationSet.emit();
  }

  private translation(key: string | number, params: any): string {
    if (isNullOrUndefined(key) || key === '' || isObject(key)) {
      return '';
    }
    if (isNumber(key)) {
      return '' + key;
    }
    if (!isNullOrUndefined(this.translations)) {
      const res = this.translateParser.interpolate(this.translateParser.getValue(this.translations, <string> key), params);
      if (!isNullOrUndefined(res)) {
        return res;
      }
    }
    return this.translateService.instant(<string> key, params);
  }

}
