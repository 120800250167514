import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { NgModule, SkipSelf, Optional } from '@angular/core';

import { environment } from '../../../environments/environment';

import { InstanceAction, InstanceEffects } from './instances/index';
import { InstanceService } from './instances/instances.service';
import { ModelAction, ModelEffects, ModelService } from './models/index';
import { NotificationAction, NotificationEffects, NotificationService } from './notifications/index';
import { RelationshipService, RelationshipEffects, RelationshipAction } from './relationships/index';
import { NodeStructureService, NodeStructureAction, NodeStructureEffects } from './nodestructures/index';
import { ImportSchemeService, ImportSchemeAction, ImportSchemeEffects } from './importschemes/index';
import { NodeDataService, NodeDataAction, NodeDataEffects } from './nodedata/index';
import { NodeService, NodesAction, NodesEffects } from './nodes/index';
import { NodesAndRelationshipsAction, NodesAndRelationshipsService, NodesRelationshipsEffects } from "./nodes-relationships/index";
import { BusinessareaService, BusinessareaAction, BusinessareaEffects } from './businessareas';
import { SubsetService, SubsetAction } from './subsets';
import { HumanResourceAction, HumanResourceService, HumanResourceEffects } from './humanresources';
import { ActivityAction, ActivityService, ActivityEffects } from './activities';
import { CommonEffects } from './shared/common.effects';
import { SubsetEffects } from './subsets/subsets.effects';
import { UserService } from './user/user.service';
import { UserAction } from './user/user.actions';
import { UserEffects } from './user/user.effects';
import { GroupAction } from './groups/groups.actions';
import { GroupService } from './groups/groups.service';
import { GroupEffects } from './groups/groups.effects';
import { TemplateEffects } from './templates/templates.effects';
import { TemplateService } from './templates/templates.service';
import { TemplateAction } from './templates/templates.actions';
import { VersionService } from './versions/versions.service';
import { VersionAction } from './versions/versions.actions';
import { VersionEffects } from './versions/versions.effects';
import { SyncEffects } from './syncs/syncs.effects';
import { SyncService } from './syncs/syncs.service';
import { SyncAction } from './syncs/syncs.actions';
import { HierarchyService } from './hierarchy/hierarchy.service';
import { HierarchyAction } from './hierarchy/hierarchy.actions';
import { HierarchyEffects } from './hierarchy/hierarchy.effects';
import { ApiService } from './api.service';
import { AuditAction, AuditEffects, AuditService } from './audits';
import { ConnectorModule } from './connector';
import { SettingsAction, SettingsEffects, SettingsService } from './settings';
import { EmailAction, EmailEffects, EmailService } from './email';
import { NodeEffects } from './node/node.effects';
import { NodeAction } from './node/node.actions';
import { GoService } from './node/node.service';
import { OneTimeTokenAction, OneTimeTokenEffects, OneTimeTokenService } from './onetimetoken';
import { ImportAction, ImportEffects, ImportService } from './import';
import { MiningAction, MiningEffects, MiningService } from './mining';
import { WorkflowAction, WorkflowEffects, WorkflowService } from './workflow';
import { FormAction, FormEffects, FormService } from './form';
import { AiAction, AiEffects, AiService } from './ai';
import { GeneralAction, GeneralEffects, GeneralService } from './general';
import { GuardianAction, GuardianEffects, GuardianService } from './guardian';
import { MyAction, MyEffects, MyService } from './my';
import { TreeAction, TreeEffects, TreeService } from './tree';

@NgModule({
    imports: [
      CommonModule,
      EffectsModule.forRoot([
        InstanceEffects,
        BusinessareaEffects,
        HumanResourceEffects,
        ModelEffects,
        RelationshipEffects,
        NodeStructureEffects,
        NodeDataEffects,
        NodesEffects,
        NodesRelationshipsEffects,
        NotificationEffects,
        ImportSchemeEffects,
        ActivityEffects,
        SubsetEffects,
        UserEffects,
        GroupEffects,
        TemplateEffects,
        VersionEffects,
        SyncEffects,
        HierarchyEffects,
        SettingsEffects,
        AuditEffects,
        EmailEffects,
        NodeEffects,
        ImportEffects,
        MiningEffects,
        WorkflowEffects,
        FormEffects,
        OneTimeTokenEffects,
        AiEffects,
        GeneralEffects,
        GuardianEffects,
        MyEffects,
        TreeEffects
      ]),
      ConnectorModule.forRoot(environment.apiUrl, environment.messagingUrl, environment.exportUrl, environment.oauthUrl, environment.go)
    ],
    providers: [
      InstanceAction,
      InstanceService,
      BusinessareaService,
      BusinessareaAction,
      ModelAction,
      ModelService,
      SubsetAction,
      SubsetService,
      RelationshipService,
      RelationshipAction,
      NodeStructureService,
      NodeStructureAction,
      NodeDataService,
      NodeDataAction,
      NodeService,
      NodesAction,
      NodeService,
      NodeAction,
      NodesAndRelationshipsAction,
      NodesAndRelationshipsService,
      NotificationAction,
      NotificationService,
      ImportSchemeService,
      ImportSchemeAction,
      HumanResourceAction,
      HumanResourceService,
      GroupAction,
      GroupService,
      ActivityAction,
      ActivityService,
      CommonEffects,
      UserService,
      UserAction,
      TemplateService,
      TemplateAction,
      VersionService,
      VersionAction,
      HierarchyService,
      HierarchyAction,
      SyncService,
      SyncAction,
      AuditService,
      AuditAction,
      ApiService,
      SettingsService,
      SettingsAction,
      EmailService,
      EmailAction,
      OneTimeTokenService,
      OneTimeTokenAction,
      GoService,
      NodeAction,
      ImportService,
      ImportAction,
      MiningService,
      MiningAction,
      WorkflowService,
      WorkflowAction,
      FormService,
      FormAction,
      AiService,
      AiAction,
      GeneralService,
      GeneralAction,
      GuardianService,
      GuardianAction,
      MyService,
      MyAction,
      TreeService,
      TreeAction
    ],
    exports: [
      ConnectorModule
    ]
})
export class APIModule {
    constructor(@Optional() @SkipSelf() parentModule: APIModule) {
        if (parentModule) {
            throw new Error('StoreModule already loaded; Import in root module only.');
        }
    }
}
