import { Record } from 'immutable';
import { RequestDiffRecord } from '../shared';

const importStateRecord = Record({
  isBusy: 0,
  diff: new RequestDiffRecord(),
});

export class ImportState extends importStateRecord {
  isBusy: number;
  diff: RequestDiffRecord;
}
