import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';

import * as selectors from './hierarchy.selectors';
import { HierarchyAction } from './hierarchy.actions';
import { SharedService } from '../shared/shared.service';

@Injectable()
export class HierarchyService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: HierarchyAction) {
    super(store, action, selectors);
  }

  public loadActions(): void {
    this.store.dispatch(this.action.loadActions());
  }

}
