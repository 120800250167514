export const TemplateFieldsNodes = {
  tabs: [
    {
      entry: { key: 'header1', label: 'NODE.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.NAME', maxLength: 255, validations: [ 'required' ], validationMessages: { required: 'DATASHEET.VALIDATION.REQUIRED.NAME' } }},
        { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.REFERENCE', maxLength: 8 }},
        { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.CROSSREFERENCE', maxLength: 255 }},
        { entry: { key: 'level', controlType: 'dropdown', label: 'Level', options: [
          { key: 0, value: 'LEVEL.OBJECTIVE' },
          { key: 1, value: 'LEVEL.CHALLENGE' },
          { key: 2, value: 'LEVEL.SOLUTION' },
          { key: 3, value: 'LEVEL.DELIVERABLE' }
        ]}},
        { entry: { key: 'positionX', controlType: 'textbox', type: 'text', label: 'X position' }},
        { entry: { key: 'id', controlType: 'textbox', type: 'number', label: 'Id' }},
        { entry: { key: 'status', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.STATUS', options: [
          { key: 0, value: 'STATUS.PENDING' },
          { key: 100, value: 'STATUS.INDEFINE' },
          { key: 110, value: 'STATUS.INDEVELOP' },
          { key: 120, value: 'STATUS.INVERIFY' },
          { key: 130, value: 'STATUS.INACCEPT' },
          { key: 200, value: 'STATUS.COMPLETED' }
        ]}},
        { entry: { key: 'responsibleId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESPONSIBLEID', source: 'humanresources', hasNull: true }},
        { entry: { key: 'documentUri', type: 'text', controlType: 'textbox-button', buttonTitle: 'NODE.DATASHEET.FIELDS.OPENDOCUMENTURI', label: 'NODE.DATASHEET.FIELDS.DOCUMENTURI' }},
        { entry: { key: 'businesscalculation', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSCALCULATION', source: 'businesscalculations', hasNull: true }},
        { entry: { key: 'storypoints', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.STORYPOINTS' }},
        { entry: { key: 'nodeType', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.TYPE.TITLE', options: [
          { key: 0, value: '' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.TYPE.INITIATIVE' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.TYPE.QUESTION' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT' },
          { key: 4, value: 'NODE.DATASHEET.FIELDS.TYPE.TECH' },
          { key: 5, value: 'NODE.DATASHEET.FIELDS.TYPE.IP' },
          { key: 6, value: 'NODE.DATASHEET.FIELDS.TYPE.REQUIREMENT' },
          { key: 7, value: 'NODE.DATASHEET.FIELDS.TYPE.SOLUTION' },
          { key: 10, value: 'NODE.DATASHEET.FIELDS.TYPE.LIBRARY' },
          { key: 11, value: 'NODE.DATASHEET.FIELDS.TYPE.DATASOURCE' },
          { key: 12, value: 'NODE.DATASHEET.FIELDS.TYPE.TRENDS' },
          { key: 13, value: 'NODE.DATASHEET.FIELDS.TYPE.IPCCLASS' },
          { key: 14, value: 'NODE.DATASHEET.FIELDS.TYPE.PATENT' },
          { key: 15, value: 'NODE.DATASHEET.FIELDS.TYPE.CLIENT' },
          { key: 16, value: 'NODE.DATASHEET.FIELDS.TYPE.COMPETITOR' },
          { key: 17, value: 'NODE.DATASHEET.FIELDS.TYPE.MARKET' },
          { key: 18, value: 'NODE.DATASHEET.FIELDS.TYPE.DEFINITION' },
          { key: 19, value: 'NODE.DATASHEET.FIELDS.TYPE.OPERATION' },
          { key: 20, value: 'NODE.DATASHEET.FIELDS.TYPE.DATATARGET' },
          { key: 21, value: 'NODE.DATASHEET.FIELDS.TYPE.TECHFIELD' },
          { key: 22, value: 'NODE.DATASHEET.FIELDS.TYPE.TECHAREA' },
          { key: 23, value: 'NODE.DATASHEET.FIELDS.TYPE.CCM' },
          { key: 24, value: 'NODE.DATASHEET.FIELDS.TYPE.SCM' },
          { key: 25, value: 'NODE.DATASHEET.FIELDS.TYPE.UCIM' },
          { key: 26, value: 'NODE.DATASHEET.FIELDS.TYPE.CWM' },
          { key: 27, value: 'NODE.DATASHEET.FIELDS.TYPE.MCM' },
          { key: 28, value: 'NODE.DATASHEET.FIELDS.TYPE.IPNAVIGATOR' },
          { key: 29, value: 'NODE.DATASHEET.FIELDS.TYPE.IF' },
          { key: 30, value: 'NODE.DATASHEET.FIELDS.TYPE.THEN' },
          { key: 31, value: 'NODE.DATASHEET.FIELDS.TYPE.ELSE' },
          { key: 32, value: 'NODE.DATASHEET.FIELDS.TYPE.INSTANCE' },
          { key: 33, value: 'NODE.DATASHEET.FIELDS.TYPE.BUSINESSAREA' },
          { key: 34, value: 'NODE.DATASHEET.FIELDS.TYPE.MODEL' },
          { key: 35, value: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOW' },
          { key: 36, value: 'NODE.DATASHEET.FIELDS.TYPE.FORM' },
          { key: 37, value: 'NODE.DATASHEET.FIELDS.TYPE.FORMTAB' },
          { key: 38, value: 'NODE.DATASHEET.FIELDS.TYPE.FORMOPTION' },
          { key: 39, value: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRE' },
          { key: 40, value: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWQUESTIONNAIRETAB' },
          { key: 41, value: 'NODE.DATASHEET.FIELDS.TYPE.WORKFLOWLINK' },
          { key: 42, value: 'NODE.DATASHEET.FIELDS.TYPE.NODE' },
          { key: 43, value: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORY' },
          { key: 44, value: 'NODE.DATASHEET.FIELDS.TYPE.CATEGORYGROUP' },
          { key: 45, value: 'NODE.DATASHEET.FIELDS.TYPE.INDUSTRY' },
          { key: 46, value: 'NODE.DATASHEET.FIELDS.TYPE.MACROTREND' },
          { key: 47, value: 'NODE.DATASHEET.FIELDS.TYPE.MEGATREND' },
          { key: 48, value: 'NODE.DATASHEET.FIELDS.TYPE.MICROTREND' },
          { key: 49, value: 'NODE.DATASHEET.FIELDS.TYPE.IDEA' },
          { key: 50, value: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCT' },
          { key: 51, value: 'NODE.DATASHEET.FIELDS.TYPE.MILESTONE' },
          { key: 52, value: 'NODE.DATASHEET.FIELDS.TYPE.MODULECONFIGURATION' },
          { key: 53, value: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET' },
          { key: 54, value: 'NODE.DATASHEET.FIELDS.TYPE.SMARTACTION' },
          { key: 55, value: 'NODE.DATASHEET.FIELDS.TYPE.DATASHEET' },
          { key: 56, value: 'NODE.DATASHEET.FIELDS.TYPE.DELETE' },
          { key: 57, value: 'NODE.DATASHEET.FIELDS.TYPE.GANTT' },
          { key: 58, value: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUP' },
          { key: 59, value: 'NODE.DATASHEET.FIELDS.TYPE.ALLOWED' },
          { key: 60, value: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONBAR' },
          { key: 61, value: 'NODE.DATASHEET.FIELDS.TYPE.MUSTHAVE' },
          { key: 62, value: 'NODE.DATASHEET.FIELDS.TYPE.ADD' },
          { key: 63, value: 'NODE.DATASHEET.FIELDS.TYPE.CONNECT' },
          { key: 64, value: 'NODE.DATASHEET.FIELDS.TYPE.DISCONNECT' },
          { key: 65, value: 'NODE.DATASHEET.FIELDS.TYPE.SPLASHSCREEN' },
          { key: 66, value: 'NODE.DATASHEET.FIELDS.TYPE.WELCOME.POWER' },
          { key: 67, value: 'NODE.DATASHEET.FIELDS.TYPE.WELCOME.REGULAR' },
          { key: 68, value: 'NODE.DATASHEET.FIELDS.TYPE.WIDGETROW' },
          { key: 69, value: 'NODE.DATASHEET.FIELDS.TYPE.FIELDS' },
          { key: 70, value: 'NODE.DATASHEET.FIELDS.TYPE.FIELD' },
          { key: 71, value: 'NODE.DATASHEET.FIELDS.TYPE.METHODOLOGY' },
          { key: 72, value: 'NODE.DATASHEET.FIELDS.TYPE.ACTION' },
          { key: 73, value: 'NODE.DATASHEET.FIELDS.TYPE.SETUPACTION' },
          { key: 74, value: 'NODE.DATASHEET.FIELDS.TYPE.OBJECTIVE' },
          { key: 75, value: 'NODE.DATASHEET.FIELDS.TYPE.CHALLENGE' },
          { key: 76, value: 'NODE.DATASHEET.FIELDS.TYPE.DELIVERABLE' },
          { key: 77, value: 'NODE.DATASHEET.FIELDS.TYPE.STRATEGYMAP' },
          { key: 78, value: 'NODE.DATASHEET.FIELDS.TYPE.PROJECTPORTFOLIO' },
          { key: 79, value: 'NODE.DATASHEET.FIELDS.TYPE.ACTIONGROUP' },
          { key: 80, value: 'NODE.DATASHEET.FIELDS.TYPE.HOMEACTION' },
          { key: 81, value: 'NODE.DATASHEET.FIELDS.TYPE.NODETYPEGROUP' },
          { key: 82, value: 'NODE.DATASHEET.FIELDS.TYPE.NEXT' },
          { key: 83, value: 'NODE.DATASHEET.FIELDS.TYPE.SORTING' },
          { key: 84, value: 'NODE.DATASHEET.FIELDS.TYPE.FACTSHEET' },
          { key: 85, value: 'NODE.DATASHEET.FIELDS.TYPE.HUMANRESOURCE' },
          { key: 86, value: 'NODE.DATASHEET.FIELDS.TYPE.GROUP' },
          { key: 87, value: 'NODE.DATASHEET.FIELDS.TYPE.CAPABILITY' },
          { key: 88, value: 'NODE.DATASHEET.FIELDS.TYPE.FILTERS' },
          { key: 89, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGANISATIONALUNIT' },
          { key: 90, value: 'NODE.DATASHEET.FIELDS.TYPE.TEAM' },
          { key: 91, value: 'NODE.DATASHEET.FIELDS.TYPE.AI' },
          { key: 92, value: 'NODE.DATASHEET.FIELDS.TYPE.TEXTBLOCK' },
          { key: 93, value: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSESTRUCTURE' },
          { key: 94, value: 'NODE.DATASHEET.FIELDS.TYPE.ENTITY' },
          { key: 95, value: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSE' },
          { key: 96, value: 'NODE.DATASHEET.FIELDS.TYPE.ANALYSEFOLDER' },
          { key: 97, value: 'NODE.DATASHEET.FIELDS.TYPE.KEYWORD' },
          { key: 98, value: 'NODE.DATASHEET.FIELDS.TYPE.COMMENT' },
          { key: 99, value: 'NODE.DATASHEET.FIELDS.TYPE.REGION' },
          { key: 100, value: 'NODE.DATASHEET.FIELDS.TYPE.RELATIONSHIP' },
          { key: 101, value: 'NODE.DATASHEET.FIELDS.TYPE.EDIT' },
          { key: 102, value: 'NODE.DATASHEET.FIELDS.TYPE.PARENT' },
          { key: 103, value: 'NODE.DATASHEET.FIELDS.TYPE.CHILD' },
          { key: 104, value: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALFILTER' },
          { key: 105, value: 'NODE.DATASHEET.FIELDS.TYPE.PLANTS' },
          { key: 106, value: 'NODE.DATASHEET.FIELDS.TYPE.FUNCTIONS' },
          { key: 107, value: 'NODE.DATASHEET.FIELDS.TYPE.COLUMN' },
          { key: 108, value: 'NODE.DATASHEET.FIELDS.TYPE.TASK' },
          { key: 109, value: 'NODE.DATASHEET.FIELDS.TYPE.CAPACITY' },
          { key: 110, value: 'NODE.DATASHEET.FIELDS.TYPE.DRAG' },
          { key: 111, value: 'NODE.DATASHEET.FIELDS.TYPE.DROP' },
          { key: 112, value: 'NODE.DATASHEET.FIELDS.TYPE.COLORLABELPROVIDER' },
          { key: 113, value: 'NODE.DATASHEET.FIELDS.TYPE.COUNTERMEASURE' },
          { key: 114, value: 'NODE.DATASHEET.FIELDS.TYPE.SUM' },
          { key: 115, value: 'NODE.DATASHEET.FIELDS.TYPE.GLOBALSUM' },
          { key: 116, value: 'NODE.DATASHEET.FIELDS.TYPE.IGNORE' },
          { key: 117, value: 'NODE.DATASHEET.FIELDS.TYPE.UPDATE' },
          { key: 118, value: 'NODE.DATASHEET.FIELDS.TYPE.INFRACTION' },
          { key: 119, value: 'NODE.DATASHEET.FIELDS.TYPE.GAP' },
          { key: 120, value: 'NODE.DATASHEET.FIELDS.TYPE.SETTINGS' },
          { key: 121, value: 'NODE.DATASHEET.FIELDS.TYPE.SEARCH' },
          { key: 122, value: 'NODE.DATASHEET.FIELDS.TYPE.SELECT' },
          { key: 123, value: 'NODE.DATASHEET.FIELDS.TYPE.TOGGLE' },
          { key: 124, value: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_HEADER' },
          { key: 125, value: 'NODE.DATASHEET.FIELDS.TYPE.WIDGET_FOOTER' },
          { key: 126, value: 'NODE.DATASHEET.FIELDS.TYPE.PLACE_CHILDREN_ASIDE' },
          { key: 127, value: 'NODE.DATASHEET.FIELDS.TYPE.CURVED_LINK' },
          { key: 128, value: 'NODE.DATASHEET.FIELDS.TYPE.LEGEND' },
          { key: 129, value: 'NODE.DATASHEET.FIELDS.TYPE.FOOTER_TABS' },
          { key: 130, value: 'NODE.DATASHEET.FIELDS.TYPE.FOCUS' },
          { key: 131, value: 'NODE.DATASHEET.FIELDS.TYPE.STEP' },
          { key: 132, value: 'NODE.DATASHEET.FIELDS.TYPE.ADD_ELEMENT' },
          { key: 133, value: 'NODE.DATASHEET.FIELDS.TYPE.ADD_CHILD' },
          { key: 134, value: 'NODE.DATASHEET.FIELDS.TYPE.LINK' },
          { key: 135, value: 'NODE.DATASHEET.FIELDS.TYPE.RECEIVER' },
          { key: 136, value: 'NODE.DATASHEET.FIELDS.TYPE.STRUCTURE' },
          { key: 137, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-1' },
          { key: 138, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-2' },
          { key: 139, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-3' },
          { key: 140, value: 'NODE.DATASHEET.FIELDS.TYPE.WBS-PROGRAM' },
          { key: 141, value: 'NODE.DATASHEET.FIELDS.TYPE.WBS-WORKPACKAGE' },
          { key: 142, value: 'NODE.DATASHEET.FIELDS.TYPE.WBS-ACTIVITY' },
          { key: 143, value: 'NODE.DATASHEET.FIELDS.TYPE.WBS-SUBPROJECTS' },
          { key: 144, value: 'NODE.DATASHEET.FIELDS.TYPE.SKILLS' },
          { key: 145, value: 'NODE.DATASHEET.FIELDS.TYPE.DEMAND' },
          { key: 146, value: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MACHINE' },
          { key: 147, value: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-MODULE' },
          { key: 148, value: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCTGROUP' },
          { key: 149, value: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-GROUP' },
          { key: 150, value: 'NODE.DATASHEET.FIELDS.TYPE.BADWORD' },
          { key: 151, value: 'NODE.DATASHEET.FIELDS.TYPE.MEMBER' },
          { key: 152, value: 'NODE.DATASHEET.FIELDS.TYPE.DUPLICATE' },
          { key: 153, value: 'NODE.DATASHEET.FIELDS.TYPE.REVERSE' },
          { key: 154, value: 'NODE.DATASHEET.FIELDS.TYPE.ROUNDED' },
          { key: 155, value: 'NODE.DATASHEET.FIELDS.TYPE.SUGGESTIONS' },
          { key: 156, value: 'NODE.DATASHEET.FIELDS.TYPE.FULL' },
          { key: 157, value: 'NODE.DATASHEET.FIELDS.TYPE.CENTER' },
          { key: 158, value: 'NODE.DATASHEET.FIELDS.TYPE.HIERARCHY' },
          { key: 159, value: 'NODE.DATASHEET.FIELDS.TYPE.REMAINING' },
          { key: 160, value: 'NODE.DATASHEET.FIELDS.TYPE.ONLY-ONE-STACK' },
          { key: 161, value: 'NODE.DATASHEET.FIELDS.TYPE.VERTICAL' },
          { key: 162, value: 'NODE.DATASHEET.FIELDS.TYPE.HORIZONTAL' },
          { key: 163, value: 'NODE.DATASHEET.FIELDS.TYPE.AXIS' },
          { key: 164, value: 'NODE.DATASHEET.FIELDS.TYPE.TABLEGROUPMONTHS' },
          { key: 165, value: 'NODE.DATASHEET.FIELDS.TYPE.DEFAULTVALUES' },
          { key: 166, value: 'NODE.DATASHEET.FIELDS.TYPE.STRAIGHT-LINKS' },
          { key: 167, value: 'NODE.DATASHEET.FIELDS.TYPE.DIRECT-CHAIN' },
          { key: 168, value: 'NODE.DATASHEET.FIELDS.TYPE.FORM-ROW' },
          { key: 169, value: 'NODE.DATASHEET.FIELDS.TYPE.FORM-COLUMN' },
          { key: 170, value: 'NODE.DATASHEET.FIELDS.TYPE.REMINDER' },
          { key: 171, value: 'NODE.DATASHEET.FIELDS.TYPE.ASSET-POSITION' },
          { key: 172, value: 'NODE.DATASHEET.FIELDS.TYPE.MANUFACTURING-SLOT' },
          { key: 173, value: 'NODE.DATASHEET.FIELDS.TYPE.BY' },
          { key: 174, value: 'NODE.DATASHEET.FIELDS.TYPE.VALUE' },
          { key: 175, value: 'NODE.DATASHEET.FIELDS.TYPE.PAGINATION' },
          { key: 176, value: 'NODE.DATASHEET.FIELDS.TYPE.SLOT' },
          { key: 177, value: 'NODE.DATASHEET.FIELDS.TYPE.LINE' },
          { key: 178, value: 'NODE.DATASHEET.FIELDS.TYPE.MACHINE' },
          { key: 179, value: 'NODE.DATASHEET.FIELDS.TYPE.MODULE' },
          { key: 180, value: 'NODE.DATASHEET.FIELDS.TYPE.TRANSFER' },
          { key: 181, value: 'NODE.DATASHEET.FIELDS.TYPE.MUTATION' },
          { key: 182, value: 'NODE.DATASHEET.FIELDS.TYPE.STANDARD-LINE' },
          { key: 183, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-6' },
          { key: 184, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-7' },
          { key: 185, value: 'NODE.DATASHEET.FIELDS.TYPE.ORGLEVEL-5' },
          { key: 186, value: 'NODE.DATASHEET.FIELDS.TYPE.MY' },
          { key: 187, value: 'NODE.DATASHEET.FIELDS.TYPE.ROLLOUT' },
          { key: 188, value: 'NODE.DATASHEET.FIELDS.TYPE.PROCESS' },
          { key: 189, value: 'NODE.DATASHEET.FIELDS.TYPE.PROJECT-CATEGORY' },
          { key: 190, value: 'NODE.DATASHEET.FIELDS.TYPE.PREFILTER' },
          { key: 191, value: 'NODE.DATASHEET.FIELDS.TYPE.VERSION' },
          { key: 192, value: 'NODE.DATASHEET.FIELDS.TYPE.POSITION' },
          { key: 193, value: 'NODE.DATASHEET.FIELDS.TYPE.STANDARD-CONFIGURATION' },
          { key: 194, value: 'NODE.DATASHEET.FIELDS.TYPE.SUPPLIER' },
          { key: 195, value: 'NODE.DATASHEET.FIELDS.TYPE.OFFLINE' },
          { key: 196, value: 'NODE.DATASHEET.FIELDS.TYPE.EVENT' },
          { key: 197, value: 'NODE.DATASHEET.FIELDS.TYPE.MACHINE-TYPE' },
          { key: 198, value: 'NODE.DATASHEET.FIELDS.TYPE.LCSTATE' },
          { key: 199, value: 'NODE.DATASHEET.FIELDS.TYPE.NODETYPEGROUPS' },
          { key: 200, value: 'NODE.DATASHEET.FIELDS.TYPE.ALTERNATIVE' },
          { key: 201, value: 'NODE.DATASHEET.FIELDS.TYPE.EVENT-CATEGORY' },
          { key: 202, value: 'NODE.DATASHEET.FIELDS.TYPE.LABEL' },
          { key: 203, value: 'NODE.DATASHEET.FIELDS.TYPE.SHARE' },
          { key: 204, value: 'NODE.DATASHEET.FIELDS.TYPE.TREE' },
          { key: 205, value: 'NODE.DATASHEET.FIELDS.TYPE.NOT' },
          { key: 206, value: 'NODE.DATASHEET.FIELDS.TYPE.NOTIFICATIONS' },
          { key: 207, value: 'NODE.DATASHEET.FIELDS.TYPE.PRIVATE' },
          { key: 208, value: 'NODE.DATASHEET.FIELDS.TYPE.TRAVERSER' },
          { key: 209, value: 'NODE.DATASHEET.FIELDS.TYPE.NFM' },
          { key: 210, value: 'NODE.DATASHEET.FIELDS.TYPE.PRODUCT-AREA' },
          { key: 211, value: 'NODE.DATASHEET.FIELDS.TYPE.SEQUENCE' },
          { key: 212, value: 'NODE.DATASHEET.FIELDS.TYPE.ENTRY' },
          { key: 213, value: 'NODE.DATASHEET.FIELDS.TYPE.ANSWER' },
          { key: 214, value: 'NODE.DATASHEET.FIELDS.TYPE.TAG' },
          { key: 215, value: 'NODE.DATASHEET.FIELDS.TYPE.DOCUMENT' },
          { key: 216, value: 'NODE.DATASHEET.FIELDS.TYPE.DATA-STORAGE' },
          { key: 217, value: 'NODE.DATASHEET.FIELDS.TYPE.VIDEO' },
          { key: 218, value: 'NODE.DATASHEET.FIELDS.TYPE.PROMPT' },
          { key: 219, value: 'NODE.DATASHEET.FIELDS.TYPE.EXTERNAL-INPUT' },
          { key: 220, value: 'NODE.DATASHEET.FIELDS.TYPE.INPUT' },
        ]}}
      ]
    },
    {
      entry: { key: 'header2', label: 'NODE.DATASHEET.HEADERS.HEADER2'},
      children: [
        {
          entry: { key: 'group1', label: 'NODE.DATASHEET.GROUPS.GROUP1', controlType: 'group' },
          children: [
            { entry: { key: 'startDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.START'}},
            { entry: { key: 'targetDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.TARGETDATE' }}
          ]
        },
        {
          entry: { key: 'group2', label: 'NODE.DATASHEET.GROUPS.GROUP2', controlType: 'group' },
          children: [
            { entry: { key: 'budget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.KPI' }},
            { entry: { key: 'budgetCalculated', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.AGGREGATIONKPI' }}
          ]
        },
        {
          entry: { key: 'group3', label: 'NODE.DATASHEET.GROUPS.GROUP3', controlType: 'group' },
          children: [
            { entry: { key: 'benefitBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.BENEFITBUDGET' }},
            { entry: { key: 'benefitActual', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.BENEFITACTUAL' }},
            { entry: { key: 'benefitRemaining', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.BENEFITREMAINING' }}
          ]
        },
        {
          entry: { key: 'group4', label: 'NODE.DATASHEET.GROUPS.GROUP4', controlType: 'group' },
          children: [
            { entry: { key: 'costBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.COSTBUDGET' }},
            { entry: { key: 'costActual', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.COSTACTUAL' }},
            { entry: { key: 'costRemaining', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.COSTREMAINING' }}
          ]
        },
        {
          entry: { key: 'group5', label: 'NODE.DATASHEET.GROUPS.GROUP5', controlType: 'group' },
          children: [
            { entry: { key: 'investBudget', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.INVESTBUDGET' }},
            { entry: { key: 'investActual', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.INVESTACTUAL' }},
            { entry: { key: 'investRemaining', type: 'number', controlType: 'textbox', defaultValue: 0, label: 'NODE.DATASHEET.FIELDS.INVESTREMAINING' }}
          ]
        },
        {
          entry: { key: 'group6', label: 'NODE.DATASHEET.GROUPS.GROUP6', controlType: 'group' },
          children: [
            { entry: { key: 'businessBenefit', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.TITLE', options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.NOTAPPLICABLE' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.LOW' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.MEDIUM' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.BUSINESSBENEFIT.HIGH' },
                ]}},
            { entry: { key: 'uncertainty', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.UNCERTAINTY', options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
                ]}}
          ]
        },
        {
          entry: { key: 'group7', label: 'NODE.DATASHEET.GROUPS.GROUP7', controlType: 'group' },
          children: [
            { entry: { key: 'risk', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISK', options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
                ]}},
            { entry: { key: 'sizeType', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.SIZETYPE', options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
                ]}},
            { entry: { key: 'complexity', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMPLEXITY', options: [
                  { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
                  { key: 1, value: 'NODE.DATASHEET.FIELDS.CALCULATED.LOW' },
                  { key: 2, value: 'NODE.DATASHEET.FIELDS.CALCULATED.MEDIUM' },
                  { key: 3, value: 'NODE.DATASHEET.FIELDS.CALCULATED.HIGH' },
                ]}}
          ]
        }
      ]
    },
    {
      entry: { key: 'header3', label: 'NODE.DATASHEET.HEADERS.HEADER3'},
      children: [
        { entry: { key: 'description', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION'}},
        { entry: { key: 'description1', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION1'}},
        { entry: { key: 'description2', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION2'}},
        { entry: { key: 'description3', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION3'}},
        { entry: { key: 'description4', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.DESCRIPTION4'}}
      ]
    },
    {
      entry: { key: 'unused', label: 'NODE.DATASHEET.HEADERS.UNUSED'},
      children: [
        {
          entry: { key: 'groupWorkflow', label: 'NODE.DATASHEET.GROUPS.WORKFLOW', controlType: 'group' },
          children: [
            { entry: { key: 'workFlowModel', controlType: 'dropdown', source: 'models', label: 'NODE.DATASHEET.FIELDS.WORKFLOWMODEL', hasNull: true }},
            { entry: { key: 'workFlowOperation', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'addMarketData', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.ADDMARKETDATA' },
                  { key: 'addPatentsToClient', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.ADDPATENTSTOCLIENT' },
                  { key: 'createNode', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CREATENODE' },
                  { key: 'createModel', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CREATEMODEL' },
                  { key: 'createInstance', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CREATEINSTANCE' },
                  { key: 'createBusinessarea', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CREATEBUSINESSAREA' },
                  { key: 'form', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.FORM' },
                  { key: 'connectCompetitorAndClient', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CONNECTCOMPETITORANDCLIENT' },
                  { key: 'connectTechFieldsAndClient', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CONNECTTECHFIELDSANDCLIENT' },
                  { key: 'connectTechAreasAndClient', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.CONNECTTECHAREASANDCLIENT' },
                  { key: 'getName', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.GETNAME' },
                  { key: 'addChildren', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.ADDCHILDREN' },
                  { key: 'addMarketChildren', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.ADDMARKETCHILDREN' },
                  { key: 'addProductChildren', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.ADDPRODUCTCHILDREN' },
                  { key: 'moduleSetup', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.MODULESETUP' },
                  { key: 'setupDefineTargetConstruct', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPDEFINETARGETCONSTRUCT' },
                  { key: 'setupCollectData', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPCOLLECTDATA' },
                  { key: 'setupDefineContext', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPDEFINECONTEXT' },
                  { key: 'setupCreateNode', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPCREATENODE' },
                  { key: 'setupCreateNodes', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPCREATENODES' },
                  { key: 'setupChooseMethodology', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPCHOOSEMETHODOLOGY' },
                  { key: 'setupHorizontalCreateNodes', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPHORIZONTALCREATENODES' },
                  { key: 'setupVerticalCreateNodes', value: 'NODE.DATASHEET.FIELDS.WORKFLOWOPERATION.SETUPVERTICALCREATENODES' },
                ]}},
            { entry: { key: 'workFlowIfClause', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WORKFLOWIFCLAUSE.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'exists', value: 'NODE.DATASHEET.FIELDS.WORKFLOWIFCLAUSE.EXISTS' },
                  // { key: 'hasName', value: 'NODE.DATASHEET.FIELDS.WORKFLOWIFCLAUSE.HASNAME' }
                ]}},
            { entry: { key: 'workFlowId', type: 'string', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.WORKFLOWID' }},
            { entry: { key: 'workFlowFormId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WORKFLOWFORMID', source: 'forms' }},
            { entry: { key: 'workFlowLink', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WORKFLOWLINK', source: 'workflows' }},
            { entry: { key: 'workFlowFormParams', type: 'string', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.WORKFLOWFORMPARAMS' }},
            { entry: { key: 'workFlowTabId', type: 'string', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.WORKFLOWTABID' }}
          ]
        },
        {
          entry: { key: 'groupForm', label: 'NODE.DATASHEET.GROUPS.FORM', controlType: 'group' },
          children: [
            { entry: { key: 'formId', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.FORMID' }},
            { entry: { key: 'formFieldId', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDID', source: 'nodefields' }},
            { entry: { key: 'formFieldControlType', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'textbox', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXTBOX' },
                  { key: 'dropdown', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DROPDOWN' },
                  { key: 'checkbox', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CHECKBOX' },
                  { key: 'text', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXT' },
                  { key: 'textbox-button', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TEXTBOXBUTTON' },
                  { key: 'date', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DATE' },
                  { key: 'daterange', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DATERANGE' },
                  { key: 'color', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.COLOR' },
                  { key: 'children', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CHILDREN' },
                  { key: 'parents', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.PARENTS' },
                  { key: 'calculated', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CALCULATED' },
                  { key: 'sublevel', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SUBLEVEL' },
                  { key: 'sublevelWithName', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SUBLEVELWITHNAME' },
                  { key: 'image', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.IMAGE' },
                  { key: 'formemail', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.FORMEMAIL' },
                  { key: 'entities', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.ENTITIES' },
                  { key: 'delete', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.DELETE' },
                  { key: 'file-status', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.FILESTATUS' },
                  { key: 'priority-score', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.PRIORITYSCORE' },
                  { key: 'table', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.TABLE' },
                  { key: 'capacity', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.CAPACITY' },
                  { key: 'select', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.SELECT' },
                  { key: 'violatedParagraph', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.VIOLATEDPARAGRAPH' },
                  { key: 'questionnaire', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.QUESTIONNAIRE' },
                  { key: 'hidden', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.HIDDEN' },
                  { key: 'lastEditedBy', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.LASTEDITEDBY' },
                  { key: 'validatedBy', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCONTROLTYPE.VALIDATEDBY' },
                  { key: 'lastEditedWhat', value: 'Last Edited what' },
                  { key: 'lastEditedWhen', value: 'Last Edited at' },
                  { key: 'createdAt', value: 'Created at' },
                  { key: 'createdBy', value: 'Created by' },
                  { key: 'history', value: 'History' },
                  { key: 'reminder', value: 'Reminder' },
                  { key: 'datestack', value: 'Date stack' },
                  { key: 'comments', value: 'Comments' },
                  { key: 'gantt', value: 'Gantt' },
                  { key: 'date-quarter', value: 'Date: Quarter select' },
                  { key: 'node-select', value: 'Node(s) select' },
                  { key: 'action-buttons', value: 'Action buttons' },
                  { key: 'assign', value: 'Assign to elements' },
                  { key: 'colorlabelprovider', value: 'Color label provider' },
                  { key: 'count', value: 'Count' },
                  { key: 'standard-configurations', value: 'Standard configurations' },
                  { key: 'add', value: 'Add' },
                  { key: 'children-sum', value: 'Children Sum' },
                  { key: 'parent-sum', value: 'Parent Sum' },
                  { key: 'children-status', value: 'Children status' },
                  { key: 'start-date', value: 'Start Date' },
                  { key: 'target-date', value: 'Target Date' },
                  { key: 'completeness', value: 'Completeness' },
                  { key: 'boxes', value: 'Boxes' },
                  { key: 'due-date', value: 'Due date' },
                  { key: 'highlight', value: 'Highlight' },
                  { key: 'toggle', value: 'Toggle' },
                  { key: 'yearCalendar', value: 'Year calendar' },
                  { key: 'answer-select', value: 'Answer(s) select' },
                  { key: 'text-marker', value: 'Text Marker' },
                  { key: 'odin-factsheet', value: 'Odin Factsheet' },
                  { key: 'checkChildren', value: 'Check children' },
                  { key: 'formPreFunction', value: 'Form Pre Function' }
                ]}},
            { entry: { key: 'formFieldType', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'string', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.TEXT' },
                  { key: 'number', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.NUMBER' },
                  { key: 'nodes', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.NODES' },
                  { key: 'year', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.YEAR' },
                  { key: 'quarter', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.QUARTER' },
                  { key: 'email', value: 'NODE.DATASHEET.FIELDS.FORMFIELDTYPE.EMAIL' },
                ]}},
            { entry: { key: 'formFieldCalculation', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'roi', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.ROI' },
                  { key: 'strategicclassification', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.STRATEGICCLASSIFICATION' },
                  { key: 'challenge', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.CHALLENGE' },
                  { key: 'challenge2nd', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.CHALLENGE2ND' },
                  { key: 'aggregation', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.AGGREGATION' },
                  { key: 'aggregationsub', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.AGGREGATIONSUB' },
                  { key: 'average', value: 'Average' },
                  { key: 'assignedcapacity', value: 'NODE.DATASHEET.FIELDS.FORMFIELDCALCULATION.ASSIGNEDCAPACITY' },
                  { key: 'line-design', value: 'Line design' },
                  { key: 'traverser', value: 'Traverser' },
                  { key: 'calculateCO2Scope3_3', value: 'Calculate CO2 Scope 3.3.'}
                ]}},
            { entry: { key: 'formBucket', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMBUCKET' }},
            { entry: { key: 'formFieldDropdownValue', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.TITLE', options: [
                  { key: '', value: '' },
                  { key: 'humanresources', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.HUMANRESOURCES' },
                  { key: 'businesscalculations', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.BUSINESSCALCULATIONS' },
                  { key: 'models', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.MODELS' },
                  { key: 'nodes', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.NODES' },
                  { key: 'level', value: 'Levels' },
                  { key: 'status', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.STATUS' },
                  { key: 'statuscolor', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.STATUSCOLOR' },
                  { key: 'shape', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.SHAPE' },
                  { key: 'humanresourceimages', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.HUMANRESOURCEIMAGES' },
                  { key: 'markets', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.MARKETS' },
                  { key: 'overbooked', value: 'NODE.DATASHEET.FIELDS.FORMFIELDDROPDOWNVALUE.OVERBOOKED' },
                  { key: 'nodeType', value: 'Node type' },
                  { key: 'colorLabelProviders', value: 'Color label providers' },
                  { key: 'widget', value: 'Widget'},
                  { key: 'responsible', value: 'Responsible'},
                  { key: 'formFieldId', value: 'Form Field Id'},
                  { key: 'formFieldControlType', value: 'Form field control type'},
                  { key: 'formFieldType', value: 'Form field type'},
                  { key: 'formFieldCalculation', value: 'Form field calculation'},
                  { key: 'formFieldDropdownValue', value: 'Form field dropdown value'}
                ]}},
          ]
        },
        { entry: { key: 'creationDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.CREATIONDATE' }},
        { entry: { key: 'lastUpdated', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.LASTUPDATED' }},
        { entry: { key: 'currency', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.CURRENCY', maxLength: 255 }},
        { entry: { key: 'upload_uri', type: 'text', controlType: 'textbox-button', fileupload: true, buttonTitle: 'NODE.DATASHEET.FIELDS.OPENUPLOADURI', label: 'NODE.DATASHEET.FIELDS.UPLOADURI' }},
        { entry: { key: 'group8', label: 'NODE.DATASHEET.GROUPS.GROUP8', controlType: 'group' }, children: [] },
        { entry: { key: 'group9', label: 'NODE.DATASHEET.GROUPS.GROUP9', controlType: 'group' }, children: [] },
        { entry: { key: 'group10', label: 'NODE.DATASHEET.GROUPS.GROUP10', controlType: 'group' }, children: [] },
        { entry: { key: 'actualDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.ACTUALDATE' }},
        { entry: { key: 'actualStartDate', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.STARTACTUAL' }},
        { entry: { key: 'percentageComplete', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.PERCENTAGECOMPLETE' }},
        { entry: { key: 'priority', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.PRIORITY', options: [
              { key: 1, value: '1' },
              { key: 2, value: '2' },
              { key: 3, value: '3' },
              { key: 4, value: '4' },
              { key: 5, value: '5' },
              { key: 6, value: '6' }
            ]}},
        { entry: { key: 'active', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.ACTIVE' }},
        { entry: { key: 'onreport', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.ONREPORT' }},
        { entry: { key: 'riskProfileCategory', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TITLE', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.EXECUTIVE' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.PROGRAMPROJECT' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.FUNCTIONAL' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.RESOURCE' },
          { key: 4, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.ORGANIZATIONAL' },
          { key: 5, value: 'NODE.DATASHEET.FIELDS.RISKPROFILECATEGORY.TECHNICAL' }
        ]}},
        { entry: { key: 'riskProfileAssessment', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.TITLE', options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.LOW' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.MEDIUM' },
              { key: 2, value: 'NODE.DATASHEET.FIELDS.RISKPROFILEASSESSMENT.HIGH' }
            ]}},
        { entry: { key: 'riskProfileCountermeasures', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.RISKPROFILECOUNTERMEASURES' }},
        // Fields for PWC
        { entry: { key: 'time', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.TIME.TITLE', options: [
              { key: 0, value: 'NODE.DATASHEET.FIELDS.TIME.APPROACH' },
              { key: 1, value: 'NODE.DATASHEET.FIELDS.TIME.PROJECT' },
            ]}},
        { entry: { key: 'timeDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.TIMEDESCRIPTION' }},
        { entry: { key: 'cost', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.COST' }},
        { entry: { key: 'costDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.COSTDESCRIPTION' }},
        { entry: { key: 'quality', type: 'text', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.QUALITY' }},
        { entry: { key: 'qualityDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.QUALITYDESCRIPTION' }},
        { entry: { key: 'executive', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.EXECUTIVE.TITLE', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.NOTAPPLICABLE' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.LOW' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.MEDIUM' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.EXECUTIVE.HIGH' }
        ]}},
        { entry: { key: 'executiveDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.EXECUTIVEDESCRIPTION' }},
        { entry: { key: 'program', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.PROGRAM.TITLE', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.PROGRAM.NOTAPPLICABLE' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.PROGRAM.LOW' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.PROGRAM.MEDIUM' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.PROGRAM.HIGH' },
        ]}},
        { entry: { key: 'programDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.PROGRAMDESCRIPTION' }},
        { entry: { key: 'functional', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.TITLE', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.NOTAPPLICABLE' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.LOW' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.MEDIUM' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.FUNCTIONAL.HIGH' },
        ]}},
        { entry: { key: 'functionalDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.FUNCTIONALDESCRIPTION' }},
        { entry: { key: 'resource', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.RESOURCE.TITLE', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.RESOURCE.NOTAPPLICABLE' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.RESOURCE.LOW' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.RESOURCE.MEDIUM' },
          { key: 3, value: 'NODE.DATASHEET.FIELDS.RESOURCE.HIGH' },
        ]}},
        { entry: { key: 'resourceDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.RESOURCEDESCRIPTION' }},
        { entry: { key: 'organizational', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONAL', options: [
              { key: 1, value: '1' },
              { key: 2, value: '2' },
              { key: 3, value: '3' },
              { key: 4, value: '4' },
              { key: 5, value: '5' },
              { key: 6, value: '6' },
              { key: 7, value: '7' },
              { key: 8, value: '8' },
              { key: 9, value: '9' },
              { key: 10, value: '10' }
            ]}},
        { entry: { key: 'organizationalDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.ORGANIZATIONALDESCRIPTION' }},
        { entry: { key: 'technical', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.TECHNICAL', options: [
              { key: 1, value: '1' },
              { key: 2, value: '2' },
              { key: 3, value: '3' },
              { key: 4, value: '4' },
              { key: 5, value: '5' },
              { key: 6, value: '6' },
              { key: 7, value: '7' },
              { key: 8, value: '8' },
              { key: 9, value: '9' },
              { key: 10, value: '10' }
            ]}},
        { entry: { key: 'technicalDescription', controlType: 'text', label: 'NODE.DATASHEET.FIELDS.TECHNICALDESCRIPTION' }},
        { entry: { key: 'costDescription', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.WORKFLOWQUESTION' }},
        { entry: { key: 'uncertainty', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WORKFLOWANSWERS', labelFromProperty: 'costDescription', options: [
          { key: 0, value: 'NODE.DATASHEET.FIELDS.CALCULATED.NOTAPPLICABLE' },
          { key: 1, value: 'GENERAL.YES' },
          { key: 2, value: 'GENERAL.NO' }
        ]}},
        { entry: { key: 'children', controlType: 'children', label: 'NODE.DATASHEET.FIELDS.CHILDREN', source: 'nodescreate', type: 'nodes' }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'NODE.DATASHEET.FIELDS.COLOR' }},
        { entry: { key: 'icon', type: 'text', controlType: 'icon', label: 'NODE.DATASHEET.FIELDS.ICON' }},
        { entry: { key: 'widget', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.WIDGET', options: [
          { key: '', value: '' },
          { key: 'setup', value: 'Setup' },
          { key: 'setuptargetconstruct', value: 'Setup target construct' },
          { key: 'setupprojects', value: 'Setup projects' },
          { key: 'setuphowtowork', value: 'Setup how to work' },
          { key: 'setupoverview', value: 'Setup overview' },
          { key: 'home', value: 'Home' },
          { key: 'strategicobjectives', value: 'Strategic objectives' },
          { key: 'projectsstack', value: 'Projects stack' },
          { key: 'gantt', value: 'Gantt' },
          { key: 'setbudget', value: 'Set budget' },
          { key: 'humanresources', value: 'Human resources' },
          { key: 'vertical', value: 'Vertical' },
          { key: 'horizontal', value: 'Horizontal' },
          { key: 'factsheet', value: 'Fact sheet' },
          { key: 'list', value: 'List legacy' },
          { key: 'list2', value: 'List' },
          { key: 'assignhumanresources', value: 'Assign human resources' },
          { key: 'assignteams', value: 'Assign teams' },
          { key: 'humanresourceslist', value: 'Human resources list' },
          { key: 'eisenhowermatrix', value: 'Eisenhower matrix' },
          { key: 'report', value: 'Report' },
          { key: 'notifications', value: 'Notifications' },
          { key: 'ai-analysedocument', value: 'AI Analyse document' },
          { key: 'ai-analysedocument-result', value: 'AI Analyse document result' },
          { key: 'existing-files', value: 'Existing files' },
          { key: 'file-viewer', value: 'File viewer' },
          { key: 'file-keywords', value: 'File keywords' },
          { key: 'experts-list', value: 'Experts list' },
          { key: 'send-to-markets', value: 'Send to markets' },
          { key: 'select-markets', value: 'Select markets' },
          { key: 'selected-file-viewer', value: 'Selected file viewer' },
          { key: 'review-experts', value: 'Review experts' },
          { key: 'selected-experts', value: 'Selected experts' },
          { key: 'confirm-action', value: 'Confirm action' },
          { key: 'assigned-files', value: 'Assigned files' },
          { key: 'fiscal-years', value: 'Fiscal years' },
          { key: 'operational-staffing', value: 'Operational staffing' },
          { key: 'kanban-board', value: 'Kanban board' },
          { key: 'chatGPT-kanban-board', value: 'Odin' },
          { key: 'kanban-board-legacy', value: 'Kanban board legacy' },
          { key: 'permissions2', value: 'Permissions' },
          { key: 'filter-byelement', value: 'Filter: By element' },
          { key: 'filter-byfields', value: 'Filter: By fields' },
          { key: 'filter-bydate', value: 'Filter: By date' },
          { key: 'assign-element', value: 'Assign element' },
          { key: 'manage-operational-staffing', value: 'Manage operational staffing' },
          { key: 'assign-staffing', value: 'Assign staffing' },
          { key: 'gantt-projects', value: 'Projects Gantt' },
          { key: 'projects-table', value: 'Projects table' },
          { key: 'data-browser', value: 'Data browser' },
          { key: 'widget-remote', value: 'Widget remote' },
          { key: 'element-connector', value: 'Element connector' },
          { key: 'table', value: 'Table' },
          { key: 'infraction-detailed', value: 'Infraction detailed' },
          { key: 'infraction-violation', value: 'Infraction violation' },
          { key: 'infraction-members', value: 'Infraction members' },
          { key: 'infraction-reference', value: 'Infraction reference' },
          { key: 'infraction-countermeasures', value: 'Infraction countermeasures' },
          { key: 'infraction-form', value: 'Infraction form' },
          { key: 'infraction-countermeasure-form', value: 'Infraction countermeasures & form' },
          { key: 'infraction-youtubeCaption', value: 'Youtube-Caption' },
          { key: 'worldmap', value: 'World map' },
          { key: 'charts', value: 'Charts' },
          { key: 'infraction-table', value: 'Infraction table' },
          { key: 'onboarding-start', value: 'Onboarding start' },
          { key: 'onboarding-worldmap', value: 'Onboarding world map' },
          { key: 'onboarding-todos', value: 'Onboarding todos' },
          { key: 'onboarding-adddocument', value: 'Onboarding add document' },
          { key: 'onboarding-documentselect', value: 'Onboarding document select' },
          { key: 'onboarding-documentview', value: 'Onboarding document view' },
          { key: 'onboarding-documentsendtoexpert', value: 'Onboarding send document to expert' },
          { key: 'todo', value: 'To-Do list' },
          { key: 'debug', value: 'Debug (dev use only)' },
          { key: 'octimine', value: 'Octimine' },
          { key: 'guardian', value: 'Guardian' },
          { key: 'heatmap', value: 'Heatmap' },
          { key: 'overview', value: 'Overview' },
          { key: 'permissions', value: 'Permissions' },
          { key: 'form', value: 'Form' },
          { key: 'machines-transfer', value: 'Machine transfer' },
          { key: 'machines-available', value: 'Machines available' },
          { key: 'machine-transfer-overview', value: 'Machine transfer overview' },
          { key: 'lines', value: 'Lines' },
          { key: 'capabilities', value: 'Capabilities' },
          { key: 'mutator', value: 'Mutator' },
          { key: 'search', value: 'Search' },
          { key: 'machine-transfer-objects', value: 'Machine transfer objects' },
          { key: 'tree', value: 'Tree' },
          { key: 'onboarding-document-email', value: 'Onboarding document email' },
          { key: 'standard-configurations', value: 'Standard configuration edit' },
          { key: 'timeline', value: 'Timeline' },
          { key: 'comparison', value: 'Comparison' },
          { key: 'send-mail', value: 'Send mail' },
          { key: 'top-level-report', value: 'Top level report' },
          { key: 'machines-kanban', value: 'Machine transfer: Kanban' },
          { key: 'notifications-setup', value: 'Notifications setup' },
          { key: 'questionnaire', value: 'Questionnaire' },
          { key: 'sequence', value: 'Sequence helper' },
          { key: 'search-by-tags', value: 'Search by tags' },
          { key: 'video-player', value: 'Video player' },
        ]}},
        { entry: { key: 'formFieldEditable', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMFIELDEDITABLE' }},
        { entry: { key: 'formFieldSortable', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMFIELDSORTABLE' }},
        { entry: { key: 'formFieldFilterable', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMFIELDFILTERABLE' }},
        { entry: { key: 'formFieldFixed', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMFIELDFIXED' }},
        { entry: { key: 'formFieldSearchable', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FORMFIELDSEARCHABLE' }},
        { entry: { key: 'formFieldWidth', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.FORMFIELDWIDTH' }},
        { entry: { key: 'formFieldBucketId', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.FORMFIELDBUCKETID' }},
        { entry: { key: 'obligatory', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.OBLIGATORY' }},
        { entry: { key: 'first_name', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.FIRSTNAME' }},
        { entry: { key: 'last_name', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.LASTNAME' }},
        { entry: { key: 'email', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.EMAIL' }},
        { entry: { key: 'image', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.IMAGE' }},
        { entry: { key: 'weight', controlType: 'textbox', type: 'number', label: 'RELATIONSHIP.DATASHEET.FIELDS.WEIGHT' }},
        { entry: { key: 'createdAt', type: 'datetime', controlType: 'date', label: 'NODE.DATASHEET.FIELDS.CREATEDAT'}},
        { entry: { key: 'form_default_value', controlType: 'textbox', type: 'string', label: 'NODE.DATASHEET.FIELDS.FORMDEFAULTVALUE' }},
        { entry: { key: 'formFieldShape', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FORMFIELDSHAPE', options: [
          { key: '', value: '' },
          { key: 'circle', value: 'Circle' },
          { key: 'triangle', value: 'Triangle' },
          { key: 'rectangle', value: 'Rectangle' }
        ]}},
        { entry: { key: 'ai_type', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.AITYPE', options: [
          { key: '', value: '' },
          { key: 'roadmap', value: 'Roadmap' }
        ]}},
        { entry: { key: 'ai_preset', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.AIPRESET', options: [
          { key: '', value: '' },
          { key: '(?m)§ (\\d*)', value: 'Paragraph - Level 0' },
          { key: '(?m)%1.(\\d+)', value: 'Paragraph - Level 1' },
          { key: '^\\w.*\\b:', value: 'Headline' },
          { key: '^-.*', value: 'Question' },
          { key: '^\\d+\\.\\s[^\\n]*', value: 'Numeric list - Level 0 (e.g. 1.)' },
          { key: '^\\d+\\.\\d+[^\\n]*', value: 'Numeric list - Level 1 (e.g. 1.1)' },
        ]}},
        { entry: { key: 'commercialStatus', type: 'number', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COMMERCIALSTATUS.TITLE', options: [
          { key: 0, value: '' },
          { key: 1, value: 'NODE.DATASHEET.FIELDS.COMMERCIALSTATUS.STATUS1' },
          { key: 2, value: 'NODE.DATASHEET.FIELDS.COMMERCIALSTATUS.STATUS2' }
        ]}},
        { entry: { key: 'fullscreen', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FULLSCREEN' }},
        { entry: { key: 'printable', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.PRINTABLE' }},
        { entry: { key: 'hideWidget', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.HIDEWIDGET' }},
        { entry: { key: 'sorting', type: 'number', controlType: 'textbox', label: 'NODE.DATASHEET.FIELDS.SORTING' }},
        { entry: { key: 'dashboardCols', type: 'number', controlType: 'textbox', hasNull: true, label: 'NODE.DATASHEET.FIELDS.DASHBOARDCOLS' }},
        { entry: { key: 'dashboardRows', type: 'number', controlType: 'textbox', hasNull: true, label: 'NODE.DATASHEET.FIELDS.DASHBOARDROWS' }},
        { entry: { key: 'dashboardX', type: 'number', controlType: 'textbox', hasNull: true, label: 'NODE.DATASHEET.FIELDS.DASHBOARDX' }},
        { entry: { key: 'dashboardY', type: 'number', controlType: 'textbox', hasNull: true, label: 'NODE.DATASHEET.FIELDS.DASHBOARDY' }},
        { entry: { key: 'start', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.START', required: true, validationMessage: 'VALIDATION.REQUIRED.START' }},
        { entry: { key: 'end', type: 'datetime', controlType: 'date', label: 'ACTIVITY.DATASHEET.FIELDS.END', required: true, validationMessage: 'VALIDATION.REQUIRED.END' }},
        { entry: { key: 'fieldConversion', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FIELDCONVERSION', options: [
          { key: null, value: '' },
          { key: 'fte-md', value: 'FTE -> MD' },
          { key: 'md-fte', value: 'MD -> FTE' }
        ]}},
        { entry: { key: 'fieldCreateType', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.FIELDCREATETYPE', options: [
          { key: null, value: '' },
          { key: 'clone', value: 'Clone node' },
          { key: 'clone-connect', value: 'Clone node with connect node' },
          { key: 'group', value: 'Group node' },
          { key: 'group-connect', value: 'Group node with connect node' },
        ]}},
        { entry: { key: 'fieldSkipIfExists', controlType: 'checkbox', label: 'NODE.DATASHEET.FIELDS.FIELDSKIPIFEXISTS' }},
        { entry: { key: 'colorLabelProvider', controlType: 'dropdown', label: 'NODE.DATASHEET.FIELDS.COLORLABELPROVIDERS', source: 'colorlabelproviders', hasNull: true }},
        // Missing dennemeyer fields */
        { entry: { key: 'alsoPublishedAs', type: 'string', controlType: 'textbox', label: 'Also published as' }},
        { entry: { key: 'cpc', type: 'string', controlType: 'textbox', label: 'CPC' }},
        { entry: { key: 'cpc4', type: 'string', controlType: 'textbox', label: 'CPC4' }},
        { entry: { key: 'ipc', type: 'string', controlType: 'textbox', label: 'IPC' }},
        { entry: { key: 'ipc4', type: 'string', controlType: 'textbox', label: 'IPC4' }},
        { entry: { key: 'applicants', type: 'string', controlType: 'textbox', label: 'Applicants' }},
        { entry: { key: 'techAreas', type: 'string', controlType: 'textbox', label: 'Tech areas' }},
        { entry: { key: 'techFields', type: 'string', controlType: 'textbox', label: 'Tech fields' }},
        { entry: { key: 'authorities', type: 'string', controlType: 'textbox', label: 'Authorities' }},
        { entry: { key: 'numberField1', type: 'number', controlType: 'textbox', label: 'Number field 1' }},
        { entry: { key: 'numberField2', type: 'number', controlType: 'textbox', label: 'Number field 2' }},
        { entry: { key: 'numberField3', type: 'number', controlType: 'textbox', label: 'Number field 3' }},
        { entry: { key: 'numberField4', type: 'number', controlType: 'textbox', label: 'Number field 4' }},
        { entry: { key: 'numberField5', type: 'number', controlType: 'textbox', label: 'Number field 5' }},
        { entry: { key: 'numberField6', type: 'number', controlType: 'textbox', label: 'Number field 6' }},
        { entry: { key: 'numberField7', type: 'number', controlType: 'textbox', label: 'Number field 7' }},
        { entry: { key: 'numberField8', type: 'number', controlType: 'textbox', label: 'Number field 8' }},
        { entry: { key: 'numberField9', type: 'number', controlType: 'textbox', label: 'Number field 9' }},
        { entry: { key: 'numberField10', type: 'number', controlType: 'textbox', label: 'Number field 10' }},
        { entry: { key: 'textField1', type: 'text', controlType: 'textbox', label: 'Text field 1' }},
        { entry: { key: 'textField2', type: 'text', controlType: 'textbox', label: 'Text field 2' }},
        { entry: { key: 'textField3', type: 'text', controlType: 'textbox', label: 'Text field 3' }},
        { entry: { key: 'textField4', type: 'text', controlType: 'textbox', label: 'Text field 4' }},
        { entry: { key: 'textField5' , type: 'text', controlType: 'textbox', label: 'Text field 5' }},
        { entry: { key: 'textField6', type: 'text', controlType: 'textbox', label: 'Text field 6' }},
        { entry: { key: 'textField7', type: 'text', controlType: 'textbox', label: 'Text field 7' }},
        { entry: { key: 'textField8', type: 'text', controlType: 'textbox', label: 'Text field 8' }},
        { entry: { key: 'textField9', type: 'text', controlType: 'textbox', label: 'Text field 9' }},
        { entry: { key: 'textField10', type: 'number', controlType: 'textbox', label: 'Text field 10' }},
        { entry: { key: 'textField11', type: 'text', controlType: 'textbox', label: 'Text field 11' }},
        { entry: { key: 'textField12', type: 'text', controlType: 'textbox', label: 'Text field 12' }},
        { entry: { key: 'textField13', type: 'text', controlType: 'textbox', label: 'Text field 13' }},
        { entry: { key: 'textField14', type: 'text', controlType: 'textbox', label: 'Text field 14' }},
        { entry: { key: 'textField15', type: 'text', controlType: 'textbox', label: 'Text field 15' }},
        { entry: { key: 'textField16', type: 'text', controlType: 'textbox', label: 'Text field 16' }},
        { entry: { key: 'textField17', type: 'text', controlType: 'textbox', label: 'Text field 17' }},
        { entry: { key: 'textField18', type: 'text', controlType: 'textbox', label: 'Text field 18' }},
        { entry: { key: 'textField19', type: 'text', controlType: 'textbox', label: 'Text field 19' }},
        { entry: { key: 'textField20', type: 'text', controlType: 'textbox', label: 'Text field 20' }},
        { entry: { key: 'textField21', type: 'text', controlType: 'textbox', label: 'Text field 21' }},
        { entry: { key: 'textField22', type: 'text', controlType: 'textbox', label: 'Text field 22' }},
        { entry: { key: 'textField23', type: 'text', controlType: 'textbox', label: 'Text field 23' }},
        { entry: { key: 'textField24', type: 'text', controlType: 'textbox', label: 'Text field 24' }},
        { entry: { key: 'textField25', type: 'text', controlType: 'textbox', label: 'Text field 25' }},
        { entry: { key: 'textField26', type: 'text', controlType: 'textbox', label: 'Text field 26' }},
        { entry: { key: 'textField27', type: 'text', controlType: 'textbox', label: 'Text field 27' }},
        { entry: { key: 'textField28', type: 'text', controlType: 'textbox', label: 'Text field 28' }},
        { entry: { key: 'textField29', type: 'text', controlType: 'textbox', label: 'Text field 29' }},
        { entry: { key: 'textField30', type: 'text', controlType: 'textbox', label: 'Text field 30' }},
        { entry: { key: 'textField31', type: 'text', controlType: 'textbox', label: 'Text field 31' }},
        { entry: { key: 'textField32', type: 'text', controlType: 'textbox', label: 'Text field 32' }},
        { entry: { key: 'textField33', type: 'text', controlType: 'textbox', label: 'Text field 33' }},
        { entry: { key: 'textField34', type: 'text', controlType: 'textbox', label: 'Text field 34' }},
        { entry: { key: 'textField35', type: 'text', controlType: 'textbox', label: 'Text field 35' }},
        { entry: { key: 'textField36', type: 'text', controlType: 'textbox', label: 'Text field 36' }},
        { entry: { key: 'textField37', type: 'text', controlType: 'textbox', label: 'Text field 37' }},
        { entry: { key: 'textField38', type: 'text', controlType: 'textbox', label: 'Text field 38' }},
        { entry: { key: 'textField39', type: 'text', controlType: 'textbox', label: 'Text field 39' }},
        { entry: { key: 'textField40', type: 'text', controlType: 'textbox', label: 'Text field 40' }},
        { entry: { key: 'formFieldValidation', type: 'text', controlType: 'textbox', label: 'Form field validation' }},
        { entry: { key: 'formFieldValidationMessage', type: 'text', controlType: 'textbox', label: 'Form field validation message' }},
        // Placeholder for internal form handling
        { entry: { key: 'dynamic-reminder', controlType: '', label: 'Dynamic: Reminder' }},
        { entry: { key: 'dynamic-datestack', controlType: '', label: 'Dynamic: Date range' }},
        { entry: { key: 'dynamic-comments', controlType: '', label: 'Dynamic: Comments' }},
        { entry: { key: 'dynamic-gantt', controlType: '', label: 'Dynamic: Gantt' }},
        { entry: { key: 'dynamic-sending-site', controlType: '', label: 'Dynamic: Sending site' }},
        { entry: { key: 'dynamic-receiving-site', controlType: '', label: 'Dynamic: Receiving site' }},
        { entry: { key: 'dynamic-amount', controlType: '', label: 'Dynamic: Amount' }},
        { entry: { key: 'dynamic-expand', controlType: '', label: 'Dynamic: Expand' }},
        { entry: { key: 'dynamic-machine-type', controlType: '', label: 'Dynamic: Machine type' }},
        { entry: { key: 'dynamic-line', controlType: '', label: 'Dynamic: Line' }},
        { entry: { key: 'dynamic-children', controlType: '', label: 'Dynamic: Children' }},
        { entry: { key: 'dynamic-parents', controlType: '', label: 'Dynamic: Parents' }},
        { entry: { key: 'dynamic-assign', controlType: '', label: 'Dynamic: Assign' }},
        { entry: { key: 'dynamic-count', controlType: '', label: 'Dynamic: Count' }},
        { entry: { key: 'dynamic-transfer', controlType: '', label: 'Dynamic: Transfer' }},
        { entry: { key: 'dynamic-reassign', controlType: '', label: 'Dynamic: Re-Assign' }},
        { entry: { key: 'dynamic-as-user', controlType: '', label: 'Dynamic: As user' }},
        { entry: { key: 'dynamic-has-access', controlType: '', label: 'Dynamic: Has access' }},
        { entry: { key: 'dynamic-connect', controlType: '', label: 'Dynamic: Connect' }},
      ]
    }
  ]
};
