import { Set, Map, Record } from 'immutable';
import {IAudit, IAuditAttributes} from '../connector';
import {DataModelState, RecordFactory, RequestDiffRecord} from '../shared';
import {NodeData} from '../nodedata';
import {NodeDataRelationships} from '../nodedata/nodedata.models';


const auditStateRecord = Record({
    ids: Set<string>(),
    entities: Map<string, Audit>(),
    relationships: Map<string, AuditRelationships>(),
    diff: new RequestDiffRecord(),
    isBusy: 0,
    history: []
});

export class AuditState extends auditStateRecord implements DataModelState {
    ids: Set<string>;
    entities: Map<string, NodeData>;
    relationships: Map<string, NodeDataRelationships>;
    diff: RequestDiffRecord;
    isBusy: number;
    history: any;
}

const auditRecord = Record(<IAuditAttributes>{
    id: undefined,
    event: '',
    before: {},
    after: {},
    url: '',
    userAgent: '',
    ip: '',
    tags: [],
    type: '',
    elementId: undefined,
    userId: undefined,
    responsibleId: undefined,
    updatedAt: undefined
});

export class Audit extends auditRecord implements IAuditAttributes {
    id: number;
    event: string;
    before: any;
    after: any;
    url: string;
    userAgent: string;
    ip: string;
    tags: any;
    type: string;
    elementId: string;
    userId: number;
    responsibleId: number;
    updatedAt: any;

    relationships: AuditRelationships;

    static fromJSONAPI(audit: IAudit) {
        return RecordFactory.entity.fromJSONAPI<Audit>(Audit, audit);
    }
}

const relationshipsRecord = Record({
    users: undefined,
    nodedata: undefined,
    nodestructures: undefined,
    activities: undefined,
    relationships: undefined,
    models: undefined,
    businessarea: undefined,
});

export class AuditRelationships extends relationshipsRecord {
    users: string;
    nodedata: string;
    nodestructures: string;
    activities: string;
    relationships: string;
    models: string[];
    businessarea: string;

    static fromJSONAPI(audit: IAudit) {
        return RecordFactory.relationships.fromJSONAPI<AuditRelationships>(AuditRelationships, audit);
    }
}

const historyRecord = Record({
  id: undefined,
  related_id: undefined,
  auditable_id: undefined,
  user_id: undefined,
  user: {
    id: undefined,
    color: '',
    email: '',
    image: null,
    tokenID: '',
    userType: '',
    last_name: '',
    first_name: ''
  },
  event: '',
  type: '',
  parent_id: undefined,
  child_id: undefined,
  old_id: undefined,
  delta: {},
  actual_Values: {},
  createdAt: {
    Time: '',
    Valid: false
  },
  rejectedAt: {
    Time: '',
    Valid: false
  },
  rejectedBy: {
    Time: '',
    Valid: false
  }
});

export class HistoryElement extends historyRecord {
  id: number;
  related_id: number;
  auditable_id: number;
  user_id: number;
  user: {
    id: number,
    color: string,
    email: string,
    image: string,
    tokenID: string,
    userType: string,
    last_name: string,
    first_name: string
  };
  event: string;
  type: string;
  parent_id: number;
  child_id: number;
  old_id: number;
  delta: {};
  actual_Values: {};
  createdAt: {
    Time: string,
    Valid: false
  };
  rejectedAt: {
    Time: string,
    Valid: false
  };
  rejectedBy: {
    Time: string,
    Valid: false
  };

  static fromJSONAPI(historyElement: any) {
    return RecordFactory.entity.fromJSONAPI<HistoryElement>(HistoryElement, historyElement);
  }
}
