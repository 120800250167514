import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { GoAPI } from '../connector';
import { NodeDataAction } from '../nodedata';
import { NodeStructureAction } from '../nodestructures';
import { RelationshipAction } from '../relationships';
import { CommonEffects } from '../shared';
import { Action } from '../shared/common.action';
import { NodesAndRelationshipsAction } from './nodes-relationships.action';

@Injectable()
export class NodesRelationshipsEffects {
  @Effect() nodesRelationship$ = this.actions$
    .pipe(ofType(NodesAndRelationshipsAction.CREATE))
    .filter((action: Action) => !!action.payload)
    .mergeMap(
      (action: Action) => this.api.nodesAndRelationships.create(action.payload.data)
        .mergeMap(response => this.commonEffects.createNodesAndRelationships(action.payload, response))
        .catch((error: any) => Observable.of(this.nodeStructureAction.createFail(action.payload, error))));

  constructor(
    private api: GoAPI,
    private actions$: Actions,
    private nodesAndRelationshipsAction: NodesAndRelationshipsAction,
    private relationshipAction: RelationshipAction,
    private nodeStructureAction: NodeStructureAction,
    private nodeDataAction: NodeDataAction,
    private commonEffects: CommonEffects
  ) { }
}
