import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.reducer';
import { NodesAndRelationshipsAction } from './nodes-relationships.action';
import { SharedService } from '../shared/shared.service';
import * as selectors from '../nodedata/nodedata.selectors';

@Injectable()
export class NodesAndRelationshipsService extends SharedService {

  public constructor(protected store: Store<AppState>, protected action: NodesAndRelationshipsAction) {
    super(store, action, selectors);
  }

  public create(data: any) {
    this.store.dispatch(this.action.create(data));
  }

}
