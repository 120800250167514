import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';

import { AppState } from '../../app.reducer';
import { GeneralAction } from './general.actions';
import * as selectors from './general.selectors';
import { HealyMember } from './general.models';
import { RequestDiffRecord } from '../shared';

@Injectable()
export class GeneralService {

  public isBusy: Observable<number>;
  public diff: Observable<RequestDiffRecord>;
  public healyMembers: Observable<HealyMember[]>;

  public constructor(private store: Store<AppState>, private action: GeneralAction) {
    this.isBusy = this.getAPIState().let(selectors.getIsBusy());
    this.diff = this.getAPIState().let(selectors.getDiff());
    this.healyMembers = this.getAPIState().let(selectors.getHealyMembers());
  }

  public getHealyMembers(start: number, count: number, search?: string) {
    this.store.dispatch(this.action.getHealyMembers(start, count, search));
  }

  public uploadDocument(requestId: string, files: FileList) {
    this.store.dispatch(this.action.uploadDocument(requestId, files));
  }

  public getDocuments(requestId: string, keys: string[]) {
    this.store.dispatch(this.action.getDocuments(requestId, keys));
  }

  protected getAPIState() {
    return this.store.select((state: AppState) => state.api);
  }

  public connectToDB(requestId: string, json: string) {
    this.store.dispatch(this.action.databaseConnection(requestId, json));
  }

  public runConnector(requestId: string, json: string) {
    this.store.dispatch(this.action.runConnector(requestId, json));
  }

  public authoriseEntry(requestId: string, domain: string, json: string) {
    this.store.dispatch(this.action.authoriseEntry(requestId, domain, json));
  }

}
