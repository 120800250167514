import { Utils, JSONAPIDeserializerService } from '../shared';
import { ActivityState, Activity, ActivityRelationships } from './activities.models';
import { ActivityAction } from './activities.actions';
import { Action } from '../shared/common.action';
import { Map } from 'immutable';
import { isNullOrUndefined } from 'util';

export default function reducer(state = new ActivityState(), action: Action): ActivityState {

  const deserializer = new JSONAPIDeserializerService<Activity, ActivityRelationships>(Activity.fromJSONAPI, ActivityRelationships.fromJSONAPI);

  switch (action.type) {

    case ActivityAction.LOAD_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload);
      const filteredEntities = <Map<any, any>> entities.filter(entity => isNullOrUndefined(entity.deletedAt));
      return <ActivityState>Utils.updateState<ActivityState>(state, filteredEntities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.LOAD_FAIL: {
      return <ActivityState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.CREATE_ON_INSTANCE: {
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.CREATE_ON_NODEDATA: {
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.CREATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <ActivityState>Utils.updateState<ActivityState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.CREATE_FAIL: {
      return <ActivityState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.UPDATE: {
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.UPDATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <ActivityState>Utils.updateState<ActivityState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.UPDATE_FAIL: {
      return <ActivityState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.REMOVE: {
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.REMOVE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload.response);
      return <ActivityState>Utils.deleteFromState<ActivityState>(state, entities, relationships, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.REMOVE_FAIL: {
      return <ActivityState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    }

    case ActivityAction.UPDATE:
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );

    case ActivityAction.UPDATE_SUCCESS: {
      const {entities, relationships} = deserializer.deserialize(action.payload);
      return <ActivityState>Utils.updateState<ActivityState>(state, entities, relationships)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.UPDATE_FAIL:
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );
    

    case ActivityAction.GET_ACTIVITY_BY_NODEID: {
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }
    case ActivityAction.GET_SUCCESS: {
      return <ActivityState>Utils.updateDiff(state, action)
      .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case ActivityAction.GET_FAIL:
      return <ActivityState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy))
      );

    default: {
      return state;
    }

  }
}
