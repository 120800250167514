import { Hierarchy, HierarchyState } from './hierarchy.models';
import { Action } from '../shared/common.action';

import { JSONAPIDeserializerServiceNoRel, Utils } from '../shared';
import { HierarchyAction } from './hierarchy.actions';

export default function reducer(state = new HierarchyState(), action: Action): HierarchyState {
  const deserializer = new JSONAPIDeserializerServiceNoRel<Hierarchy>(Hierarchy.fromJSONAPI);
  switch (action.type) {

    case HierarchyAction.LOAD:
      return <HierarchyState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 1)
      );

    case HierarchyAction.LOAD_ALL:
      return <HierarchyState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 1)
      );

    case HierarchyAction.LOAD_ACTIONS:
      return <HierarchyState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => 1)
      );

    case HierarchyAction.LOAD_SUCCESS: {
      const entities = action.payload.response;

      return <HierarchyState>Utils.updateStateWithoutMerge<HierarchyState>(state, entities, action)
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy));
    }

    case HierarchyAction.LOAD_FAIL: {
      return <HierarchyState> Utils.updateDiff(state, action).withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => (isBusy === 0 ? 0 : --isBusy))
      );
    }

    default: {
      return state;
    }
  }
}
