import { Injectable } from '@angular/core';
import { JSONAPIResourceService } from '../jsonapi/jsonapi-resource.service';
import { BackendExportService } from './backend.export.service';
import { BackendGoService } from './backend.go.service';

@Injectable()
export class ExportAPI {

  constructor(private apiService: BackendExportService, private apiGoService: BackendGoService) {}

  public get pdf() {
    return new JSONAPIResourceService<any>('pdf', '/export', this.apiService);
  }

  public get png() {
    return new JSONAPIResourceService<any>('png', '/export', this.apiService);
  }

  public get jpg() {
    return new JSONAPIResourceService<any>('jpg', '/export', this.apiService);
  }

  public get excel() {
    return new JSONAPIResourceService<any>('excel', '/export', this.apiService);
  }

  public get word() {
    return new JSONAPIResourceService<any>('word', '/export', this.apiService);
  }

  public get html() {
    return new JSONAPIResourceService<any>('html', '/api/v5/screenshot', this.apiGoService);
  }

}
