import { Utils } from '../shared/utils';
import { Action } from '../shared/common.action';
import { TreeState } from './tree.models';
import { TreeAction } from './tree.actions';

export default function reducer(state = new TreeState(), action: Action): TreeState {

  switch (action.type) {

    case TreeAction.LOAD: {
      return <TreeState> state.withMutations(s => s
        .updateIn(['isBusy'], (isBusy: number) => Utils.incrementIsBusy(action, isBusy))
      );
    }

    case TreeAction.LOAD_SUCCESS: {
      return <TreeState>Utils.updateStateVanilla<TreeState>(state, action.payload.response, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    case TreeAction.LOAD_FAIL: {
      return <TreeState>Utils.updateDiff<TreeState>(state, action)
        .updateIn(['isBusy'], (isBusy: number) => Utils.decrementIsBusy(action, isBusy));
    }

    default:
      return state;
  }
}
