import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs/Observable';

import { Action } from '../shared/common.action';
import { GoAPI } from '../connector/shared/api.go.service';
import { TreeAction } from './tree.actions';

@Injectable()
export class TreeEffects {

  @Effect() attach$ = this.actions$
    .pipe(ofType(TreeAction.LOAD))
    .mergeMap((action: Action) => this.api.businessarea(action.payload.data).tree.get().mergeMap(response => Observable.from([
      this.treeAction.loadSuccess(response)
    ])).catch((error: any) => Observable.of(this.treeAction.loadFail(error))));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private treeAction: TreeAction) {
  }
}
