import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as hello from 'hellojs';

import { environment } from '../../../../../environments/environment';
import { AppGlobal } from 'src/app/app.global';

@Injectable()
export class TokenService {

  private valueminer = this.initService();

  public get(): Observable<string> {
    if (environment.isMobile) {
      AppGlobal.getToken.next();
      return AppGlobal.tokenObs.take(1);
    } else {
      const token = this.valueminer.login({ force: false, display: 'page' }).then(() => <string> this.valueminer.getAuthResponse().access_token);
      const promise = Promise.resolve(token);   // Abstraction to make the promise compatible
      return <Observable<string>> Observable.fromPromise(promise);
    }
  }

  public refresh(): Observable<string> {
    if (environment.isMobile) {
      if (localStorage.getItem('valueminer.mobile-token') !== null) {
        localStorage.removeItem('valueminer.mobile-token');
      }
      return this.get();
    } else {
      const token = this.valueminer.login({force: true, display: 'page'})
        .then(() => <string>this.valueminer.getAuthResponse().access_token);
      const promise = Promise.resolve(token);   // Abstraction to make the promise compatible
      return <Observable<string>>Observable.fromPromise(promise);
    }
  }

  private initService() {
    if (environment.isMobile) {
      return null;
    }
    hello.init({
      valueminer: {
        name: 'ValueMiner',
        oauth: {
          version: 2,
          auth: environment.auth.authurl,
          grant: environment.auth.tokenurl
        },
        display: 'page',
        refresh: true,
        scope: {
          api: 'api',
          users: 'users'
        },
        scope_delim: ' '
      }
    });

    hello.init(
      { valueminer: environment.auth.clientID },
      { scope: environment.auth.scopes.join(',') }
    );
    return hello('valueminer');
  }
}
