import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { IAccount, ThirdPartyService } from './thirdparty.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppGlobal } from '../../../app.global';

export interface TrendExplorerTokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  scope: any;
}

export interface TrendExplorerResponse {
  page: number;
  totalPages: number;
  limit: number;
  total: number;
  results: any[];
  status?: number;
}

export interface TrendExplorerResponseCategory {
  id: number;
  abbreviation: string;
  translations: TrendExplorerResponseDataTranslation[];
  _links: { 'category-group': { href: string }, 'microtrends': { href: string } };
}

export interface TrendExplorerResponseCategoryGroup {
  id: number;
  title: string;
  translations: TrendExplorerResponseDataTranslation[];
  _links: { 'categories': { href: string } };
}

export interface TrendExplorerResponseIndustry {
  id: number;
  translations: TrendExplorerResponseDataTranslation[];
}

export interface TrendExplorerResponseMacroTrend {
  id: number;
  translations: TrendExplorerResponseDataTranslation[];
  createdAt: string;
  updatedAt: string;
  megatrend_id: string;
  visual0: string;
  _links: { 'megatrend': { href: string }, 'microtrends': { href: string } };
}

export interface TrendExplorerResponseMegaTrend {
  id: number;
  translations: TrendExplorerResponseDataTranslation[];
  color: string;
  visual0: string;
  visual1: string;
  createdAt: string;
  updatedAt: string;
}

export interface TrendExplorerResponseMicroTrend {
  id: number;
  url: string;
  tags: string[];
  countryCode: string;
  macrotrendIds: number[];
  industryIds: number[];
  pictures: string[];
  videos: string[];
  initiators: string[];
  translations: TrendExplorerResponseDataTranslation[];
  isSpecialResearch: boolean;
  identifiedAt: string;
  createdAt: string;
  updatedAt: string;
  similarityIndex?: number;
}

export interface TrendExplorerResponseDataTranslation {
  locale: string;
  title: string;
  description: string;
  subTitle: string;
  shortDescription: string;
  longDescription: string;
  trendexplorerUrl: string;
}

@Injectable()
export class ThirdPartyTrendExplorerService extends ThirdPartyService {

  public getToken(): Observable<IAccount> {
    /* Check if there is a saved token */
    if (this.account.accessToken !== null && this.account.accessToken !== '') {
      return new BehaviorSubject<IAccount>(this.account);
    } else {
      return this.tokenService.get().mergeMap(accessToken => {
        return this.http.post(AppGlobal.go + '/legacy/cors', {
          method: 'GET',
          url: this.account.url + '/oauth/v2/token?grant_type=client_credentials&client_id=' + this.account.clientId + '&client_secret=' + this.account.clientSecret
        }, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        }).map((response: TrendExplorerTokenResponse) => {
          this.account.accessToken = response.access_token;
          return this.account;
        }).catch((err: HttpErrorResponse) => {
          this.account.error = {status: 504, message: ''};
          return new BehaviorSubject<IAccount>(this.account);
        });
      });
    }
  }

  public getTokenByRefreshToken(): Observable<IAccount> {
    return this.tokenService.get().switchMap(accessToken => {
      return this.http.post(AppGlobal.go + '/legacy/cors', {
        method: 'GET',
        url: this.account.url + '/oauth/v2/token?grant_type=client_credentials&client_id=' + this.account.clientId + '&client_secret=' + this.account.clientSecret
      }, {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken
        })
      }).map((response: TrendExplorerTokenResponse) => {
        this.account.accessToken = response.access_token;
        this.updateAccount(this.account);
        return this.account;
      });
    });
  }

  public retryRequest(method: string, url: string, body: any) {
    return this.getTokenByRefreshToken().switchMap(account => {
      return this.tokenService.get().switchMap(accessToken => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Accept': 'application/vnd.api.v2+json',
          'Authorization': 'Bearer ' + accessToken
        });
        body.url = body.url.replace(/access_token=/, 'access_token=' + account.accessToken);
        return this.http.request(method, url, {
          body: JSON.stringify(body),
          headers: headers
        });
      });
    });
  }

  public sendRequest(tokenObservable: Observable<IAccount>, method: string, url: string, body: {} = {}): Observable<Object> {
    return this.tokenService.get().mergeMap(accessToken => tokenObservable.mergeMap((account: IAccount) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.api.v2+json',
        'Authorization': 'Bearer ' + accessToken
      });
      return this.http.request(method, url, {
        body: JSON.stringify(body),
        headers: headers
      });
    }));
  }

  public search(url: string): Observable<TrendExplorerResponse> {
    /* Call */
    return <Observable<TrendExplorerResponse>> this.initialize(this.getAccount('trendexplorer')).request('POST', AppGlobal.go + '/legacy/cors', {
      method: 'GET',
      url: url
    });
  }

}
