export const TemplateFieldsModels = {
  tabs: [
    {
      entry: { key: 'header1', label: 'MODEL.DATASHEET.HEADERS.HEADER1' },
      children: [
        { entry: { key: 'name', type: 'text', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.NAME', required: true, validationMessage: 'DATASHEET.VALIDATION.REQUIRED.NAME' }},
        { entry: { key: 'reference', type: 'text', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.REFERENCE' }},
        { entry: { key: 'crossReference', type: 'text', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.CROSSREFERENCE' }},
        { entry: { key: 'abbreviation', type: 'text', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.ABBREVIATION' }},
        { entry: { key: 'description', controlType: 'text', label: 'MODEL.DATASHEET.FIELDS.DESCRIPTION'}},
        { entry: { key: 'hypothesis', controlType: 'text', label: 'MODEL.DATASHEET.FIELDS.HYPOTHESIS'}},
        { entry: { key: 'targetpicture', controlType: 'text', label: 'MODEL.DATASHEET.FIELDS.TARGETPICTURE'}},
        { entry: { key: 'sorting', type: 'number', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.SORTING' }},
        { entry: { key: 'parentModel', controlType: 'dropdown', hasNull: true, label: 'MODEL.DATASHEET.FIELDS.PARENTMODEL', source: 'models' }},
        { entry: { key: 'onstrategyradar', controlType: 'checkbox', label: 'MODEL.DATASHEET.FIELDS.ONSTRATEGYRADAR' }},
        { entry: { key: 'onreport', controlType: 'checkbox', label: 'MODEL.DATASHEET.FIELDS.ONREPORT' }},
        { entry: { key: 'onprogramm', controlType: 'checkbox', label: 'MODEL.DATASHEET.FIELDS.ONPROGRAMM' }},
        { entry: { key: 'type', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.TYPE.TITLE', options: [
          { key: 0, value: '' },
          { key: 1, value: 'MODEL.DATASHEET.FIELDS.TYPE.DATASOURCE' },
          { key: 2, value: 'MODEL.DATASHEET.FIELDS.TYPE.CCM' },
          { key: 3, value: 'MODEL.DATASHEET.FIELDS.TYPE.SCM' },
          { key: 4, value: 'MODEL.DATASHEET.FIELDS.TYPE.UCIM' },
          { key: 5, value: 'MODEL.DATASHEET.FIELDS.TYPE.CWM' },
          { key: 6, value: 'MODEL.DATASHEET.FIELDS.TYPE.MCM' },
          { key: 7, value: 'MODEL.DATASHEET.FIELDS.TYPE.LIBRARY' },
          { key: 8, value: 'MODEL.DATASHEET.FIELDS.TYPE.SPLASH' },
          { key: 9, value: 'MODEL.DATASHEET.FIELDS.TYPE.STRATEGYMAP' },
          { key: 10, value: 'MODEL.DATASHEET.FIELDS.TYPE.PROJECTPORTFOLIO' },
          { key: 11, value: 'Notification Model' }
        ]}}
      ]
    },
    {
      entry: { key: 'header2', label: 'MODEL.DATASHEET.HEADERS.HEADER2' },
      children: [
        { entry: { key: 'humanresource', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.RESPONSIBLE', source: 'humanresources', hasNull: true }},
        { entry: { key: 'color', type: 'text', controlType: 'color', label: 'MODEL.DATASHEET.FIELDS.COLOR' }},
        { entry: { key: 'template', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.TEMPLATE', source: 'templates', hasNull: true }}
      ]
    },
    {
      entry: { key: 'header3', label: 'MODEL.DATASHEET.HEADERS.HEADER3' },
      children: [
        { entry: { key: 'status', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.STATUS', options: [
          { key: 0, value: 'STATUS.PENDING' },
          { key: 1, value: 'STATUS.INDEFINE' },
          { key: 2, value: 'STATUS.INDEVELOP' },
          { key: 3, value: 'STATUS.INVERIFY' },
          { key: 4, value: 'STATUS.INACCEPT' },
          { key: 5, value: 'STATUS.COMPLETED' }
        ]}},
        { entry: { key: 'commercialStatus', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.COMMERCIALSTATUS', options: [
          { key: 0, value: 'COMMERCIALSTATUS.NA' },
          { key: 1, value: 'COMMERCIALSTATUS.REGISTERED' },
          { key: 2, value: 'COMMERCIALSTATUS.TOBEINVESTIGATED' },
          { key: 3, value: 'COMMERCIALSTATUS.TOBEAPPROVED' },
          { key: 4, value: 'COMMERCIALSTATUS.CONDACCEPTED' },
          { key: 5, value: 'COMMERCIALSTATUS.ACCEPTED' },
          { key: 6, value: 'COMMERCIALSTATUS.INVOICED' },
          { key: 7, value: 'COMMERCIALSTATUS.REJECTED' },
          { key: 8, value: 'COMMERCIALSTATUS.CLOSED' }
        ]}},
        { entry: { key: 'date', type: 'datetime', controlType: 'date', label: 'MODEL.DATASHEET.FIELDS.TARGETDATE' }}
      ]
    },
    {
      entry: { key: 'header4', label: 'MODEL.DATASHEET.HEADERS.HEADER4' },
      children: [
        { entry: { key: 'boxWidth', source: 'settings.default', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.BOXWIDTH.TITLE', options: [
          { key: 80, value: 'MODEL.DATASHEET.FIELDS.BOXWIDTH.NORMAL' },
          { key: 150, value: 'MODEL.DATASHEET.FIELDS.BOXWIDTH.WIDE' }
        ]}},
        { entry: { key: 'wordBreak', source: 'settings.default', controlType: 'checkbox', label: 'MODEL.DATASHEET.FIELDS.WORDBREAK' }},
        { entry: { key: 'limit', source: 'settings.default', type: 'number', controlType: 'textbox', label: 'MODEL.DATASHEET.FIELDS.LIMIT' }},
        { entry: { key: 'lines', source: 'settings.default', controlType: 'dropdown', label: 'MODEL.DATASHEET.FIELDS.LINES', options: [
          { key: 1, value: '1' },
          { key: 2, value: '2' },
          { key: 3, value: '3' },
          { key: 4, value: '4' },
          { key: 5, value: '5' }
        ]}},
        { entry: { key: 'icon', type: 'text', controlType: 'icon', label: 'MODEL.DATASHEET.FIELDS.ICON' }}
      ]
    }
  ]
};
