import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppGlobal } from './app/app.global';

if (environment.production) {
  enableProdMode();
}

/* Set variables */
AppGlobal.oauthUrl = environment.oauthUrl;
AppGlobal.exportUrl = environment.exportUrl;
AppGlobal.imageUrl = environment.imageUrl;
AppGlobal.fileupload = environment.fileupload;
AppGlobal.go = environment.go;
AppGlobal.authurl = environment.auth.authurl;
AppGlobal.tokenurl = environment.auth.tokenurl;
AppGlobal.logouturl = environment.auth.logouturl;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
