import { isArray } from 'util';

export interface Similarity {
  string: string;
  similarityIndex: number;
}

export class SimilarityService {

  public similarity(needle: string | string[], haystack: string[], callback: Function) {
    const useMatrix = isArray(needle);
    /* Insert needle as first entry */
    if (!useMatrix) {
      haystack.unshift(<string> needle);
    } else {
      haystack = (<string[]> needle).concat(haystack);
    }
    /* Load the embed */
    this.loadEmbed(haystack, embeddings => {
      /* Get the matrix */
      const cosineSimilarityMatrix = this.cosineSimilarityMatrix(embeddings.arraySync(), useMatrix);
      /* Subtract the amount of needles */
      const needleCount = useMatrix ? needle.length : 1;
      /* Remove the needle entries */
      haystack.splice(0, needleCount);
      /* Haystack count */
      const haystackCount = haystack.length;
      /* Merge matrix with haystack and make 0 - 100 index */
      const results: { needle: string, similarities: any[] }[] = [];
      for (let i = 0; i < needleCount; i++) {
        const result = { needle: needle[i], similarities: [] };
        for (let i2 = 0; i2 < haystackCount; i2++) {
          result.similarities.push({ string: haystack[i2], similarityIndex: Math.round(cosineSimilarityMatrix[i][i2] * 1000) });
        }
        results.push(result);
      }
      if (useMatrix) {
        callback(results);
      } else {
        callback(results[0]);
      }
    });
  }

  private loadEmbed(haystack: string[], callback) {}

  private cosineSimilarityMatrix(matrix, useMatrix = false) {
    const cosineSimilarityMatrix = [];
    const count = useMatrix ? matrix.length : 1;
    for (let i = 0; i < count; i++) {
      const row = [];
      for (let j = 0; j < i; j++) {
        row.push(cosineSimilarityMatrix[j][i]);
      }
      row.push(1);
      for (let j = (i + 1); j < matrix.length; j++) {
        row.push(this.calculateSimilarity(matrix[i], matrix[j]));
      }
      cosineSimilarityMatrix.push(row);
    }
    return cosineSimilarityMatrix;
  }

  private calculateSimilarity(a, b) {
    const magA = Math.sqrt(this.dot(a, a));
    const magB = Math.sqrt(this.dot(b, b));
    return magA && magB ? this.dot(a, b) / (magA * magB) : false;
  }

  private dot(a, b) {
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    let sum = 0;
    for (const key in a) {
      if (hasOwnProperty.call(a, key) && hasOwnProperty.call(b, key)) {
        sum += a[key] * b[key];
      }
    }
    return sum;
  }

}
