import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { GoAPI, IHierarchy } from '../connector';
import { HierarchyAction } from './hierarchy.actions';
import { Observable } from 'rxjs/Observable';
import { JSONAPIResponse } from '../connector';
import { Action } from '../shared/common.action';

@Injectable()
export class HierarchyEffects {

  @Effect() loadHierarchies$ = this.actions$
    .pipe(ofType(HierarchyAction.LOAD_ALL))
    .switchMap((action: Action) => this.api.hierarchy.findAll()
      .mergeMap((response: JSONAPIResponse<IHierarchy[]>) => {
          return Observable.from([this.hierarchyAction.loadSuccess(response.toPayload())]);
        }
      )).catch((error: any) => Observable.of(this.hierarchyAction.loadFail(error)));

  @Effect() loadActions$ = this.actions$
    .pipe(ofType(HierarchyAction.LOAD_ACTIONS))
    .switchMap((action: Action) => this.api.actions.findAll()
      .mergeMap((response: JSONAPIResponse<IHierarchy[]>) => {
          return Observable.from([this.hierarchyAction.loadSuccess(response.toPayload())]);
        }
      )).catch((error: any) => Observable.of(this.hierarchyAction.loadFail(error)));

  constructor(private api: GoAPI,
              private actions$: Actions,
              private hierarchyAction: HierarchyAction) {
  }
}
