import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';
import { isArray, isNullOrUndefined } from 'util';
import { Map, Set } from 'immutable';
import { ColorLabelProviderLegend } from '../colorlabelprovider.interface';

export class ColorLabelProviderServiceHeatmap extends ColorLabelProviderAbstract {

  private field = '';
  private calculation = 'default'
  private fields = Map<string, { label: string, color: string }>();
  private formFieldID = '';
  private averageFields = [];
  private fieldMap = {};
  public constructor(protected configurationNode?: TreeNode) {
    super();
    /* Configuration node */
    if (!isNullOrUndefined(configurationNode)) {
      this.configure();
    }
  }

  public color(elements: TreeNode[]|TreeNode) {
    let color;
    
    if (Array.isArray(elements)) {
      switch (this.calculation) {
        case 'average':
          color = this.averageCalculation(elements);
          break;
        default:
          color = this.defaultCalculation(elements);
          break;
      }
    } else {
     color = this.fields.has('' + elements[this.formFieldID]) ? [this.fields.get('' + elements[this.formFieldID]).color] : super.color();
    if(elements[this.formFieldID] != undefined) {
    }
    }
    return color;
  }

  /* Calculating the Average */
  private averageCalculation (elements:TreeNode[]) {
    const count = elements.length
    let sum = 0;
    for (let i = 0; i < count; i++) {
      const element = elements[i];
      sum += element[this.formFieldID]
    }
    const result = sum/count
    const countFields = this.averageFields.length;
    for (let i = 0; i < countFields; i++) {
      const averageFields = this.averageFields[i];
      if(result < averageFields.value) {
        return [averageFields.color];
      }
    }
    return super.color()
  }

  /* Worst/Best - Case */
  private defaultCalculation (elements: TreeNode[]) {
    const count = elements.length
    let pos = 2000;
    let color = super.color()[0]
    for (let i = 0; i < count; i++) {
      const element = elements[i];
      const field = this.fieldMap[element[this.formFieldID]] || this.fieldMap["undefined"];
      if(isNullOrUndefined(field) || (field === this.fieldMap["undefined"] && element[this.formFieldID] === 0)){
        continue
      }
      if(field.position < pos) {
        pos = field.position;
        color = field.color;
      }
      if(pos === 0) {
        return [field.color]
      }
    }
    return [color]
  }

  public legend(): any {
    return <ColorLabelProviderLegend[]> this.fields.map((field, key) => (<ColorLabelProviderLegend> {
      key: key,
      label: field.label,
      color: field.color,
      field: this.field,
      value: key,
    })).toArray();
  }

  public modifyConfiguration(configurationNode: TreeNode) {
    this.configurationNode = configurationNode;
    this.configure();
  }

  protected configure() {
    if (this.configurationNode === null || this.configurationNode === undefined) {
      return;
    }
    /* Set the field */
    this.field = this.configurationNode.formFieldId;
    this.calculation = this.configurationNode.formFieldCalculation;
    /* Set the fields */
    this.fields = this.fields.clear();
    this.formFieldID = this.configurationNode.formFieldId
    this.averageFields = [];
    this.fieldMap = {};
    const children = this.configurationNode.children.sort((a, b) => a.positionX - b.positionX);
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      this.fields = this.fields.set('' + child.formId, { label: child.name, color: child.color });
      switch (this.calculation) {
        case 'average':
            this.averageFields.push({value: parseInt(child.formId), color: child.color});
          break;
        default:
            this.fieldMap[child.formId] = {position: i, color: child.color};
          break;
      }
    }
  }
}
