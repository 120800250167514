import { TranslateService } from '@ngx-translate/core';

import { CoreService } from '../../core/service/core.service';
import { CoreUtilities } from '../../core/utilities/core.utilities';
import {
  AutogenAgentConfig, AutogenAgentWorkflow, AutogenRequest, AutogenResponse, AutogenSession,
  GPT, GPTMessage,
  GPTRequest,
  GPTResponse,
  GPTStatus,
} from './gpt.interface';
import { TreeNode } from '../../core/interface/core.interface';
import { NODES_TYPE_AGENT, NODES_TYPE_CONNECT, NODES_TYPE_MODEL, NODES_TYPE_RECEIVER, NODES_TYPE_SKILLS } from '../../shared/api/nodes/nodes.models';
import { Datum } from '../../shared/utilities/datum';
import { UUID } from 'angular2-uuid';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';

export class GptAutogenService {

  public constructor(private coreService: CoreService, private coreUtilities: CoreUtilities, private translateService: TranslateService) {}

  /**
   * Connect
   *
   * @param {GPT} gpt
   */
  public connect(gpt: GPT) {
    /* Manipulate the uuid */
    gpt.uuid = gpt.uuid.replace('-', '').slice(0, 11);
    /* Do the first request */
    const request = {
      type: 'autogen',
      autogenRequest: {
        url: gpt.url + '/api/sessions?user_id=' + gpt.user_id,
      } as AutogenRequest
    } as GPTRequest;
    this.coreService.gpt('health', request).take(1).subscribe(response => {
      switch (response.status) {
        case true:
          /* Send first update */
          window.setTimeout(() => gpt.events.next({ gpt, status: GPTStatus.IDLE } as GPTResponse), 0);
          break;
        default:
          this.coreService.toast(this.translateService.instant('GENERAL.ERROR.0.TITLE'), 'error');
          break;
      }
    });
  }

  public getNewSession(gpt: GPT, workflowNode: TreeNode, callback: Function) {

    workflowNode.children = workflowNode.unfilteredChildren;

    const request = {
      type: 'autogen',
      autogenRequest: {
        user_id: gpt.user_id,
        url: gpt.url + '/api/sessions',
        method: 'POST',
        session: {
          user_id: gpt.user_id,
          session_id: null,
          flow_config: this.getWorkflow(gpt, workflowNode)
        } as AutogenSession
      } as AutogenRequest
    } as GPTRequest;

    this.coreService.gpt('session', request).take(1).subscribe(response => {
      switch (response.status) {
        case true:
          /* Send first update */
          callback(response.data[0]);
          break;
        default:
          this.coreService.toast(this.translateService.instant('GENERAL.ERROR.0.TITLE'), 'error');
          break;
      }
    });
  }

  /**
   * Send prompt
   *
   * @param {GPT} gpt
   * @param promptId
   * @param prompt
   * @param autogenWorkflow
   * @param conversations
   * @param systemPrompt
   */
  public sendPrompt(gpt: GPT, promptId: string, prompt: string, autogenWorkflow: TreeNode, conversations: GPTMessage[], systemPrompt?: string) {
    /* Load the workflow node if grouped */
    autogenWorkflow = this.coreService.getNodeStructuresFromOriginal(autogenWorkflow);
    /* Send the first request to get the session id */
    const requestGetSessionId = {
      type: 'autogen',
      autogenRequest: {
        url: gpt.url + '/api/sessions?user_id=' + gpt.user_id
      } as AutogenRequest
    } as GPTRequest;
    this.coreService.gpt('health', requestGetSessionId).take(1).subscribe((responseGetSessionId) => {
      if (responseGetSessionId.data[0] !== undefined) {
        const sessionId = responseGetSessionId.data[0].id;
        /* Now send the message */
        const request = {
          type: 'autogen',
          autogenRequest: {
            connection_id: sessionId,
            url: gpt.url + '/api/messages',
            user_id: gpt.user_id,
            message: {
              content: prompt,
              msg_id: UUID.UUID(),
              role: 'user',
              root_msg_id: '0',
              session_id: sessionId,
              user_id: gpt.user_id
            },
            session: responseGetSessionId.data[0],
            workflow: this.getWorkflow(gpt, autogenWorkflow)
          } as AutogenRequest
        } as GPTRequest;

        request.autogenRequest.session.flow_config = request.autogenRequest.workflow;

        if (conversations !== undefined && conversations.length > 0) {
          if (conversations[0].nodeIds !== undefined) {
            request.autogenRequest.message.nodeIds = conversations[0].nodeIds;
          }
          if (conversations[0].nodeFields !== undefined) {
            request.autogenRequest.message.nodeFields = conversations[0].nodeFields;
          }
        }

        this.coreService.gpt('completion', request).pipe(
          filter(response => {
            if (response.message === 'Sessions retrieved successfully') {
              return false;
            }
            return true;
          }),
          take(1)
        ).subscribe((response) => {
          /* Send the event via emitter */
          gpt.events.next({ gpt, message: this.convertToGPTMessage(promptId, prompt, response), status: this.convertToGPTStatus(response) } as GPTResponse);
        });
      }
    });
  }

  public getWorkflow(gpt: GPT, autogenWorkflow: TreeNode) {

    const workflow = {
      id: autogenWorkflow.crossReference,
      description: this.coreUtilities.unescapeHtml(autogenWorkflow.description),
      name: autogenWorkflow.name,
      user_id: 'default',
      timestamp: moment().format('YYYY-MM-DD[T]HH:mm:ss[.580385]'),
      type: 'twoagents',
      summary_method: autogenWorkflow.textField2
    } as AutogenAgentWorkflow;

    const children = autogenWorkflow.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      if (child.nodeType === NODES_TYPE_CONNECT || child.nodeType === NODES_TYPE_RECEIVER) {
        const selectedAgent = child.parents.filter(p => p.nodeType === NODES_TYPE_AGENT)[0];
        if (selectedAgent !== undefined) {
          const agentData = this.getAgentData(gpt, selectedAgent);
          const agent = {
            type: 'assistant',
            id: selectedAgent.crossReference,
            timestamp: moment().format('YYYY-MM-DD[T]HH:mm:ss[.580385]'),
            user_id: 'default',
            description: this.coreUtilities.unescapeHtml(selectedAgent.description),
            config: {
              name: selectedAgent.name,
              human_input_mode: selectedAgent.textField3,
              max_consecutive_auto_reply: selectedAgent.numberField1,
              system_message: this.coreUtilities.unescapeHtml(selectedAgent.textField5),
              is_termination_msg: null,
              code_execution_config: false,
              default_auto_reply: selectedAgent.textField2,
              llm_config: agentData.llm_config
            } as AutogenAgentConfig
          };
          switch (child.nodeType) {
            case NODES_TYPE_CONNECT:
              workflow.sender = agent;
              break;
            case NODES_TYPE_RECEIVER:
              workflow.receiver = agent;
              break;
          }
        }
      }
    }

    return workflow;

  }

  /**
   * Create a skill on Autogen
   *
   * @param gpt
   * @param {TreeNode} skillTreeNode
   * @param response
   * @param del
   */
  public createSkill(gpt: GPT, skillTreeNode: TreeNode, response: Function, del = false) {
    /* Set up the request */
    const request = {
      type: 'autogen',
      autogenRequest: {
        user_id: gpt.user_id,
        url: gpt.url + '/api/skills',
        method: del ? 'DELETE' : 'POST',
        skill: {
          title: skillTreeNode.name,
          content: skillTreeNode.code,
          description: this.coreUtilities.unescapeHtml(skillTreeNode.description)
        }
      } as AutogenRequest
    } as GPTRequest;

    if (skillTreeNode.crossReference !== '' && skillTreeNode.crossReference !== undefined && skillTreeNode.crossReference !== null) {
      request.autogenRequest.skill.id = skillTreeNode.crossReference;
    }

    if (del) {
      request.autogenRequest.url += '/delete';
    }

    /* Send the prompt */
    this.coreService.gpt('create', request).take(1).subscribe((re) => {
      if (re.data !== undefined && re.data !== null) {
        response(re.data.sort((a, b) => {
          const aDate = new Datum(a.timestamp);
          const bDate = new Datum(b.timestamp);
          if (aDate.eq(bDate)) {
            return 0;
          }
          return aDate.lt(bDate) ? 1 : -1;
        })[0].id);
      } else {
        console.error(re);
      }
    });
  }

  /**
   * Create a model on Autogen
   *
   * @param gpt
   * @param {TreeNode} modelTreeNode
   * @param response
   * @param del
   */
  public createModel(gpt: GPT, modelTreeNode: TreeNode, response: Function, del = false) {

    /* Set up the request */
    const request = {
      type: 'autogen',
      autogenRequest: {
        user_id: gpt.user_id,
        url: gpt.url + '/api/models',
        method: del ? 'DELETE' : 'POST',
        model: {
          api_key: modelTreeNode.textField1,
          api_type: modelTreeNode.textField2,
          api_version: modelTreeNode.textField3,
          base_url: modelTreeNode.documentUri,
          description: this.coreUtilities.unescapeHtml(modelTreeNode.description),
          model: modelTreeNode.name,
          user_id: gpt.user_id,
        }
      } as AutogenRequest
    } as GPTRequest;


    if (modelTreeNode.crossReference !== '' && modelTreeNode.crossReference !== undefined && modelTreeNode.crossReference !== null) {
      request.autogenRequest.model.id = modelTreeNode.crossReference;
    }

    if (del) {
      request.autogenRequest.url += '/delete';
    }

    /* Send the prompt */
    this.coreService.gpt('create', request).take(1).subscribe((re) => {
      if (re.data !== undefined && re.data !== null) {
        response(re.data.sort((a, b) => {
          const aDate = new Datum(a.timestamp);
          const bDate = new Datum(b.timestamp);
          if (aDate.eq(bDate)) {
            return 0;
          }
          return aDate.lt(bDate) ? 1 : -1;
        })[0].id);
      } else {
        console.error(re);
      }
    });
  }

  /**
   * Create an agent on Autogen
   *
   * @param gpt
   * @param {TreeNode} agentTreeNode
   * @param response
   * @param del
   */
  public createAgent(gpt: GPT, agentTreeNode: TreeNode, response: Function, del = false) {

    /* Set up the request */
    const request = {
      type: 'autogen',
      autogenRequest: {
        user_id: gpt.user_id,
        url: gpt.url + '/api/agents',
        method: del ? 'DELETE' : 'POST',
        agent: {
          user_id: gpt.user_id,
          description: this.coreUtilities.unescapeHtml(agentTreeNode.description),
          type: 'assistant',
          config: {
            code_execution_config: false,
            name: agentTreeNode.name,
            default_auto_reply: agentTreeNode.textField2,
            human_input_mode: agentTreeNode.textField3,
            max_consecutive_auto_reply: agentTreeNode.numberField1,
            system_message: this.coreUtilities.unescapeHtml(agentTreeNode.textField5),
            llm_config: false
          }
        }
      } as AutogenRequest
    } as GPTRequest;

    const agentData = this.getAgentData(gpt, agentTreeNode);
    if (agentData.llm_config !== undefined) {
      request.autogenRequest.agent.config.llm_config = agentData.llm_config;
    }
    if (agentData.skills !== undefined) {
      request.autogenRequest.agent.skills = agentData.skills;
    }

    if (agentTreeNode.crossReference !== '' && agentTreeNode.crossReference !== undefined && agentTreeNode.crossReference !== null) {
      request.autogenRequest.agent.id = agentTreeNode.crossReference;
    }

    if (del) {
      request.autogenRequest.url += '/delete';
    }

    /* Send the prompt */
    this.coreService.gpt('create', request).take(1).subscribe((re) => {
      if (re.data !== undefined && re.data !== null) {
        response(re.data.sort((a, b) => {
          const aDate = new Datum(a.timestamp);
          const bDate = new Datum(b.timestamp);
          if (aDate.eq(bDate)) {
            return 0;
          }
          return aDate.lt(bDate) ? 1 : -1;
        })[0].id);
      } else {
        console.error(re);
      }
    });
  }

  /**
   * Create an agent on Autogen
   *
   * @param gpt
   * @param {TreeNode} workflowTreeNode
   * @param response
   * @param del
   * @param internal
   */
  public createWorkflow(gpt: GPT, workflowTreeNode: TreeNode, response: Function, del = false, internal = false) {
    /* Set up the request */
    const request = {
      type: 'autogen',
      autogenRequest: {
        user_id: gpt.user_id,
        url: gpt.url + '/api/workflows',
        method: del ? 'DELETE' : 'POST',
        workflow: {
          description: this.coreUtilities.unescapeHtml(workflowTreeNode.description),
          name: workflowTreeNode.name,
          type: 'twoagents',
          summary_method: workflowTreeNode.textField2,
        }
      } as AutogenRequest
    } as GPTRequest;


    if (workflowTreeNode.crossReference !== '' && workflowTreeNode.crossReference !== undefined && workflowTreeNode.crossReference !== null) {
      request.autogenRequest.workflow.id = workflowTreeNode.crossReference;
    }

    if (del) {
      request.autogenRequest.url += '/delete';
    }

    const children = workflowTreeNode.children;
    const count = children.length;
    for (let i = 0; i < count; i++) {
      const child = children[i];
      const selected = child['dynamic-parents'] !== undefined ? child['dynamic-parents'].selected[0] : undefined;
      if (selected !== undefined) {
        const agentData = this.getAgentData(gpt, selected);
        const agent = {
          type: '',
          config: {
            id: selected.crossReference,
            code_execution_config: false,
            name: selected.name,
            default_auto_reply: selected.textField2,
            human_input_mode: selected.textField3,
            max_consecutive_auto_reply: selected.numberField1,
            system_message: this.coreUtilities.unescapeHtml(selected.textField5),
            llm_config: agentData.llm_config !== undefined ? agentData.llm_config : false
          } as AutogenAgentConfig,
          skills: agentData.skills
        };
        switch (child.nodeType) {
          case NODES_TYPE_CONNECT:
            agent.type = 'assistant';
            request.autogenRequest.workflow.sender = agent;
            break;
          case NODES_TYPE_RECEIVER:
            agent.type = 'assistant';
            request.autogenRequest.workflow.receiver = agent;
            break;
        }
      }
    }

    if (internal) {
      return request;
    }

    /* Send the prompt */
    this.coreService.gpt('create', request).take(1).subscribe((re) => {
      if (re.data !== undefined && re.data !== null) {
        response(re.data.sort((a, b) => {
          const aDate = new Datum(a.timestamp);
          const bDate = new Datum(b.timestamp);
          if (aDate.eq(bDate)) {
            return 0;
          }
          return aDate.lt(bDate) ? 1 : -1;
        })[0].id);
      } else {
        console.error(re);
      }
    });
  }

  protected getAgentData(gpt: GPT, agentTreeNode: TreeNode) {
    /* Response */
    const response = { llm_config: undefined, skills: undefined };

    const models = (agentTreeNode.unfilteredChildren !== undefined ? agentTreeNode.unfilteredChildren : agentTreeNode.children).filter(child => child.nodeType === NODES_TYPE_MODEL);
    if (models.length > 0) {
      response.llm_config = {
        cache_seed: agentTreeNode.formFieldSearchable,
        timeout: agentTreeNode.numberField2,
        temperature: agentTreeNode.benefitActual,
        config_list: models.map(child => ({
          api_key: child.textField1,
          api_type: child.textField2,
          api_version: child.textField3,
          base_url: child.documentUri,
          description: this.coreUtilities.unescapeHtml(child.description),
          model: child.name,
          user_id: gpt.user_id,
          id: child.crossReference
        }))
      };

      const responseFormat = this.coreUtilities.unescapeHtml(agentTreeNode.textField6);
      if (responseFormat !== '') {
        try {
          response.llm_config.response_format = JSON.parse(responseFormat);
        } catch (e) {}
      }
    }

    const skills = (agentTreeNode.unfilteredChildren !== undefined ? agentTreeNode.unfilteredChildren : agentTreeNode.children).filter(child => child.nodeType === NODES_TYPE_SKILLS);
    if (skills.length > 0) {
      response.skills = skills.map(child => ({
        id: child.crossReference,
        file_name: null,
        timestamp: moment().format('YYYY-MM-DD[T]HH:mm:ss[.580385]'),
        title: child.name,
        content: child.code,
        description: this.coreUtilities.unescapeHtml(child.description),
        user_id: 'default'
      }));
    }

    return response;
  }

  /**
   * Convert the data object to GPT message
   *
   * @param {string} promptId
   * @param prompt
   * @param response
   * @returns {GPTMessage}
   * @private
   */
  private convertToGPTMessage(promptId: string, prompt: string, response: AutogenResponse): GPTMessage {
    /* The message */
    const message: GPTMessage = { uuid: promptId, prompt, message: '', status: this.convertToGPTStatus(response) };

    /* Show the last answer */
    if (response.data !== null) {
      message.response = response.data[response.data.length - 1].content;

      /* Add all messages */
      try {
        const metadata = JSON.parse(response.data[response.data.length - 1].metadata);
        message.agentMessages = metadata.messages;
      } catch (e) {}
    } else {
      message.response = response.message;
    }

    /* Return message */
    return message;
  }

  /**
   * Convert event data to GPT Status
   *
   * @returns {string}
   * @private
   * @param response
   */
  private convertToGPTStatus(response: AutogenResponse): string {
    switch (response.status) {
      case true:
        return GPTStatus.COMPLETED;
    }
    return '';
  }

}
