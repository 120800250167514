import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { isNullOrUndefined } from 'util';
import { Set } from 'immutable';

export class ColorLabelProviderServicePositive extends ColorLabelProviderAbstract {

  private static POSITIVE = '#9ac93e';
  private static LIGHT_POSITIVE = '#d4f691';
  private static ATTENTION = '#EDD515';
  private static LIGHT_ATTENTION = '#e3db97';
  private static NEGATIVE = '#E50000';
  private static LIGHT_NEGATIVE = '#da9696';

  public getNegative() {
    return Set<string>([ColorLabelProviderServicePositive.ATTENTION, ColorLabelProviderServicePositive.LIGHT_ATTENTION, ColorLabelProviderServicePositive.NEGATIVE, ColorLabelProviderServicePositive.LIGHT_NEGATIVE]);
  }

  public getTotalNegative() {
    return Set<string>([ColorLabelProviderServicePositive.ATTENTION, ColorLabelProviderServicePositive.NEGATIVE]);
  }

  public color(result: { allValue: number, totalCapacity: number, allBookedCapacity: number, attention: boolean, counter: number, value: number, capacityValue: number,
    requirementValue: number, requirementAllValue: number, sumCapacity: number, sumRequirement: number, sumPercentage: number, inProject: false }, calculateTotals = false) {
    if (isNullOrUndefined(result) || isNullOrUndefined(result.value) || !result.inProject) {
      return super.color();
    }

    const capacity = result.requirementValue + result.value;
    const percentageValue = capacity === 0 ? ((result.requirementValue > 0) ? -30 : 0) : ((capacity - result.requirementValue) / capacity) * 100;

    if (calculateTotals) {
      // --> if x > "-10%" --> bright Green
      if (result.allValue >= -10) {
        return [ColorLabelProviderServicePositive.POSITIVE];
      }
      // --> if x < "-10" but > "-20" bright yellow
      if (result.allValue < -10 && result.allValue >= -20) {
        return [ColorLabelProviderServicePositive.ATTENTION];
      }
      // --> if x < "-20%" dark red
      if (result.allValue < -20) {
        return [ColorLabelProviderServicePositive.NEGATIVE];
      }
      return super.color();
    }

    if (result.sumPercentage < -20) {
      return [ColorLabelProviderServicePositive.NEGATIVE];
    }

    /* Booked Capacity is NULL and Requirement is NOT NULL and totals saldo is less than - 20% */
    if ((result.totalCapacity === 0 && result.requirementValue !== 0 && result.allValue <= -20) || (result.value === 0 && result.requirementValue === 0 && result.allBookedCapacity === 0)) {
      return [ColorLabelProviderServicePositive.LIGHT_NEGATIVE];
    }

    /* if sum of montly booked capacity of a role is up to 10% higher than monthly lineary total capacity
    of this role (if monthly booked capacity and requirement are green and lineary total is green) */
    if ((percentageValue >= -10 && result.allValue >= -10) && result.sumPercentage >= -10) {
      return [ColorLabelProviderServicePositive.POSITIVE];
    }

    /* show light yellow: if the requirement of a project is between 10-20% higher than the booked capacity,
    but the sum of monthly lineary total capacity is „green“ */
    if (percentageValue < -10 && percentageValue >= -20 && result.sumPercentage >= -10) {
      return [ColorLabelProviderServicePositive.LIGHT_ATTENTION];
    }

    /* show bright yellow: if sum of monthly booked capacity of a role is between 10%-20% higher
    than monthly lineary total capacity of this role  */
    if (percentageValue >= -20 && result.sumPercentage < -10) {
      return [ColorLabelProviderServicePositive.ATTENTION];
    }

    /* if monthly requirement of a role on a project is more than 20% higher than monthly booked capacity
    of this role for this project, but the monthly calculation would turn (green)  */
    if (percentageValue < -20 && result.sumPercentage >= -20) {
      return [ColorLabelProviderServicePositive.LIGHT_NEGATIVE];
    }

    /* show bright red: if sum of montly booked capacity of a role is more than 20%
    higher than monthly lineary total capacity of this role  */
    if ((percentageValue < -20 && result.allValue < -20) || (percentageValue >= -10 && result.allValue < -20)) {
      return [ColorLabelProviderServicePositive.NEGATIVE];
    }

    return super.color();
  }

}
