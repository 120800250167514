import { NODES_TYPE_TRAVERSER } from './../../../shared/api/nodes/nodes.models';
import { CoreService } from 'src/app/core/service/core.service';
import { ColorLabelProviderAbstract } from './colorlabelprovider.service.abstract';
import { TreeNode } from '../../../core/interface/core.interface';

import { isNullOrUndefined } from 'util';
import { Traverser } from '../../traverser/traverser';

export class ColorLabelProviderServiceStructure extends ColorLabelProviderAbstract {
  private traverser: Traverser;

  public constructor(configurationTreeNode: TreeNode, private coreService: CoreService) {
    super();
    if (isNullOrUndefined(this.coreService) || isNullOrUndefined(configurationTreeNode)) {
      return;
    }
    const lanesParent = configurationTreeNode.children.filter(e => e.nodeType === NODES_TYPE_TRAVERSER)[0];
    if (lanesParent !== undefined) {
      this.traverser = new Traverser().setRelationshipWeights(this.coreService.getRelationshipWeights()).addToLanes(lanesParent.children).setTRT(false);
    }
  }

  public color(treeNode: TreeNode): any {
    /* Hier läuft der traverser */
    if (isNullOrUndefined(this.traverser)) {
      return super.color(treeNode);
    } else {
      const colorNodes =  this.traverser.run([treeNode]);
      if (colorNodes.length > 0) {
        return [colorNodes[0].color];
      } else {
        return super.color(treeNode);
      }
    }
  }

}
